import { ConfirmationDialogInitialValues, ConfirmationPanelProps } from '../types';
import { ConfirmationDialog } from './ConfirmationDialog';

export const ConfirmationPanel = (props: ConfirmationPanelProps) => {
  const { t, isVisible, registerEmail, dialogHeader, onSuccess } = props;

  const initialValues: ConfirmationDialogInitialValues = {
    confirmationCode: '',
    email: registerEmail,
  };

  return (
    <>
      <ConfirmationDialog
        t={t}
        isVisible={isVisible}
        initialValues={initialValues}
        dialogHeader={dialogHeader}
        onSuccess={onSuccess}
      />
    </>
  );
};
