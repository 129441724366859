import { FormikErrors, FormikHelpers, useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import groupsService from '../../service/api/GroupsService';
import FormErrorMessage from '../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../_shared/FormErrorMessageScroller';
import { useTranslation } from 'react-i18next';
import { Group } from '../../types/Group';

import { GroupTemplate } from '../../types/GroupTemplate';
import { deepCopy } from '../_shared/objectHelpers';
import { Fieldset } from 'primereact/fieldset';
import { Io } from '../../types/Io';
import { GroupConnectors } from '../../types/GroupConnectors';
import { InputNumber } from 'primereact/inputnumber';
import WriteOnlyButton from '../controls/WriteOnlyButton';

interface FormValuesProps extends Group {}
const INIT_FORM_STATE: FormValuesProps = {
  id: null,
  name: '',
  groupTemplateId: null,
  isUsed: false,
  connectors: [],
};

type Props = {
  dialogVisible: boolean;
  closeAddEditModal: VoidFunction;
  group: Group;
  availableGroupTemplates: GroupTemplate[];
  availableIo: Io[];
  showAddToast: (name: string) => void;
  showSaveToast: (name: string) => void;
};

function GroupDialog({
  dialogVisible,
  closeAddEditModal,
  group,
  availableGroupTemplates,
  availableIo,
  showAddToast,
  showSaveToast,
}: Props) {
  const [initFormValues, setInitFormValues] = useState(deepCopy(INIT_FORM_STATE));

  const { t } = useTranslation();

  const hideDialog = () => {
    formik.resetForm({ values: deepCopy(INIT_FORM_STATE) });
    closeAddEditModal();
  };

  const groupTemplates = availableGroupTemplates?.map((r) => {
    return { label: r.name, value: r.id };
  });

  const io = availableIo?.map((r) => {
    return { label: r.name, value: r.id };
  });

  const connectorTypeNameMap = {
    INPUT: t('groupTemplates.input'),
    OUTPUT: t('groupTemplates.output'),
  };

  useEffect(() => {
    if (dialogVisible && group) {
      setInitFormValues({
        id: group.id,
        name: group.name,
        groupTemplateId: group.groupTemplateId,
        isUsed: group.isUsed,
        connectors: group.connectors,
      });
    } else {
      setInitFormValues(deepCopy(INIT_FORM_STATE));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogVisible]);

  const handleGroupTemplateChange = (groupTemplateId: string, formik: any): void => {
    const groupTempalte = availableGroupTemplates.find((gt) => gt.id === groupTemplateId);
    if (!groupTempalte) {
      return;
    }

    const groupConnectors: GroupConnectors[] = [];
    groupTempalte.connectors.forEach((connector) => {
      groupConnectors.push({
        groupTemplateConnectorId: connector.id,
        groupTemplateConnectorType: connector.type,
        groupTemplateConnectorName: connector.name,
        groupTemplateConnectorIndex: connector.index,
        name: connector.name,
        type: connector.type,
        typeName: connectorTypeNameMap[connector.type],
        pin: null,
        ioId: null,
        groupId: null,
        id: null,
      });
    });
    formik.setFieldValue('groupTemplateId', groupTemplateId, false);
    formik.setFieldValue('connectors', groupConnectors, false);
    formik.setFieldError('connectors', {});
  };

  const formik = useFormik({
    initialValues: initFormValues,
    validate: (data: FormValuesProps) => {
      let errors: FormikErrors<FormValuesProps> = {};

      if (!data.name?.trim()) {
        errors.name = t('common.error_name_required');
      }

      if (!data.groupTemplateId?.trim()) {
        errors.groupTemplateId = t('groups.error_group_template_required');
      }

      const connectorsErrors: FormikErrors<GroupConnectors[]> = [];
      data.connectors.forEach((connector, index) => {
        if (!connector.ioId?.trim()) {
          connectorsErrors[index] = { ...connectorsErrors[index], ioId: t('groups.error_io_device_required') };
        }

        if (connector.pin == null) {
          connectorsErrors[index] = { ...connectorsErrors[index], pin: t('groups.error_pin_required') };
        }
      });

      if (connectorsErrors.length) {
        errors.connectors = connectorsErrors;
      }

      return errors;
    },
    onSubmit: async (formData: FormValuesProps, helpers: FormikHelpers<FormValuesProps>) => {
      try {
        if (!formData.id) {
          await groupsService.create(formData);

          showAddToast(formData.name);
        } else {
          await groupsService.edit(formData, formData.id);

          showSaveToast(formData.name);
        }

        hideDialog();
      } catch (error: any) {
        if (error.response.status === 409) {
          const field = error.response.data.field || 'name';
          if (field.includes(':')) {
            const [f, index, cause] = field.split(':');
            helpers.setFieldError(`connectors[${index}].${f}`, t(`groups.connector_erors.${f}_${cause}`));
          } else {
            if (field === 'hostIds') {
              helpers.setFieldError('name', t(`groups.error_io_device_same_host`));
            } else {
              helpers.setFieldError(field, t(`groups.error_${field}_already_taken`));
            }
          }
        }
      }
      helpers.setSubmitting(false);
    },
    enableReinitialize: true,
  });

  const DialogFooter = (
    <>
      <Button
        type="reset"
        label={t('common.cancel')}
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <WriteOnlyButton
        type="submit"
        label={t('common.save')}
        disabled={formik.values.isUsed}
        icon="pi pi-check"
        className="p-button-text"
        onClick={formik.submitForm}
      />
    </>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik}>
      <Dialog
        visible={dialogVisible}
        header={t('groups.group_details')}
        modal
        className="p-fluid"
        footer={DialogFooter}
        onHide={hideDialog}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
        style={{ width: '70vw' }}
      >
        <form>
          <div className="col-10">
            <div className="field">
              <label htmlFor="name">{t('common.name')}</label>
              <InputText
                id="name"
                disabled={formik.values.isUsed}
                value={formik.values.name}
                onChange={formik.handleChange}
                className={formik.touched.name && formik.errors.name && 'p-invalid'}
              />
              <FormErrorMessage fieldName="name" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="groupTemplateId">{t('groups.group_template')}</label>
              <Dropdown
                id="groupTemplateId"
                disabled={formik.values.isUsed}
                value={formik.values.groupTemplateId}
                onChange={(e) => {
                  handleGroupTemplateChange(e.value, formik);
                }}
                options={groupTemplates}
                placeholder={t('common.select')}
                emptyMessage={t('groups.group_templates_empty_message')}
                className={formik.touched.groupTemplateId && formik.errors.groupTemplateId && 'p-invalid'}
              />
              <FormErrorMessage fieldName="groupTemplateId" formikInstance={formik} />
            </div>

            <Fieldset legend={t('groupTemplates.connectors')}>
              <div className="formgrid grid">
                {formik.values.connectors?.map((_, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="field col-4">
                        <label htmlFor={`connectors[${index}].groupTemplateConnectorName`}>{t('common.name')}</label>
                        <InputText
                          disabled
                          id={`connectors[${index}].groupTemplateConnectorName`}
                          value={formik.values.connectors[index].name}
                        />
                      </div>
                      <div className="field col-2">
                        <label htmlFor={`connectors[${index}].typeName`}>{t('common.type')}</label>
                        <InputText
                          disabled
                          id={`connectors[${index}].typeName`}
                          value={formik.values.connectors[index].typeName}
                        />
                      </div>
                      <div className="field col-4">
                        <label htmlFor={`connectors[${index}].ioId`}>{t('groups.io_device')}</label>
                        <Dropdown
                          id={`connectors[${index}].ioId`}
                          value={formik.values.connectors[index].ioId}
                          disabled={formik.values.isUsed}
                          onChange={formik.handleChange}
                          options={io}
                          className={
                            formik.errors?.connectors &&
                            formik.touched?.connectors &&
                            formik.touched?.connectors[index].ioId &&
                            (formik.errors.connectors as FormikErrors<GroupConnectors>[])[index]?.ioId &&
                            'p-invalid'
                          }
                        />
                        <FormErrorMessage
                          fieldName="connectors"
                          formikInstance={formik}
                          index={index}
                          nestedFieldName="ioId"
                        />
                      </div>
                      <div className="field col-2">
                        <label htmlFor={`connectors[${index}].pin`}>{t('groups.pin')}</label>
                        <InputNumber
                          id={`connectors[${index}].pin`}
                          value={formik.values.connectors[index].pin}
                          disabled={formik.values.isUsed}
                          min={0}
                          onValueChange={formik.handleChange}
                          className={
                            formik.errors?.connectors &&
                            formik.touched?.connectors &&
                            formik.touched?.connectors[index].pin &&
                            (formik.errors.connectors as FormikErrors<GroupConnectors>[])[index]?.pin &&
                            'p-invalid'
                          }
                        />
                        <FormErrorMessage
                          fieldName="connectors"
                          formikInstance={formik}
                          index={index}
                          nestedFieldName="pin"
                        />
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </Fieldset>
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
}

export default GroupDialog;
