import { Message } from 'primereact/message';

const FormErrorMessage = ({ fieldName, formikInstance, className, withTouchedTrue, index, nestedFieldName }: any) => {
  const getValue = (root) => {
    const value = root[fieldName];
    if (!value) {
      return undefined;
    }

    if (index !== undefined && nestedFieldName) {
      return value[index] && value[index][nestedFieldName];
    }

    return value;
  };

  const touched = withTouchedTrue || getValue(formikInstance.touched);

  const isFormFieldInvalid = () => touched && getValue(formikInstance.errors);

  let messageId = `error-msg-${fieldName}`;
  if (index !== undefined && nestedFieldName) {
    messageId += `-${index}-${nestedFieldName}`;
  }

  return isFormFieldInvalid() ? (
    <Message id={messageId} severity="error" text={getValue(formikInstance.errors)} className={className} />
  ) : (
    <></>
  );
};

export default FormErrorMessage;
