import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Password } from 'primereact/password';
import React, { useEffect, useState, useRef } from 'react';
import usersService from '../../service/api/UsersService';
import FormErrorMessage from '../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../_shared/FormErrorMessageScroller';
import { Toast } from 'primereact/toast';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import validator from 'validator';
import WriteOnlyButton from '../controls/WriteOnlyButton';

const INIT_FORM_STATE = {
  oldPassword: '',
  password: '',
  confPassword: '',
};

function ChangePasswordDialog({ dialogVisible, closeAddEditModal }) {
  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);

  const currentUser = useSelector((state: RootState) => state.user.loggedUser);

  const hideDialog = () => {
    formik.resetForm({
      values: INIT_FORM_STATE,
    });
    closeAddEditModal();
  };
  const toast = useRef<any>();
  const { t } = useTranslation();

  useEffect(() => {
    if (dialogVisible) {
      setInitFormValues(INIT_FORM_STATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogVisible]);

  const formik = useFormik({
    initialValues: initFormValues,
    validate: (data) => {
      let errors: any = {};

      if (!data.oldPassword?.trim()) {
        errors.oldPassword = t('register_dialog.empty_password_error');
      }
      if (!data.password?.trim()) {
        errors.password = t('register_dialog.empty_password_error');
      }

      if (
        data.password &&
        !validator.isStrongPassword(data.password, {
          minLength: 8,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
        errors.password = t('register_dialog.password_requirements_not_fulfilled_error');
      }

      if (!data.confPassword) {
        errors.confPassword = t('register_dialog.confirmed_password_required');
      }

      if (data.password !== data.confPassword) {
        errors.confPassword = t('register_dialog.confirmed_password_error');
      }

      return errors;
    },
    onSubmit: async (formData, helpers) => {
      usersService
        .changePassword(formData.oldPassword, formData.password, currentUser?.accessToken)
        .then(() => {
          toast.current.show({
            severity: 'success',
            summary: t('users.password_successfully_changed'),
            life: 4000,
          });
          hideDialog();
        })
        .catch((error) => {
          if (error.response.status === 409) {
            helpers.setFieldError('oldPassword', t('users.error_invalid_password'));
          }
        })
        .finally(() => helpers.setSubmitting(false));
    },
    enableReinitialize: true,
  });

  const dialogFooter = (
    <>
      <Button
        type="reset"
        label={t('common.cancel')}
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <WriteOnlyButton
        type="submit"
        label={t('common.save')}
        icon="pi pi-check"
        className="p-button-text"
        onClick={formik.submitForm}
      />
    </>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik}>
      <Dialog
        visible={dialogVisible}
        header={t('users.password_change')}
        modal
        className="p-fluid"
        footer={dialogFooter}
        onHide={hideDialog}
        breakpoints={{ '1400px': '40vw', '896px': '80vw' }}
        style={{ width: '25vw' }}
      >
        <form>
          <div className="col-10">
            <div className="field">
              <label htmlFor="oldPassword">{t('users.old_password')}</label>
              <Password
                id="oldPassword"
                name="oldPassword"
                value={formik.values.oldPassword}
                onChange={formik.handleChange}
                feedback={false}
                toggleMask
                className={formik.touched.oldPassword && formik.errors.oldPassword && 'p-invalid'}
              />
              <FormErrorMessage fieldName="oldPassword" formikInstance={formik} />
            </div>
            <div className="field">
              <label htmlFor="password">{t('users.password')}</label>
              <Password
                id="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                feedback={false}
                toggleMask
                className={formik.touched.password && formik.errors.password && 'p-invalid'}
              />
              <FormErrorMessage fieldName="password" formikInstance={formik} />
            </div>
            <div className="field">
              <label htmlFor="confPassword">{t('users.password_repeat')}</label>
              <Password
                id="confPassword"
                name="confPassword"
                value={formik.values.confPassword}
                onChange={formik.handleChange}
                feedback={false}
                className={formik.touched.confPassword && formik.errors.confPassword && 'p-invalid'}
              />
              <FormErrorMessage fieldName="confPassword" formikInstance={formik} />
            </div>
          </div>
        </form>
      </Dialog>
      <Toast ref={toast} />
    </FormErrorMessageScroller>
  );
}

export default ChangePasswordDialog;
