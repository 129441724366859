import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import rolesService from '../../service/api/RolesService';
import permissionsService from '../../service/api/PermissionsService';
import { Trans, useTranslation } from 'react-i18next';
import { Chip } from 'primereact/chip';
import RoleDialog from './RoleDialog';
import { RoleDetails } from '../../types/Role';
import { Permission } from '../../types/Permission';
import { SETTINGS } from '../../enums/settings.enum';
import WriteOnlyButton from '../controls/WriteOnlyButton';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';

export const RoleManagement = () => {
  const toast = useRef<any>();
  const [roles, setRoles] = useState<RoleDetails[]>(null);
  const [globalFilter, setGlobalFilter] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedRowToEdit, setSelectedRowToEdit] = useState<RoleDetails>(null);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [addEditDialogVisible, setAddEditDialogVisible] = useState(false);
  const [roleToDelete, setRoleToDelete] = useState<RoleDetails>();

  const [permissions, setPermissions] = useState<Permission[]>();
  const { t } = useTranslation();

  const showSaveToast = (summary: string) => {
    toast.current.show({
      severity: 'success',
      summary: summary,
      detail: t('roles.toast_saved'),
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  const showAddToast = (summary: string) => {
    toast.current.show({
      severity: 'success',
      summary: summary,
      detail: t('roles.toast_added'),
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  const showDeleteToast = (summary: string) => {
    toast.current.show({
      severity: 'info',
      summary: summary,
      detail: t('roles.toast_deleted'),
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  const showDeleteErrorToast = (summary: string) => {
    toast.current.show({
      severity: 'error',
      summary: summary,
      detail: t('roles.toast_delete_error'),
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  const loadData = () => {
    setLoading(true);
    Promise.all([rolesService.getDetails(), permissionsService.getAll()]).then((response) => {
      setPermissions(addPermissionsLabels(response[1]));
      setRoles(
        response[0].map((role) => {
          const permissions = addPermissionsLabels(role.permissions);
          return {
            ...role,
            permissions: permissions,
            permissionsSearch: permissions.map((p) => p.name).join(' '),
          };
        })
      );
      setLoading(false);
    });
  };

  const addPermissionsLabels = (permissions: Permission[]) => {
    return permissions.map((permission) => {
      return { id: permission.id, name: t(`permissions.${permission.id}`) };
    });
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableHeader = (
    <div className="table-header">
      <h3 className="m-0">{t('roles.header')}</h3>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder={t('common.search')}
        />
        <Button
          type="button"
          icon="pi pi-plus"
          label={t('common.add')}
          className="p-button ml-2"
          onClick={() => {
            addRole();
          }}
        ></Button>
      </span>
    </div>
  );

  const addRole = () => {
    setSelectedRowToEdit(null);
    setAddEditDialogVisible(true);
  };

  const editRole = (_role: RoleDetails) => {
    setSelectedRowToEdit(_role);
    setAddEditDialogVisible(true);
  };

  const closeAddEditModal = () => {
    setAddEditDialogVisible(false);
    loadData();
  };

  const confirmDeleteRole = (role: RoleDetails) => {
    setRoleToDelete(role);
    setDeleteDialogVisible(true);
  };

  const deleteRole = async () => {
    setLoading(true);
    try {
      await rolesService.delete(roleToDelete.id);
      showDeleteToast(roleToDelete.name);
    } catch (error: any) {
      if (error.response.status === 409) {
        showDeleteErrorToast(roleToDelete.name);
      }
    } finally {
      setDeleteDialogVisible(false);
      loadData();
    }
  };
  const hideDeleteDialog = () => {
    setDeleteDialogVisible(false);
  };

  const deleteDialogFooter = (
    <>
      <Button label={t('common.no')} icon="pi pi-times" className="p-button-text" onClick={() => hideDeleteDialog()} />
      <Button label={t('common.yes')} icon="pi pi-check" className="p-button-text" onClick={() => deleteRole()} />
    </>
  );

  const bodyTemplate = (data: RoleDetails, props: ColumnBodyOptions) => {
    return <>{data[props.field]}</>;
  };

  const permissionsTemplate = (data: any, props: ColumnBodyOptions) => {
    return (
      <>
        {data[props.field].map((item: any) => {
          return item.name ? <Chip key={item.id} label={item.name} className="mr-2 mb-2" /> : null;
        })}
      </>
    );
  };

  const actionTemplate = (data: RoleDetails) => (
    <span>
      <Button
        type="button"
        icon="pi pi-pencil"
        tooltip={t('common.edit')}
        tooltipOptions={{ position: 'top' }}
        className="p-button-success mr-2"
        onClick={() => {
          editRole(data);
        }}
      ></Button>
      <WriteOnlyButton
        type="button"
        icon="pi pi-trash"
        tooltip={t('common.delete')}
        tooltipOptions={{ position: 'top' }}
        className="p-button-success"
        onClick={() => {
          confirmDeleteRole(data);
        }}
      />
    </span>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <DataTable
            value={roles}
            paginator
            className="p-datatable-customers"
            rows={10}
            dataKey="id"
            rowHover
            globalFilter={globalFilter}
            loading={loading}
            header={tableHeader}
          >
            <Column field="name" header={t('roles.name')} sortable body={bodyTemplate}></Column>
            <Column
              field="permissions"
              filterField="permissionsSearch"
              headerStyle={{ width: '70%' }}
              header={t('roles.permissions')}
              body={permissionsTemplate}
            ></Column>
            <Column
              headerStyle={{ width: '8rem' }}
              header={t('common.actions')}
              align="center"
              body={actionTemplate}
            ></Column>
          </DataTable>
          <RoleDialog
            dialogVisible={addEditDialogVisible}
            closeAddEditModal={closeAddEditModal}
            role={selectedRowToEdit}
            allPermissions={permissions}
            showAddToast={showAddToast}
            showSaveToast={showSaveToast}
          />
          <Dialog
            visible={deleteDialogVisible}
            className="p-fluid"
            header={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                {t('common.confirm')}
              </span>
            }
            modal
            footer={deleteDialogFooter}
            onHide={hideDeleteDialog}
            breakpoints={{ '896px': '90vw' }}
            style={{ minWidth: '450px' }}
          >
            <div className="confirmation-content">
              {roleToDelete && (
                <span>
                  <Trans
                    i18nKey="common.confirm_delete_message"
                    values={{ name: roleToDelete.name }}
                    components={[<strong />]}
                  />
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>
      <Toast ref={toast} />
    </div>
  );
};
