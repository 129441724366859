import axios from 'axios';
import { Recipe } from '../../types/Recipe';

class RecipesService {
  async getAll(groupId?: string): Promise<Recipe[]> {
    const result = await axios.get('/recipes', { params: { groupId } });
    return result.data;
  }
  async get(id: string): Promise<Recipe> {
    const result = await axios.get(`/recipes/${id}`);
    return result.data;
  }
  async delete(id: string): Promise<void> {
    return axios.delete(`/recipes/${id}`);
  }
  async create(postData: Recipe): Promise<void> {
    return axios.post(`/recipes`, mapToRequestBody(postData));
  }
  async edit(postData: Recipe, id: string): Promise<void> {
    return axios.put(`/recipes/${id}`, mapToRequestBody(postData));
  }
  async makeDefault(id: string): Promise<void> {
    return axios.put(`/recipes/${id}/default`);
  }
}

const mapToRequestBody = (recipe: Recipe): any => {
  const result = {
    id: recipe.id,
    name: recipe.name,
    useActive: recipe.useActive,
    delayForFinishedS: recipe.delayForFinishedS,
    ingredients: recipe.ingredients,
    groupId: recipe.groupId,
    isDefault: recipe.isDefault,
  };

  return result;
};

const recipesService = new RecipesService();
export default recipesService;
