import axios from 'axios';

class ScaleOrdersService {
  async zero(scaleId: string) {
    return axios.post(`/scales/${scaleId}/zero`);
  }

  async tare(scaleId: string) {
    return axios.post(`/scales/${scaleId}/tare`);
  }

  async reconnect(scaleId: string) {
    return axios.post(`/scales/${scaleId}/reconnect`);
  }
}

let scaleOrdersService = new ScaleOrdersService();
export default scaleOrdersService;
