import React from 'react';
import { Calendar } from 'primereact/calendar';
import { parseISO } from 'date-fns';
import { Button } from 'primereact/button';

import 'chartjs-adapter-date-fns';

import { dateFilter, handleEndDate, handleStartDate } from '../../_shared/functions';
import { ChartToolbarParams } from './types';
import generalStyle from '../../../assets/custom-styles/general.module.scss';
import { MultiSelect } from 'primereact/multiselect';

export const ChartToolbar = (props: ChartToolbarParams): JSX.Element => {
  const { t, startDate, setStartDate, endDate, setEndDate, tags, selectedTags, setSelectedTags } = props;

  const tagTemplate = (option: string) => {
    return (
      <div className="flex align-items-center">
        <span className={`mr-2`} />
        <span>{option}</span>
      </div>
    );
  };

  const selectTagTemplate = (option: string) => {
    if (option) {
      return (
        <div className="inline-flex align-items-center font-bold border-round mr-2">
          <span>{option}</span>
        </div>
      );
    }

    return t('weighings_list.select_tag');
  };

  const FilterToolbar = () => {
    return (
      <React.Fragment>
        <Calendar
          placeholder={t('common.date_from')}
          locale="pl"
          inputId="startDate"
          value={startDate ? parseISO(startDate) : undefined}
          className={`col-12 md:col-2`}
          onChange={(element: any) => handleStartDate(element?.value, endDate, setStartDate, setEndDate)}
          showIcon
        />

        <Calendar
          placeholder={t('common.date_to')}
          locale="pl"
          inputId="endDate"
          value={endDate ? parseISO(endDate) : undefined}
          className={`col-12 md:col-2`}
          onChange={(element: any) => handleEndDate(element?.value, startDate, setStartDate, setEndDate)}
          showIcon
        />
        <span className="col-12 md:col-2 inline-flex">
          <Button
            id="button"
            onClick={() => dateFilter(0, setStartDate, setEndDate)}
            label={t('common.today')}
            icon="pi pi-filter"
            iconPos="right"
            className={`p-button-primary col-12 mt-2 pt-2 pb-2`}
          />
        </span>
        <span className="col-12 md:col-2 inline-flex">
          <Button
            id="button"
            onClick={() => dateFilter(6, setStartDate, setEndDate)}
            label={t('common.last_seven_days')}
            icon="pi pi-filter"
            iconPos="right"
            className={`p-button-primary mt-2 col-12 pt-2 pb-2`}
          />
        </span>

        <span className="col-12 md:col-2 inline-flex">
          <Button
            id="button"
            onClick={() => dateFilter(30, setStartDate, setEndDate)}
            label={t('scale_chart.last_thirty_one_days')}
            icon="pi pi-filter"
            iconPos="right"
            className={`p-button-primary mr-1 mt-2 col-12 pt-2 pb-2`}
          />
        </span>

        <span className="col-12 md:col-2 inline-flex">
          <MultiSelect
            value={selectedTags}
            onChange={(e) => setSelectedTags(e.value)}
            options={tags}
            disabled={!tags.length}
            optionLabel="tag"
            placeholder={t('weighings_list.select_tag')}
            filter
            className={`mr-1 mt-2 col-12 pt-1 pb-1`}
            itemTemplate={tagTemplate}
            selectedItemTemplate={selectTagTemplate}
            maxSelectedLabels={1}
            selectedItemsLabel={`${t('weighings_list.selected_tags')}: ${selectedTags?.length}`}
          />
        </span>
      </React.Fragment>
    );
  };

  return (
    <div className={`${generalStyle.toolbar} px-3 pt-2 pb-3`}>
      <FilterToolbar />
    </div>
  );
};
