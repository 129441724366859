import { TFunction } from 'react-i18next';

const backgroundColor = 'rgba(60,149,210, 0.6)';
const borderColor = 'rgba(60,149,210, 0.6)';
const elements = {
  line: {
    borderWidth: 2,
    borderDash: [3,3],
    borderColor: borderColor,
    backgroundColor: backgroundColor,
    fill: false,
  },
  point: {
    radius: 2.5,
    hitRadius: 5,
    hoverRadius: 5,
    borderColor: borderColor,
    backgroundColor: backgroundColor,
  },
}

export const chartDaysModeOptions = (t: TFunction) => {
  return {
    responsive: true,
    elements: elements,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: backgroundColor,
        mode: 'index',
        displayColors: false,
        callbacks: {
          label: (context: any) => {
            return [
              `${t('common.date')}: ${context.raw.x.substring(0, 10)}`,
              `${t('common.hour')}: ${context.raw.hours}`,
              `${t('scale_chart.kilograms')}: ${context.raw.y}`,
            ]; //it has to be in array to make it put to new line
          },
        },
      },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          displayFormats: {
            day: t('formats.date_format'),
          },
        },
        ticks: {
          max: 7,
        },
      },
      y: {
        ticks: {
          beginAtZero: true,
        },
      },
    },
  };
};

export const chartHoursModeOptions = (t: TFunction) => {
  return {
    responsive: true,
    elements: elements,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: backgroundColor,
        mode: 'index',
        displayColors: false,
        callbacks: {
          label: (context: any) => {
            return [`${t('common.hour')}: ${context.raw.x}`, `${t('scale_chart.kilograms')}: ${context.raw.y}`]; //it has to be in array to make it put to new line
          },
        },
      },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          parser: 'HH:mm:ss',
          unit: 'hour',
          stepSize: 1,
          displayFormats: {
            hour: t('formats.hour_format'),
          },
          tooltipFormat: t('formats.hour_format'),
        },
        ticks: {
          min: '00:00',
          max: '24:00',
        },
      },
      y: {
        ticks: {
          beginAtZero: true,
        },
      },
    },
  };
};
