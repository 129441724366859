import { AxiosConfigContainer } from '../components/AxiosConfig';
import { VerifyLicenseKeyDialog } from '../components/license/VerifyLicenseKeyDialog';

interface VerifyLicenseKeyProps {
  onSuccess: () => void;
}

export const VerifyLicenseKeyPage = ({ onSuccess }: VerifyLicenseKeyProps) => {
  return (
    <>
      <AxiosConfigContainer />

      <div className="login-body">
        <VerifyLicenseKeyDialog onSuccess={onSuccess} dialogVisible={true} onHide={null} />
      </div>
    </>
  );
};
