import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';
import { VerifyLicenseKeyDialog } from './VerifyLicenseKeyDialog';

export const UpdateLicenseKey = ({ dialogVisible, onHide }) => {
  const { t } = useTranslation();

  const [isSuccess, setIsSuccess] = useState(false);

  return (
    <>
      <Dialog
        visible={isSuccess}
        breakpoints={{ '960px': '90vw', '640px': '100vw' }}
        header={t('verifyLicenseKeyPage.licenseUpdated')}
        modal
        className="p-fluid w-33vw"
        onHide={() => window.location.replace('/')}
      />
      <VerifyLicenseKeyDialog
        dialogVisible={dialogVisible}
        onHide={onHide}
        onSuccess={() => {
          setIsSuccess(true);
        }}
      />
    </>
  );
};
