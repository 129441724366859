export const panelTemplate = (options: any) => {
  const className = `${options.className} justify-content-start`;
  const titleClassName = `${options.titleClassName} mr-2`;

  return (
    <div className={className}>
      <span className={titleClassName}>{options.props.header}</span>
    </div>
  );
};

export const dropdownPanelTemplate = (options: any) => {
  const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
  const className = `${options.className} justify-content-start`;
  const titleClassName = `${options.titleClassName} mr-2`;

  return (
    <div className={className}>
      <span className={titleClassName}>{options.props.header}</span>
      <button className={options.togglerClassName} onClick={options.onTogglerClick}>
        <span className={toggleIcon}></span>
      </button>
    </div>
  );
};
