import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';

import divisionsService from '../../../service/api/DivisionsService';
import { Dialog } from 'primereact/dialog';
import DivisionDialog from './DivisionDialog';
import { Trans, useTranslation } from 'react-i18next';
import WriteOnlyButton from '../../controls/WriteOnlyButton';

export const DivisionManagement = () => {
  const toast = useRef<any>();

  const [globalFilter, setGlobalFilter] = useState('');
  const [loading, setLoading] = useState(true);

  const [divisions, setDivisions] = useState<any>(null);
  const [divisionToDelete, setDivisionToDelete] = useState<any>();

  const [selectedRowToEdit, setSelectedRowToEdit] = useState(null);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [addEditDialogVisible, setAddEditDialogVisible] = useState(false);
  const { t } = useTranslation();

  const showDeleteError = () => {
    toast.current.show({
      severity: 'error',
      summary: t('divisions.header'),
      detail: t('divisions.unable_delete_division'),
      life: 8000,
    });
  };

  const loadData = () => {
    setLoading(true);
    Promise.all([divisionsService.getAll()]).then((response) => {
      const _divisions = response[0];
      _divisions.forEach((_x: any) => {});
      setDivisions(_divisions);
      setLoading(false);
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const divisionsTableHeader = (
    <div className="table-header">
      {t('divisions.header')}
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder={t('common.search')}
        />
        <Button
          type="button"
          icon="pi pi-plus"
          label={t('common.add')}
          className="p-button ml-2"
          onClick={() => {
            addDivision();
          }}
        ></Button>
      </span>
    </div>
  );

  const closeAddEditModal = () => {
    setAddEditDialogVisible(false);
    loadData();
  };

  const confirmDeleteDivision = (division: any) => {
    setDivisionToDelete(division);
    setDeleteDialogVisible(true);
  };

  const deleteDivision = () => {
    setLoading(true);
    divisionsService
      .delete(divisionToDelete.id)
      .then(() => {
        loadData();
      })
      .catch((error) => {
        if (error.response.status === 409) {
          showDeleteError();
          setLoading(false);
        }
      });

    setDeleteDialogVisible(false);
  };
  const hideDeleteDialog = () => {
    setDeleteDialogVisible(false);
  };

  const editDivision = (_division: any) => {
    setSelectedRowToEdit(_division);
    setAddEditDialogVisible(true);
  };

  const addDivision = () => {
    setSelectedRowToEdit(null);
    setAddEditDialogVisible(true);
  };

  const deleteDialogFooter = (
    <>
      <Button label={t('common.no')} icon="pi pi-times" className="p-button-text" onClick={() => hideDeleteDialog()} />
      <Button label={t('common.yes')} icon="pi pi-check" className="p-button-text" onClick={() => deleteDivision()} />
    </>
  );

  const bodyTemplate = (data: any, props: any) => {
    return <>{data[props.field]}</>;
  };

  const actionTemplate = (data) => (
    <span>
      <Button
        type="button"
        icon="pi pi-pencil"
        className="p-button-success mr-2"
        onClick={() => {
          editDivision(data);
        }}
      ></Button>
      <WriteOnlyButton
        type="button"
        icon="pi pi-trash"
        className="p-button-success"
        onClick={() => {
          confirmDeleteDivision(data);
        }}
      />
    </span>
  );

  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <DataTable
              value={divisions}
              paginator
              className="p-datatable-customers"
              rows={10}
              dataKey="id"
              rowHover
              globalFilter={globalFilter}
              emptyMessage={t('divisions.empty_message')}
              loading={loading}
              header={divisionsTableHeader}
            >
              <Column field="name" header={t('common.name')} sortable body={bodyTemplate}></Column>
              <Column
                headerStyle={{ width: '8rem' }}
                header={t('common.actions')}
                align="center"
                body={actionTemplate}
              ></Column>
            </DataTable>
            <DivisionDialog
              dialogVisible={addEditDialogVisible}
              closeAddEditModal={closeAddEditModal}
              division={selectedRowToEdit}
            />
            <Dialog
              visible={deleteDialogVisible}
              className="p-fluid"
              header={
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                  {t('common.confirm')}
                </span>
              }
              modal
              footer={deleteDialogFooter}
              onHide={hideDeleteDialog}
              breakpoints={{ '896px': '90vw' }}
              style={{ minWidth: '450px' }}
            >
              <div className="confirmation-content">
                {divisionToDelete && (
                  <span>
                    <Trans
                      i18nKey="common.confirm_delete_message"
                      values={{ name: divisionToDelete.name }}
                      components={[<strong />]}
                    />
                  </span>
                )}
              </div>
            </Dialog>
          </div>
        </div>
      </div>
      <Toast ref={toast} />
    </>
  );
};
