import { Panel } from 'primereact/panel';
import generalStyle from '../../../assets/custom-styles/general.module.scss';
import { panelTemplate } from '../../_shared/panel-template';
import { useTranslation } from 'react-i18next';
import { MeasurementsSeriesManagement } from './MeasurementsSeriesManagement';
import { Toolbar } from 'primereact/toolbar';
import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import FormErrorMessage from '../../_shared/FormErrorMessage';
import autoWeighingConfigsService from '../../../service/api/AutoWeighingConfigsService';
import measurementsSeriesService from '../../../service/api/MeasurementsSeriesService';
import { InputTextarea } from 'primereact/inputtextarea';
import WriteOnlyButton from '../../controls/WriteOnlyButton';

type Props = {
  scaleId: string;
  scaleName: string;
};

const INIT_FORM_STATE: any = {
  name: '',
  description: '',
  autoWeighingConfigId: '',
  scaleId: '',
};

export const OpenMeasurementsSeries = ({ scaleId, scaleName }: Props) => {
  const { t } = useTranslation();

  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);

  const [autoWeighingConfigs, setAutoWeighingConfigs] = useState([]);
  const [measurementsSeries, setMeasurementsSeries] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadData = async () => {
    setLoading(true);

    const [autoWeighingConfigs, measurementSeries] = await Promise.all([
      autoWeighingConfigsService.getAllConfigs(scaleId),
      measurementsSeriesService.getAll({ scaleId, open: true }),
    ]);

    setMeasurementsSeries(measurementSeries);

    const configs = autoWeighingConfigs.map((config: any, index: number) => {
      return {
        label: `${index + 1}. ${config.tag}`,
        value: config.id,
      };
    });
    setAutoWeighingConfigs(configs);

    setLoading(false);
  };

  const onDataChanged = () => {
    loadData();
  };

  useEffect(() => {
    loadData();
    setInitFormValues({ ...INIT_FORM_STATE, scaleId: scaleId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scaleId]);

  const formik = useFormik({
    initialValues: initFormValues,
    validate: (data) => {
      let errors: any = {};

      if (!data.autoWeighingConfigId) {
        errors.autoWeighingConfigId = t('measurementsSeries.auto_weighing_required');
      }

      return errors;
    },
    onSubmit: async (formData: any, helpers: any) => {
      measurementsSeriesService
        .create(formData)
        .then(() => {
          formik.resetForm({ ...INIT_FORM_STATE, scaleId: scaleId });
          loadData();
        })
        .finally(() => {
          helpers.setSubmitting(false);
        });
    },
    enableReinitialize: true,
  });

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <WriteOnlyButton
          type="submit"
          label={t('measurementsSeries.begin_series')}
          icon="pi pi-check"
          className="p-button-info mr-2"
          onClick={formik.submitForm}
        />
      </React.Fragment>
    );
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="formgrid grid">
          <div className="field col-6">
            <label htmlFor="autoWeighingConfigId">{t('measurementsSeries.auto_measurements')}</label>
            <div>
              <Dropdown
                id="autoWeighingConfigId"
                style={{ width: '100%' }}
                value={formik.values.autoWeighingConfigId}
                onChange={formik.handleChange}
                options={autoWeighingConfigs}
                placeholder={t('common.select')}
                emptyMessage={t('measurementsSeries.auto_weighing_no_data')}
                showClear={true}
                className={formik.touched.autoWeighingConfigId && formik.errors.autoWeighingConfigId && 'p-invalid'}
              />
              <FormErrorMessage fieldName="autoWeighingConfigId" formikInstance={formik} />
            </div>
          </div>

          <div className="field col-6">
            <label htmlFor="name">{t('measurementsSeries.name')}</label>
            <div>
              <InputText
                id="name"
                style={{ width: '100%' }}
                value={formik.values.name}
                onChange={formik.handleChange}
                className={formik.touched.name && formik.errors.name && 'p-invalid'}
              />
            </div>
          </div>

          <div className="field col-12">
            <label htmlFor="description">{t('measurementsSeries.description')}</label>
            <div>
              <InputTextarea
                id="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                rows={4}
                className="w-full"
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div>
      <div className="grid list-demo">
        <div className="col-12">
          <Panel
            header={`${t('measurementsSeries.open_header')} - ${scaleName}`}
            className={generalStyle.panelTitle}
            headerTemplate={(e) => panelTemplate(e)}
          >
            <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
            <MeasurementsSeriesManagement
              measurementsSeries={measurementsSeries}
              dataChanged={onDataChanged}
              loading={loading}
              open
            />
          </Panel>
        </div>
      </div>
    </div>
  );
};
