const hasPermission = (requiredPermission: string, userPermissions: string[]) => {
  return userPermissions?.includes(requiredPermission);
};

const hasOneOfPermissions = (requiredPermissions: string[], userPermissions: string[]) => {
  return requiredPermissions
    .map((requiredPermission) => {
      return userPermissions?.includes(requiredPermission);
    })
    .reduce((a, b) => {
      return a || b;
    });
};

export { hasPermission, hasOneOfPermissions };
