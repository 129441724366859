import React from 'react';
import { Toolbar } from 'primereact/toolbar';
import { Calendar } from 'primereact/calendar';
import { parseISO } from 'date-fns';
import { handleEndDate, handleStartDate } from '../_shared/functions';
import { ReceptionsToolbarProps } from './types';

export const ReceptionsToolbar = (props: ReceptionsToolbarProps): JSX.Element => {
  const { t, startDate, setStartDate, endDate, setEndDate } = props;

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Calendar
            placeholder={t('common.date_from')}
            locale="pl"
            inputId="startDate"
            value={startDate ? parseISO(startDate) : undefined}
            className="mr-2"
            onChange={(element: any) => handleStartDate(element?.value, endDate, setStartDate, setEndDate)}
            showIcon
          />

          <Calendar
            placeholder={t('common.date_to')}
            locale="pl"
            inputId="endDate"
            value={endDate ? parseISO(endDate) : undefined}
            className="mr-2"
            onChange={(element: any) => handleEndDate(element?.value, startDate, setStartDate, setEndDate)}
            showIcon
          />
        </div>
      </React.Fragment>
    );
  };

  return <Toolbar className="mb-4" left={leftToolbarTemplate} />;
};
