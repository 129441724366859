import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, useLocation, withRouter } from 'react-router-dom';
import App from './App';
import Access from './pages/Access';
import Error from './pages/Error';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import { RootState } from './store';
import { LocationState } from './types/LocationState';
import { VerifyLicenseKeyPage } from './pages/VerifyLicenseKeyPage';
import installationService from './service/api/InstallationService';
import { modeContext } from './services/ModeContext';

const AppWrapper = (): JSX.Element => {
  const modeCtx = modeContext();
  let location = useLocation<LocationState>();

  const loggedUser = useSelector((state: RootState) => state.user.loggedUser);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const [isAppVerified, setIsAppVerified] = useState(true);

  const fetchOfflineInstallationStatus = useCallback(() => {
    modeCtx.isOffline &&
      installationService.isAppVerified().then((response) => {
        setIsAppVerified(response.data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modeCtx.isOffline, location]);

  useEffect(() => {
    fetchOfflineInstallationStatus();
  }, [fetchOfflineInstallationStatus]);

  if (location.pathname === '/verify-license') {
    return <Route path="/verify-license" render={() => <VerifyLicenseKeyPage onSuccess={null} />} />;
  }

  if (!isAppVerified) {
    return <Route path="/">{<Redirect to="/verify-license" />}</Route>;
  }

  switch (location.pathname) {
    case '/':
      return (
        <Route path="/">
          {loggedUser && <App />}
          {!loggedUser && <Redirect to="/login" />}
        </Route>
      );
    case '/login':
      return <Route path="/login" render={() => <Login />} />;
    case '/error':
      return <Route path="/error" component={Error} />;
    case '/notfound':
      return <Route path="/notfound" component={NotFound} />;
    case '/access':
      return <Route path="/access" component={Access} />;
    default:
      return <App />;
  }
};

export default withRouter(AppWrapper);
