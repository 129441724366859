import { useEffect, useState } from 'react';
import { DataView } from 'primereact/dataview';

import { Scale, ScalesListProps } from './types';
import { InputText } from 'primereact/inputtext';
import { ScalesListToolbar } from './ScalesListToolbar';
import scaleOrdersService from '../../service/api/ScaleOrdersService';
import weighingsService from '../../service/api/WeighingsService';
import { format, parseISO } from 'date-fns';
import { CommunicationMode } from '../../enums/communication-mode.enum';
import WriteOnlyButton from '../controls/WriteOnlyButton';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { scaleIndicationsActions } from '../../store/scale-indications-slice';
import { modeContext } from '../../services/ModeContext';
import { useTranslation } from 'react-i18next';

const layout = 'list';

export const ScalesList = (props: ScalesListProps) => {
  const { scales, itemsSelected, setItemsSelected, showToast } = props;
  const [newItems, setNewItems] = useState([]);

  const { i18n, t } = useTranslation();

  const dispatch = useDispatch();
  const measurements = useSelector((state: RootState) => state.scaleIndications.indications);

  const defaultTag = null;

  const [time, setTime] = useState(Date.now());
  const modeCtx = modeContext();

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const fetchMeasurements = async () => {
    const scalesId = scales.map((element: any) => element.id);

    const indications = await weighingsService.getLastIndications(scalesId);

    let newMeasurements = {};

    for (const indication of indications) {
      newMeasurements[indication.scaleId] = indication;
    }

    dispatch(scaleIndicationsActions.setScaleIndications(newMeasurements));
  };

  useEffect(() => {
    if (modeCtx.isOffline) {
      return;
    }

    fetchMeasurements();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  useEffect(() => {
    fetchMeasurements();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scales, dispatch]);

  const getMeasurementDate = (scaleId: string) => {
    const date = measurements[scaleId]?.measurementTimeLocal;

    const formattedDate = date ? format(parseISO(date), t('formats.full_format')) : '';
    return formattedDate;
  };

  const getMeasurementValue = (scaleId: string): string => {
    return measurements[scaleId]?.value || measurements[scaleId]?.value === 0
      ? measurements[scaleId]?.value.toString()
      : '';
  };

  const addTag = (e: any, scale: any) => {
    let outputArray: any = [];

    itemsSelected.forEach((element: Scale) => {
      if (element.id === scale.id) {
        outputArray.push({ ...element, tag: e.target.value });
      } else {
        outputArray.push(element);
      }
    });

    setNewItems(outputArray);
  };

  const getTag = (scale: Scale) => {
    let tag = defaultTag;

    newItems.forEach((element: Scale) => {
      if (element.id === scale.id) tag = element.tag;
    });

    return tag;
  };

  const handleSaveIndication = async (scale: Scale) => {
    const foundTag = getTag(scale);

    const dataToSend = {
      scaleId: scale.id,
      tag: foundTag,
    };
    showToast(
      `${scale.name}: ${t('scales_indications.save_scale_indication')}`,
      t('scales_indications.toast_save_measurement'),
      'info'
    );

    return await executeCommand(
      async () => {
        await weighingsService.saveScaleIndication(dataToSend);
        showToast(
          `${scale.name}: ${t('scales_indications.save_scale_indication')}`,
          t('scales_indications.toast_saved'),
          'success'
        );
      },
      scale.name,
      false
    );
  };

  const handleSaveIndicationAndPrint = async (scale: Scale) => {
    try {
      const foundTag = getTag(scale);

      const dataToSend = {
        scaleId: scale.id,
        tag: foundTag,
      };
      showToast(
        `${scale.name}: ${t('scales_indications.save_scale_indication_and_print')}`,
        t('scales_indications.toast_save_measurement_and_print'),
        'info'
      );
      await weighingsService.saveScaleIndicationAndPrint(dataToSend);
      showToast(
        `${scale.name}: ${t('scales_indications.save_scale_indication_and_print')}`,
        t('scales_indications.toast_saved_and_printed'),
        'success'
      );
    } catch (error: any) {
      if (error.response.status === 409) {
        const errorKey = error.response.data.error;
        const details = i18n.exists(`printers.errors.${errorKey}`)
          ? t(`printers.errors.${errorKey}`)
          : t(`scales_indications.errors.${errorKey}`);
        showToast(`${scale.name}: ${t('scales_indications.save_scale_indication_and_print')}`, details, 'error');
      }
      if (error.response.status === 404) {
        const details = t(`printers.errors.NOT_FOUND`);
        showToast(`${scale.name}: ${t('scales_indications.save_scale_indication_and_print')}`, details, 'error');
      }
    }
  };

  const executeCommand = async (command, scaleName, showSuccessToast = true) => {
    try {
      await command();
      if (showSuccessToast) {
        showToast(
          `${scaleName}: ${t('scales_indications.success')}`,
          t('scales_indications.success_details'),
          'success'
        );
      }
    } catch (error: any) {
      if (error.response.status === 409) {
        const details = t(`scales_indications.errors.${error.response.data.error}`);
        showToast(`${scaleName}: ${t('scales_indications.error')}`, details, 'error');
      }
    }
  };

  const zeroScale = async (scaleId: string, scaleName: string) => {
    showToast(`${scaleName}: ${t('scales_indications.reset')}`, t('scales_indications.toast_zero'), 'info');
    return await executeCommand(async () => {
      await scaleOrdersService.zero(scaleId);
    }, scaleName);
  };

  const tareScale = async (scaleId: string, scaleName: string) => {
    showToast(`${scaleName}: ${t('scales_indications.tare')}`, t('scales_indications.toast_tare'), 'info');
    return await executeCommand(async () => {
      await scaleOrdersService.tare(scaleId);
    }, scaleName);
  };

  const reconnectScale = async (scaleId: string, scaleName: string) => {
    showToast(`${scaleName}: ${t('scales_indications.reconnect')}`, t('scales_indications.toast_reconnect'), 'info');
    return await executeCommand(async () => {
      await scaleOrdersService.reconnect(scaleId);
    }, scaleName);
  };

  const parseMeasurementValueView = (scale: Scale) => {
    const measurementValue = getMeasurementValue(scale.id);

    const weighingUnit = scale.weighingUnit.toLowerCase();

    return measurementValue ? `${measurementValue} ${weighingUnit}` : '';
  };

  const dataViewListItem = (scale: Scale): JSX.Element | any => {
    return (
      <div key={scale?.id} className="col-12">
        <div className="flex flex-column lg:flex-row align-items-center p-3 w-full">
          <div className="my-4 lg:my-0 w-12 lg:h-5rem lg:w-12rem  mr-4">
            <div className={`font-bold text-2xl text-center lg:text-center mt-3`}>
              {parseMeasurementValueView(scale)}
            </div>
          </div>

          <div className="flex-1 text-center lg:text-left">
            <div className="font-bold text-2xl">{scale.name}</div>
            <div className="mt-3">
              <b>{t('scales_indications.date')}</b>: {getMeasurementDate(scale.id)}
            </div>
          </div>
          <div className="flex flex-wrap flex-column lg:flex-row justify-content-between w-full lg:w-auto align-items-center lg:align-items-end mt-5 lg:mt-0">
            {scale.communicationMode === CommunicationMode.TRIGGERED_BY_SCALE && (
              <WriteOnlyButton
                id="reconnect"
                onClick={() => reconnectScale(scale.id, scale.name)}
                label={t('scales_indications.reconnect')}
                iconPos="right"
                className="p-button-raised p-button-primary mr-2 mb-2"
              />
            )}
            {scale.communicationMode === CommunicationMode.TRIGGERED_BY_US && (
              <>
                <WriteOnlyButton
                  id="button"
                  onClick={() => tareScale(scale.id, scale.name)}
                  label={t('scales_indications.tare')}
                  iconPos="right"
                  className="p-button-raised p-button-primary mr-2 mb-2"
                />

                <WriteOnlyButton
                  id="button"
                  onClick={() => zeroScale(scale.id, scale.name)}
                  label={t('scales_indications.reset')}
                  iconPos="right"
                  className="p-button-raised p-button-primary mr-2 mb-2"
                />

                <WriteOnlyButton
                  id="button"
                  onClick={() => handleSaveIndication(scale)}
                  label={t('scales_indications.save_scale_indication')}
                  iconPos="right"
                  className="p-button-raised p-button-primary mr-2 mb-2"
                />

                <WriteOnlyButton
                  id="button"
                  onClick={() => handleSaveIndicationAndPrint(scale)}
                  label={t('scales_indications.save_scale_indication_and_print')}
                  iconPos="right"
                  className="p-button-raised p-button-primary mr-2 mb-2"
                />

                <InputText
                  key={scale.id}
                  type="text"
                  placeholder={t('common.tag')}
                  className="mr-2 mb-2"
                  value={scale.tag}
                  onChange={(e) => addTag(e, scale)}
                />
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="grid list-demo">
        <div className="col-12">
          <>
            <ScalesListToolbar
              t={t}
              scales={scales}
              itemsSelected={itemsSelected}
              setItemsSelected={setItemsSelected}
            />

            <DataView
              value={itemsSelected}
              layout={layout}
              itemTemplate={dataViewListItem}
              emptyMessage={t('scales_indications.empty_message')}
            ></DataView>
          </>
        </div>
      </div>
    </div>
  );
};
