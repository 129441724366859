import { FormikHelpers, useFormik } from 'formik';
import { TFunction } from 'i18next';
import usersService from '../../service/api/UsersService';
import { PasswordRecoveryInitialValues } from './types';
import validator from 'validator';

export const GetPasswordRecoveryForm = (
  initialValues: PasswordRecoveryInitialValues,
  onSuccess: (email: string) => void,
  t: TFunction
) => {
  const form = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validate: (data: PasswordRecoveryInitialValues) => {
      let errors: any = {};

      if (!data.email) {
        errors.email = t('register_dialog.empty_email_error');
      }

      if (!validator.isEmail(data.email)) {
        errors.email = t('register_dialog.incorrect_email_error');
      }

      return errors;
    },
    onSubmit: async (
      formData: PasswordRecoveryInitialValues,
      helpers: FormikHelpers<PasswordRecoveryInitialValues>
    ) => {
      try {
        await usersService.forgotPassword(formData);

        onSuccess(formData.email);
      } catch (error: any) {
        let code = error?.response?.data?.error;

        switch (code) {
          case 'UserNotFoundException':
            helpers.setFieldError('email', t('confirmation_dialog.user_not_found_error'));
            break;

          default:
            helpers.setFieldError('email', t('login_page.other_error'));
            break;
        }
      }
    },
  });

  return form;
};
