import axios from 'axios';
import { Group } from '../../types/Group';

class GroupsService {
  async getAll(): Promise<Group[]> {
    const result = await axios.get('/groups');
    return result.data;
  }
  async get(id: string): Promise<Group> {
    const result = await axios.get(`/groups/${id}`);
    return result.data;
  }
  async delete(id: string): Promise<void> {
    return axios.delete(`/groups/${id}`);
  }
  async create(postData: Group): Promise<void> {
    return axios.post(`/groups`, mapToRequestBody(postData));
  }
  async edit(postData: Group, id: string): Promise<void> {
    return axios.put(`/groups/${id}`, mapToRequestBody(postData));
  }
}

const mapToRequestBody = (group: Group): any => {
  const result = {
    id: group.id,
    name: group.name,
    groupTemplateId: group.groupTemplateId,
    connectors: group.connectors,
  };

  return result;
};

const groupsService = new GroupsService();
export default groupsService;
