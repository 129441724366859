import axios from 'axios';
import { License } from '../../types/License';

class LicensesService {
  async getAll(): Promise<License[]> {
    const result = await axios.get('/licenses');
    return result.data.map((d) => {
      return {
        ...d,
        expirationDate: d.expirationDate && new Date(d.expirationDate),
      };
    });
  }
  async get(id: string): Promise<License> {
    const result = await axios.get(`/licenses/${id}`);
    return (
      result.data ?? {
        ...result.data,
        expirationDate: result.data.expirationDate && new Date(result.data.expirationDate),
      }
    );
  }
  async getLicenseKey(id: string): Promise<string> {
    const result = await axios.get(`/licenses/${id}/licenseKey`);
    return result.data;
  }
  async delete(id: string): Promise<void> {
    return axios.delete(`/licenses/${id}`);
  }
  async create(postData: License): Promise<void> {
    return axios.post(`/licenses`, mapToRequestBody(postData));
  }
  async edit(postData: License, id: string): Promise<void> {
    return axios.put(`/licenses/${id}`, mapToRequestBody(postData));
  }
}

const mapToRequestBody = (license: License): any => {
  const result = {
    id: license.id,
    customerId: license.customerId,
    type: license.type,
    email: license.email,
    deviceKey: license.deviceKey,
    version: license.version,
    expirationDate: license.expirationDate,
    limits: {
      usersNumber: license.limits.usersNumber,
      scalesNumber: license.limits.scalesNumber,
    },
    comment: license.comment,
  };

  return result;
};

const licensesService = new LicensesService();
export default licensesService;
