import { FormikErrors, FormikHelpers, useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import printersService from '../../service/api/PrintersService';
import FormErrorMessage from '../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../_shared/FormErrorMessageScroller';
import { useTranslation } from 'react-i18next';
import { Printer } from '../../types/Printer';
import { modeContext } from '../../services/ModeContext';
import validator from 'validator';
import WriteOnlyButton from '../controls/WriteOnlyButton';

interface FormValuesProps extends Printer {}

const INIT_FORM_STATE: FormValuesProps = {
  id: null,
  name: '',
  description: '',
  hostId: null,
  divisionId: null,
  connectionType: '',
  ipAddress: '',
  port: null,
  portPath: '',
  baudRate: null,
  databits: 8,
  parity: 'none',
  stopbits: 1,
  flowControl: 'NONE',
  vendorId: '',
  productId: '',
};

type Props = {
  dialogVisible: boolean;
  closeAddEditModal: VoidFunction;
  printer: Printer;

  availableHosts: any[];

  availableDivisions: any[];
};

function PrinterDialog({
  dialogVisible,
  closeAddEditModal,
  printer,

  availableHosts,

  availableDivisions,
}: Props) {
  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);
  const modeCtx = modeContext();

  const hideDialog = () => {
    formik.resetForm({ values: INIT_FORM_STATE });
    closeAddEditModal();
  };

  console.log(printer);

  const hosts = availableHosts?.map((r) => {
    return { label: r.name, value: r.id };
  });

  const divisions = availableDivisions?.map((r) => {
    return { label: r.name, value: r.id };
  });

  const { t } = useTranslation();

  const connectionTypes = [
    { label: 'Ethernet', value: 'ETHERNET' },
    { label: 'USB', value: 'USB' },
    { label: 'RS-232', value: 'RS232' },
  ];

  const parity = [
    { label: 'None', value: 'none' },
    { label: 'Odd', value: 'odd' },
    { label: 'Even', value: 'even' },
    { label: 'Mark', value: 'mark' },
    { label: 'Space', value: 'space' },
  ];

  const databits = [
    { label: '8', value: 8 },
    { label: '7', value: 7 },
    { label: '6', value: 6 },
    { label: '5', value: 5 },
  ];

  const stopbits = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
  ];

  const flowControl = [
    { label: 'None', value: 'NONE' },
    { label: 'Hardware (RTS/CTS)', value: 'HARDWARE' },
    { label: 'Software (XON/XOFF)', value: 'SOFTWARE' },
  ];

  useEffect(() => {
    if (dialogVisible && printer) {
      setInitFormValues({
        id: printer.id,
        name: printer.name,
        description: printer.description,
        hostId: printer.hostId,
        divisionId: printer.divisionId,
        connectionType: printer.connectionType,
        ipAddress: printer.ipAddress,
        port: printer.port,
        portPath: printer.portPath,
        baudRate: printer.baudRate,
        databits: printer.databits,
        stopbits: printer.stopbits,
        flowControl: printer.flowControl,
        parity: printer.parity,
        vendorId: printer.vendorId,
        productId: printer.productId,
      });
    } else {
      setInitFormValues(INIT_FORM_STATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogVisible]);

  const handleConflictError = (error: any, helpers: FormikHelpers<FormValuesProps>) => {
    if (error.response.data.field === 'name') {
      helpers.setFieldError('name', t('printers.error_name_already_taken'));
    } else if (error.response.data.field === 'ipAddress') {
      helpers.setFieldError('ipAddress', t('printers.error_ip_address_already_taken'));
      helpers.setFieldError('port', t('printers.error_ip_address_already_taken'));
    } else if (error.response.data.field === 'portPath') {
      helpers.setFieldError('portPath', t('printers.error_port_path_already_taken'));
    } else if (error.response.data.field === 'vendorId') {
      helpers.setFieldError('vendorId', t('printers.error_vendor_id_product_id_already_taken'));
      helpers.setFieldError('productId', t('printers.error_vendor_id_product_id_already_taken'));
    } else if (error.response.data.field === 'hostId') {
      helpers.setFieldError('hostId', t('printers.error_different_host'));
    }
  };

  const formik = useFormik({
    initialValues: initFormValues,
    validate: (data: FormValuesProps) => {
      let errors: FormikErrors<FormValuesProps> = {};

      if (!data.name?.trim()) {
        errors.name = t('common.error_name_required');
      }

      if (!data.divisionId) {
        errors.divisionId = t('scales.error_division_required');
      }

      if (!data.connectionType?.trim()) {
        errors.connectionType = t('printers.error_connection_type_required');
      }

      if (data.ipAddress?.trim() && !validator.isIP(data.ipAddress)) {
        errors.ipAddress = t('printers.error_invalid_ip_address');
      }

      if (data.connectionType === 'ETHERNET') {
        if (!data.ipAddress?.trim()) {
          errors.ipAddress = t('printers.error_ip_address_required');
        }
        if (data.port === null) {
          errors.port = t('printers.error_port_required');
        }
      } else if (data.connectionType === 'RS232') {
        if (!data.portPath?.trim()) {
          errors.portPath = t('printers.error_port_path_required');
        }
        if (data.baudRate === null) {
          errors.baudRate = t('printers.error_baud_rate_required');
        }
      } else if (data.connectionType === 'USB') {
        if (!data.vendorId?.trim()) {
          errors.vendorId = t('printers.error_vendor_id_required');
        }
        if (!data.productId?.trim()) {
          errors.productId = t('printers.error_product_id_required');
        }
      }

      return errors;
    },
    onSubmit: async (formData: FormValuesProps, helpers: FormikHelpers<FormValuesProps>) => {
      if (!formData.id) {
        printersService
          .create(formData)
          .then((_res: any) => {
            hideDialog();
          })
          .catch((error) => {
            if (error.response.status === 409) {
              handleConflictError(error, helpers);
            }
          })
          .finally(() => helpers.setSubmitting(false));
      } else {
        printersService
          .edit(formData, formData.id)
          .then(() => {
            hideDialog();
          })
          .catch((error) => {
            if (error.response.status === 409) {
              handleConflictError(error, helpers);
            }
          })
          .finally(() => helpers.setSubmitting(false));
      }
    },
    enableReinitialize: true,
  });

  const DialogFooter = (
    <>
      <Button
        type="reset"
        label={t('common.cancel')}
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <WriteOnlyButton
        type="submit"
        label={t('common.save')}
        icon="pi pi-check"
        className="p-button-text"
        onClick={formik.submitForm}
      />
    </>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik}>
      <Dialog
        visible={dialogVisible}
        header={t('printers.printer_details')}
        modal
        className="p-fluid"
        footer={DialogFooter}
        onHide={hideDialog}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
        style={{ width: '40vw' }}
      >
        <form>
          <div className="col-10">
            <div className="field">
              <label htmlFor="name">{t('common.name')}</label>
              <InputText
                id="name"
                value={formik.values.name}
                readOnly={printer ? true : undefined}
                disabled={printer ? true : undefined}
                onChange={formik.handleChange}
                className={formik.touched.name && formik.errors.name && 'p-invalid'}
              />
              <FormErrorMessage fieldName="name" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="description">{t('printers.description')}</label>
              <InputText
                id="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                className={formik.touched.description && formik.errors.description && 'p-invalid'}
              />
              <FormErrorMessage fieldName="description" formikInstance={formik} />
            </div>

            {modeCtx.hostsEnabled && (
              <div className="field">
                <label htmlFor="hostId">{t('printers.host')}</label>
                <Dropdown
                  id="hostId"
                  value={formik.values.hostId}
                  onChange={formik.handleChange}
                  options={hosts}
                  placeholder={t('common.select')}
                  emptyMessage={t('printers.hosts_empty_message')}
                  className={formik.touched.hostId && formik.errors.hostId && 'p-invalid'}
                />
                <FormErrorMessage fieldName="hostId" formikInstance={formik} />
              </div>
            )}

            <div className="field">
              <label htmlFor="divisionId">{t('printers.division')}</label>
              <Dropdown
                id="divisionId"
                value={formik.values.divisionId}
                onChange={formik.handleChange}
                options={divisions}
                placeholder={t('common.select')}
                emptyMessage={t('printers.divisions_empty_message')}
                className={formik.touched.divisionId && formik.errors.divisionId && 'p-invalid'}
              />
              <FormErrorMessage fieldName="divisionId" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="connectionType">{t('printers.connection_type')}</label>
              <Dropdown
                id="connectionType"
                value={formik.values.connectionType}
                onChange={formik.handleChange}
                options={connectionTypes}
                placeholder={t('common.select')}
                className={formik.touched.connectionType && formik.errors.connectionType && 'p-invalid'}
              />
              <FormErrorMessage fieldName="connectionType" formikInstance={formik} />
            </div>

            {formik.values.connectionType === 'ETHERNET' && (
              <>
                <div className="field">
                  <label htmlFor="ipAddress">{t('printers.ip_address')}</label>
                  <InputText
                    id="ipAddress"
                    value={formik.values.ipAddress}
                    onChange={formik.handleChange}
                    className={formik.touched.ipAddress && formik.errors.ipAddress && 'p-invalid'}
                  />
                  <FormErrorMessage fieldName="ipAddress" formikInstance={formik} />
                </div>

                <div className="field">
                  <label htmlFor="port">{t('printers.port')}</label>
                  <InputNumber
                    id="port"
                    value={formik.values.port}
                    onValueChange={formik.handleChange}
                    className={formik.touched.port && formik.errors.port && 'p-invalid'}
                  />
                  <FormErrorMessage fieldName="port" formikInstance={formik} />
                </div>
              </>
            )}

            {formik.values.connectionType === 'RS232' && (
              <>
                <div className="field">
                  <label htmlFor="portPath">{t('printers.port_path')}</label>
                  <InputText
                    id="portPath"
                    value={formik.values.portPath}
                    onChange={formik.handleChange}
                    className={formik.touched.portPath && formik.errors.portPath && 'p-invalid'}
                  />
                  <FormErrorMessage fieldName="portPath" formikInstance={formik} />
                </div>

                <div className="field">
                  <label htmlFor="baudRate">{t('printers.baud_rate')}</label>
                  <InputNumber
                    id="baudRate"
                    value={formik.values.baudRate}
                    onValueChange={formik.handleChange}
                    className={formik.touched.baudRate && formik.errors.baudRate && 'p-invalid'}
                  />
                  <FormErrorMessage fieldName="baudRate" formikInstance={formik} />
                </div>
                <div className="field">
                  <label htmlFor="databits">{t('printers.databits')}</label>
                  <Dropdown
                    id="databits"
                    value={formik.values.databits}
                    onChange={formik.handleChange}
                    options={databits}
                    placeholder={t('common.select')}
                    className={formik.touched.databits && formik.errors.databits && 'p-invalid'}
                  />
                  <FormErrorMessage fieldName="databits" formikInstance={formik} />
                </div>
                <div className="field">
                  <label htmlFor="stopbits">{t('printers.stopbits')}</label>
                  <Dropdown
                    id="stopbits"
                    value={formik.values.stopbits}
                    onChange={formik.handleChange}
                    options={stopbits}
                    placeholder={t('common.select')}
                    className={formik.touched.stopbits && formik.errors.stopbits && 'p-invalid'}
                  />
                  <FormErrorMessage fieldName="stopbits" formikInstance={formik} />
                </div>
                <div className="field">
                  <label htmlFor="flowControl">{t('printers.flow_control')}</label>
                  <Dropdown
                    id="flowControl"
                    value={formik.values.flowControl}
                    onChange={formik.handleChange}
                    options={flowControl}
                    placeholder={t('common.select')}
                    className={formik.touched.flowControl && formik.errors.flowControl && 'p-invalid'}
                  />
                  <FormErrorMessage fieldName="flowControl" formikInstance={formik} />
                </div>
                <div className="field">
                  <label htmlFor="parity">{t('printers.parity')}</label>
                  <Dropdown
                    id="parity"
                    value={formik.values.parity}
                    onChange={formik.handleChange}
                    options={parity}
                    placeholder={t('common.select')}
                    className={formik.touched.parity && formik.errors.parity && 'p-invalid'}
                  />
                  <FormErrorMessage fieldName="parity" formikInstance={formik} />
                </div>
              </>
            )}

            {formik.values.connectionType === 'USB' && (
              <>
                <div className="field">
                  <label htmlFor="vendorId">{t('printers.vendor_id')}</label>
                  <InputText
                    id="vendorId"
                    value={formik.values.vendorId}
                    onChange={formik.handleChange}
                    className={formik.touched.vendorId && formik.errors.vendorId && 'p-invalid'}
                  />
                  <FormErrorMessage fieldName="vendorId" formikInstance={formik} />
                </div>

                <div className="field">
                  <label htmlFor="productId">{t('printers.product_id')}</label>
                  <InputText
                    id="productId"
                    value={formik.values.productId}
                    onChange={formik.handleChange}
                    className={formik.touched.productId && formik.errors.productId && 'p-invalid'}
                  />
                  <FormErrorMessage fieldName="productId" formikInstance={formik} />
                </div>
              </>
            )}
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
}

export default PrinterDialog;
