import { configureStore } from '@reduxjs/toolkit';
import userSlice from './user-slice';
import scaleIndicationsSlice from './scale-indications-slice';

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    scaleIndications: scaleIndicationsSlice.reducer,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
