import { MeasurementsSeriesManagement } from './MeasurementsSeriesManagement';
import React, { useEffect, useState } from 'react';
import { Panel } from 'primereact/panel';
import generalStyle from '../../../assets/custom-styles/general.module.scss';
import { panelTemplate } from '../../_shared/panel-template';
import { useTranslation } from 'react-i18next';
import measurementsSeriesService from '../../../service/api/MeasurementsSeriesService';

type Props = {
  scaleId: string;
  scaleName: string;
};

export const ClosedMeasurementsSeries = ({ scaleId, scaleName }: Props) => {
  const { t } = useTranslation();

  const [measurementsSeries, setMeasurementsSeries] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadData = () => {
    setLoading(true);

    measurementsSeriesService.getAll({ scaleId, open: false }).then((data) => {
      setMeasurementsSeries(data);
    });

    setLoading(false);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scaleId]);

  const onDataChanged = () => {
    loadData();
  };

  return (
    <div>
      <div className="grid list-demo">
        <div className="col-12">
          <Panel
            header={`${t('measurementsSeries.closed_header')} - ${scaleName}`}
            className={generalStyle.panelTitle}
            headerTemplate={(e) => panelTemplate(e)}
          >
            <MeasurementsSeriesManagement
              measurementsSeries={measurementsSeries}
              dataChanged={onDataChanged}
              loading={loading}
            />
          </Panel>
        </div>
      </div>
    </div>
  );
};
