import { FormikErrors, FormikHelpers, useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import recipesService from '../../service/api/RecipesService';
import FormErrorMessage from '../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../_shared/FormErrorMessageScroller';
import { useTranslation } from 'react-i18next';
import { Recipe } from '../../types/Recipe';
import { INIT_FORM_STATE as INGREDIENT_FORM_STATE, RecipeIngredientTemplate } from './RecipeIngredientItem';
import { deepCopy } from '../_shared/objectHelpers';
import WriteOnlyButton from '../controls/WriteOnlyButton';
import { Group } from '../../types/Group';
import { Checkbox } from 'primereact/checkbox';
import { InputNumber } from 'primereact/inputnumber';

interface FormValuesProps extends Recipe {}

type Props = {
  dialogVisible: boolean;
  closeAddEditModal: VoidFunction;
  recipe: Recipe;
  group: Group;
  showAddToast: (name: string) => void;
  showSaveToast: (name: string) => void;
};

function RecipeDialog({ dialogVisible, closeAddEditModal, recipe, group, showAddToast, showSaveToast }: Props) {
  const outputConnectors = group.connectors.filter(
    (c) => c.groupTemplateConnectorType === 'OUTPUT' && c.groupTemplateConnectorFunction === 'STANDARD'
  );
  const INIT_FORM_STATE: FormValuesProps = {
    id: null,
    name: '',
    useActive: true,
    delayForFinishedS: 0,
    groupId: group.id,
    isDefault: false,
    ingredients: outputConnectors.map((connector) => {
      return { ...INGREDIENT_FORM_STATE, groupConnectorId: connector.id, name: connector.groupTemplateConnectorName };
    }),
  };

  const [initFormValues, setInitFormValues] = useState(deepCopy(INIT_FORM_STATE));

  const hideDialog = () => {
    formik.resetForm();
    closeAddEditModal();
  };

  const { t } = useTranslation();

  useEffect(() => {
    if (dialogVisible && recipe) {
      setInitFormValues({
        id: recipe.id,
        name: recipe.name,
        useActive: recipe.useActive,
        delayForFinishedS: recipe.delayForFinishedS,
        groupId: group.id,
        ingredients: recipe.ingredients.map((i) => {
          return { ...i, name: group.connectors.find((c) => c.id === i.groupConnectorId)?.groupTemplateConnectorName };
        }),
      });
    } else {
      setInitFormValues(deepCopy(INIT_FORM_STATE));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogVisible]);

  const formik = useFormik({
    initialValues: initFormValues,
    validate: (data: FormValuesProps) => {
      let errors: FormikErrors<FormValuesProps> = {};

      if (!data.name?.trim()) {
        errors.name = t('common.error_name_required');
      }

      const ingredientsErrors = data.ingredients.map((ingredient) => {
        const innerErrors: any = {};
        if (ingredient.quantity == null) {
          innerErrors.quantity = t('recipeIngredients.error_quantity_required');
        }
        if (ingredient.sequenceNumber == null) {
          innerErrors.sequenceNumber = t('recipeIngredients.error_sequence_number_required');
        }
        return Object.keys(innerErrors).length ? innerErrors : null;
      });

      if (ingredientsErrors.filter(Boolean).length) {
        errors.ingredients = ingredientsErrors;
      }

      return errors;
    },
    onSubmit: async (formData: FormValuesProps, helpers: FormikHelpers<FormValuesProps>) => {
      try {
        if (!formData.id) {
          await recipesService.create(formData);

          showAddToast(formData.name);
        } else {
          await recipesService.edit(formData, formData.id);

          showSaveToast(formData.name);
        }

        hideDialog();
      } catch (error: any) {
        if (error.response.status === 409) {
          const field = error.response.data.field || 'name';
          helpers.setFieldError(field, t(`recipes.error_${field}_already_taken`));
        }
        if (error.response.status === 400) {
          helpers.setErrors({
            ingredients: Array(formData.ingredients.length).fill({
              sequenceNumber: t('recipeIngredients.error_sequence_number_unique'),
            }),
          });
        }
      }
      helpers.setSubmitting(false);
    },
    enableReinitialize: true,
  });

  const DialogFooter = (
    <>
      <Button
        type="reset"
        label={t('common.cancel')}
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <WriteOnlyButton
        type="submit"
        label={t('common.save')}
        icon="pi pi-check"
        className="p-button-text"
        onClick={formik.submitForm}
      />
    </>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik}>
      <Dialog
        visible={dialogVisible}
        header={t('recipes.recipe_details')}
        modal
        className="p-fluid"
        footer={DialogFooter}
        onHide={hideDialog}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
        style={{ width: '40vw' }}
      >
        <form>
          <div className="col-10">
            <div className="field">
              <label htmlFor="name">{t('common.name')}</label>
              <InputText
                id="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                className={formik.touched.name && formik.errors.name && 'p-invalid'}
              />
              <FormErrorMessage fieldName="name" formikInstance={formik} />
            </div>
            <div className="field-checkbox">
              <Checkbox id="useActive" checked={formik.values.useActive} onChange={formik.handleChange} />
              <label htmlFor="active">{t('recipes.useActive')}</label>
            </div>
            <div className="field">
              <label htmlFor="delayForFinishedS">{t('recipes.delayForFinished')}</label>
              <InputNumber
                id="delayForFinishedS"
                value={formik.values.delayForFinishedS}
                onValueChange={formik.handleChange}
                min={0}
                max={1000000}
                className={formik.touched.delayForFinishedS && formik.errors.delayForFinishedS && 'p-invalid'}
              />
              <FormErrorMessage fieldName="delayForFinishedS" formikInstance={formik} />
            </div>

            <div className="col-12 grid">
              {formik.values.ingredients?.map((_i, index) => RecipeIngredientTemplate(formik, index, t))}
            </div>
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
}

export default RecipeDialog;
