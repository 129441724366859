import React, { useEffect, useRef, useState } from 'react';
import measurementsSeriesService from '../../../service/api/MeasurementsSeriesService';
import { Panel } from 'primereact/panel';
import generalStyle from '../../../assets/custom-styles/general.module.scss';
import { panelTemplate } from '../../_shared/panel-template';
import { useTranslation } from 'react-i18next';
import { Toolbar } from 'primereact/toolbar';
import { DataTable } from 'primereact/datatable';
import { MeasurementsSeries } from '../../../types/MeasurementsSeries';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { format, parseISO, toDate } from 'date-fns';
import { SETTINGS } from '../../../enums/settings.enum';
import { InputText } from 'primereact/inputtext';
import { Chart } from 'primereact/chart';
import { chartDaysModeOptions } from '../../_shared/chart-settings';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { REPORT_TYPES } from '../../../enums/report-types.enum';
import { getFileFromBuffer } from '../../_shared/getFile';
import { InputTextarea } from 'primereact/inputtextarea';

type Props = {
  seriesId: string;
};

export const MeasurementsSeriesDetails = ({ seriesId }: Props) => {
  const { t } = useTranslation();

  const overlayPanel = useRef(null);

  const [measurementsSeries, setMeasurementsSeries] = useState<MeasurementsSeries>(null);
  const [measurements, setMeasurements] = useState([]);
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(false);

  const [pdfLoading, setPdfLoading] = useState(false);
  const [excelLoading, setExcelLoading] = useState(false);

  const loadData = () => {
    setLoading(true);
    measurementsSeriesService.get(seriesId).then((d) => {
      setMeasurementsSeries(d);

      const measurements = d.measurements.map((m) => {
        return {
          value: `${m.value} ${m.weighingUnit.toLowerCase()}`,
          date: format(parseISO(m.measurementTimeLocal), SETTINGS.FULL_DATE_FORMAT),
          scaleName: m.scaleName,
        };
      });
      setMeasurements(measurements);

      const points = d.measurements.map((m) => {
        return {
          y: m.value,
          x: format(parseISO(m.measurementTimeLocal), SETTINGS.FULL_DATE_FORMAT),
          hours: format(parseISO(m.measurementTimeLocal), SETTINGS.HOURS_DATE_FORMAT),
        };
      });
      setChartData({
        datasets: [
          {
            data: points,
          },
        ],
      });

      setLoading(false);
    });
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seriesId]);

  const downloadReport = (reportFormat: string) => {
    let report: any = {
      download: true,
    };

    if (reportFormat === REPORT_TYPES.EXCEL) {
      report.format = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      report.extension = '.xlsx';
    } else {
      report.format = 'application/pdf';
      report.extension = '.pdf';
    }

    exportReport(report, reportFormat);
  };

  const exportReport = (options: any, reportFormat: string) => {
    measurementsSeriesService.getReport(seriesId, options.format).then((report: any) => {
      reportFormat === REPORT_TYPES.EXCEL ? setExcelLoading(false) : setPdfLoading(false);

      !options.download && getFileFromBuffer(report.data, options.format);
      options.download && getFileFromBuffer(report.data, options.format, prepareFileName());
    });
  };

  const prepareFileName = (): string => {
    const formattedDate = format(Date.now(), 'yyyyMMdd_HHmmss');
    return `${t('measurementsSeries.report_name')}__${formattedDate}`;
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="grid">
          <div className="field col-6">
            <label htmlFor="auto_measurements">{t('measurementsSeries.auto_measurements')}</label>
            <div>
              <InputText
                id="auto_measurements"
                disabled
                value={measurementsSeries.autoWeighingConfigTag}
                type="text"
                className="w-full"
              />
            </div>
          </div>

          <div className="field col-6">
            <label htmlFor="name">{t('measurementsSeries.name')}</label>
            <div>
              <InputText id="name" disabled value={measurementsSeries.name} type="text" className="w-full" />
            </div>
          </div>

          <div className="field col-12">
            <label htmlFor="description">{t('measurementsSeries.description')}</label>
            <div>
              <InputTextarea
                id="description"
                disabled
                className="w-full"
                rows={4}
                autoResize
                value={measurementsSeries.description}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const toggleOverlayPanel = (event: any) => {
    overlayPanel.current.toggle(event);
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          type="button"
          label={t('common.export')}
          onClick={toggleOverlayPanel}
          className={`col-11  md:col-12 mr-1 mt-2 md:pr-3 md:pl-3`}
          icon="pi pi-download"
          iconPos="right"
          disabled={!measurements.length}
        />
        <OverlayPanel ref={overlayPanel} appendTo={document.body}>
          <Button
            label="PDF"
            className="p-button-plain p-button-text"
            icon="pi pi-file-pdf"
            onClick={() => {
              downloadReport(REPORT_TYPES.PDF);
              setPdfLoading(true);
            }}
            disabled={excelLoading}
            loading={pdfLoading}
          />
          <br />
          <Button
            label="Excel"
            className="p-button-plain p-button-text export-button-menu-width"
            icon="pi pi-file-excel"
            onClick={() => {
              downloadReport(REPORT_TYPES.EXCEL);
              setExcelLoading(true);
            }}
            disabled={pdfLoading}
            loading={excelLoading}
          />
        </OverlayPanel>
      </React.Fragment>
    );
  };

  const dateTemplate = (rowData: any): JSX.Element => (
    <>{format(toDate(parseISO(rowData.date)), t('formats.full_format'))}</>
  );

  const bodyTemplate = (data: MeasurementsSeries, props: ColumnBodyOptions) => {
    return <>{data[props.field]}</>;
  };

  return (
    <div>
      {measurementsSeries && (
        <div className="grid list-demo">
          <div className="col-12">
            <Panel
              header={`${t('measurementsSeries.header_singular')} `}
              className={generalStyle.panelTitle}
              headerTemplate={(e) => panelTemplate(e)}
            >
              <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

              <div className="card">
                <Chart type="line" data={chartData} options={chartDaysModeOptions(t)} />
              </div>

              <div className="card">
                <DataTable
                  value={measurements}
                  paginator
                  className="p-datatable-customers"
                  rows={10}
                  dataKey="id"
                  rowHover
                  emptyMessage={t('measurementsSeries.empty_message')}
                  loading={loading}
                >
                  <Column field="value" header={t('weighings_list.indication')} sortable body={bodyTemplate}></Column>
                  <Column field="date" header={t('common.date')} sortable body={dateTemplate}></Column>
                  <Column field="scaleName" header={t('weighings_list.scale')} body={bodyTemplate}></Column>
                </DataTable>
              </div>
            </Panel>
          </div>
        </div>
      )}
    </div>
  );
};
