import axios from 'axios';
import { DateFilters, PaginationParams } from '../../types/general-types';

class ReceptionsService {
  async getAllReceptions(paginationParams: PaginationParams, dateFilters: DateFilters | null) {
    let dataToSend: any = {
      paginationParams,
    };

    if (dateFilters) {
      dataToSend.dateFilters = dateFilters;
    }

    const result = await axios.post(`/receptions`, dataToSend);

    return result.data;
  }
}

let receptionsService = new ReceptionsService();
export default receptionsService;
