import axios from 'axios';
import { Animal } from '../../types/Animal';
import { Measurement } from '../../types/Measurement';

class AnimalsService {
  async getAll(): Promise<Animal[]> {
    const result = await axios.get('/animals');
    return result.data;
  }
  async get(id: string): Promise<Animal> {
    const result = await axios.get(`/animals/${id}`);
    return result.data;
  }
  async getMeasurements(id: string): Promise<Measurement[]> {
    const result = await axios.get(`/animals/${id}/measurements`);
    return result.data;
  }
  delete(id: string): Promise<void> {
    return axios.delete(`/animals/${id}`);
  }
  async create(postData: Animal): Promise<void> {
    return axios.post(`/animals`, mapToRequestBody(postData));
  }
  async edit(postData: Animal, id: string): Promise<void> {
    return axios.put(`/animals/${id}`, mapToRequestBody(postData));
  }
}

const mapToRequestBody = (animal: Animal): any => {
  const result = {
    id: animal.id,
    name: animal.name,
    description: animal.description,
    rfid: animal.rfid,
    divisionId: animal.divisionId,
  };

  return result;
};

const animalsService = new AnimalsService();
export default animalsService;
