import { FormikHelpers, useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import usersService from '../../service/api/UsersService';
import FormErrorMessage from '../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../_shared/FormErrorMessageScroller';
import { useTranslation } from 'react-i18next';
import { User } from '../../types/User';
import WriteOnlyButton from '../controls/WriteOnlyButton';

interface FormValuesProps extends User {}

const INIT_FORM_STATE: FormValuesProps = {
  id: null,
  email: '',
  firstName: '',
  lastName: '',
};

type Props = {
  dialogVisible: boolean;
  closeAddEditModal: VoidFunction;
  user: User;
};

function UserDialog({ dialogVisible, closeAddEditModal, user }: Props) {
  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);

  const hideDialog = () => {
    formik.resetForm({ values: INIT_FORM_STATE });
    closeAddEditModal();
  };

  const { t } = useTranslation();

  useEffect(() => {
    if (dialogVisible && user?.id) {
      setInitFormValues({
        id: user.id,
        email: user.email,
        firstName: user.firstName ? user.firstName : '',
        lastName: user.lastName ? user.lastName : '',
      });
    } else {
      setInitFormValues(INIT_FORM_STATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogVisible]);

  const formik = useFormik({
    initialValues: initFormValues,
    validate: () => {},
    onSubmit: async (formData: FormValuesProps, helpers: FormikHelpers<FormValuesProps>) => {
      usersService
        .edit(formData)
        .then(() => {
          hideDialog();
        })
        .finally(() => helpers.setSubmitting(false));
    },
    enableReinitialize: true,
  });

  const userDialogFooter = (
    <>
      <Button
        type="reset"
        label={t('common.cancel')}
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <WriteOnlyButton
        type="submit"
        label={t('common.save')}
        icon="pi pi-check"
        className="p-button-text"
        onClick={formik.submitForm}
      />
    </>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik}>
      <Dialog
        visible={dialogVisible}
        header={t('users.user_details')}
        modal
        className="p-fluid"
        footer={userDialogFooter}
        onHide={hideDialog}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
        style={{ width: '40vw' }}
      >
        <form>
          <div className="col-10">
            <div className="field">
              <label htmlFor="email">{t('users.email')}</label>
              <InputText id="email" value={formik.values.email} readOnly disabled />
              <FormErrorMessage fieldName="email" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="firstName">{t('users.first_name')}</label>
              <InputText id="firstName" value={formik.values.firstName} onChange={formik.handleChange} />
            </div>

            <div className="field">
              <label htmlFor="lastName">{t('users.last_name')}</label>
              <InputText id="lastName" value={formik.values.lastName} onChange={formik.handleChange} />
            </div>
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
}

export default UserDialog;
