import { useState, useEffect, useRef } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { parseISO, format, toDate } from 'date-fns';
import { Trans, useTranslation } from 'react-i18next';
import getPaginatorTemplate from '../_shared/getPaginatorTemplate';
import { handleDateFilters, handleSort } from '../_shared/functions';
import { PaginationParams, PaginatorState } from '../../types/general-types';
import weighingsService from '../../service/api/WeighingsService';
import { WeighingsListToolbar } from './Toolbar';
import { WeighingListProps } from './types';
import { Panel } from 'primereact/panel';
import { SETTINGS } from '../../enums/settings.enum';

import generalStyle from '../../assets/custom-styles/general.module.scss';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import WriteOnlyButton from '../controls/WriteOnlyButton';

const INIT_PAGINATION_PARAMETERS = {
  order: {
    isAscending: false,
    orderColumn: 'date',
  },
  page: {
    index: 1,
    size: 10,
  },
};

const INIT_PAGINATOR_STATE = {
  currentPage: 1,
  totalPages: 1,
  rows: 10,
};

export const WeighingsList = (props: WeighingListProps) => {
  const { scales } = props;

  const { t } = useTranslation();
  const toast = useRef<any>();

  const noTagString = t('common.no_tag');

  const [allTags, setAllTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [weighings, setWeighings] = useState<any>([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [paginatorState, setPaginatorState] = useState<PaginatorState>(INIT_PAGINATOR_STATE);
  const [paginationParams, setPaginationParams] = useState<PaginationParams>(INIT_PAGINATION_PARAMETERS);

  const [selectedScales, setSelectedScales] = useState<any>([]);
  const [selectedIds, setSelectedIds] = useState<any>([]);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [scaleIndicationToDelete, setScaleIndicationToDelete] = useState<any>();
  const [customColumns, setCustomColumns] = useState<string[]>([]);
  const [alibiColumnVisible, setAlibiColumnVisible] = useState<boolean>(false);

  const showDeleteScaleIndicationToast = (scaleIndicationId: string) => {
    toast.current.show({
      severity: 'success',
      summary: `${t('weighings_list.indication')}: ${scaleIndicationId}`,
      detail: t('weighings_list.delete_info'),
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  const showToast = (summary: string, detail: string, severity: 'success' | 'info' | 'warn' | 'error') => {
    toast.current.show({
      severity: severity,
      summary: summary,
      detail: detail,
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  const fetchData = () => {
    const dateFilters = handleDateFilters(startDate, endDate);

    const noTag = selectedTags.includes(noTagString);

    weighingsService
      .searchWeighings(paginationParams, selectedIds, selectedTags, noTag, dateFilters)
      .then((weighings) => {
        let parsedWeighings = [];

        let showAlibiColumn = false;

        for (const weighing of weighings.data) {
          parsedWeighings.push({
            id: weighing.id,
            scaleId: weighing.scaleId,
            scaleName: weighing.scaleName,
            tag: weighing.tag,
            date: format(parseISO(weighing.measurementTimeLocal), SETTINGS.FULL_DATE_FORMAT),
            value: `${weighing.value} ${weighing.weighingUnit.toLowerCase()}`,
            metadata: weighing.metadata,
            alibi: weighing.alibi,
          });

          showAlibiColumn = showAlibiColumn || !!weighing.alibi;
        }

        setWeighings(parsedWeighings);
        setPaginatorState({
          ...paginatorState,
          totalPages: weighings.totalPageNumber,
          currentPage: paginatorState.currentPage > weighings.totalPageNumber ? 1 : paginatorState.currentPage,
        });

        setAlibiColumnVisible(showAlibiColumn);
      });
  };

  useEffect(() => {
    const customKeys = new Set<string>(weighings.flatMap((w) => Object.keys(w.metadata ?? {})));
    setCustomColumns(Array.from(customKeys));
  }, [weighings]);

  useEffect(() => {
    const mappedIds = selectedScales.map((element: any) => element.id);
    setSelectedIds(mappedIds);
  }, [selectedScales]);

  useEffect(() => {
    if (
      paginatorState.currentPage !== paginationParams.page.index ||
      paginatorState.rows !== paginationParams.page.size
    ) {
      const params = {
        ...paginationParams,
        page: {
          index: paginatorState.currentPage,
          size: paginatorState.rows,
        },
      };

      setPaginationParams(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationParams, paginatorState]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, paginationParams, selectedIds, selectedTags]);

  useEffect(() => {
    weighingsService.getTags().then((tags) => {
      tags.push(noTagString);

      setAllTags(tags);
    });
  }, [weighings, noTagString]);

  const panelTemplate = (options: any) => {
    const className = `${options.className} justify-content-start`;
    const titleClassName = `${options.titleClassName} mr-2`;

    return (
      <div className={className}>
        <span className={titleClassName}>{options.props.header}</span>
      </div>
    );
  };

  const dateTemplate = (rowData: any): JSX.Element => (
    <>{format(toDate(parseISO(rowData.date)), t('formats.full_format'))}</>
  );

  const actionsTemplate = (rowData: any) => {
    return (
      <span>
        <WriteOnlyButton
          type="button"
          icon="pi pi-print"
          className="p-button-success mr-2"
          tooltip={t('common.print')}
          tooltipOptions={{ position: 'top' }}
          onClick={() => {
            print(rowData);
          }}
        />
        <WriteOnlyButton
          type="button"
          icon="pi pi-trash"
          className="p-button-danger"
          tooltip={t('common.delete')}
          tooltipOptions={{ position: 'top' }}
          onClick={() => {
            confirmDeleteScaleIndication(rowData);
          }}
        />
      </span>
    );
  };

  const print = async (scaleIndicationData: any) => {
    try {
      await weighingsService.printScaleIndication(scaleIndicationData.id);
      showToast('', t('weighings_list.print'), 'success');
    } catch (error: any) {
      if (error.response.status === 409) {
        const details = t(`printers.errors.${error.response.data.error}`);
        showToast(t('printers.error'), details, 'error');
      }
      if (error.response.status === 404) {
        const details = t(`printers.errors.NOT_FOUND`);
        showToast(t('printers.error'), details, 'error');
      }
    }
  };

  const confirmDeleteScaleIndication = (scaleIndicationData: any) => {
    setScaleIndicationToDelete(scaleIndicationData);
    setDeleteDialogVisible(true);
  };

  const hideDeleteDialog = () => {
    setDeleteDialogVisible(false);
  };

  const deleteScaleIndication = async () => {
    await weighingsService.deleteScaleIndication(scaleIndicationToDelete.id);
    setDeleteDialogVisible(false);
    showDeleteScaleIndicationToast(scaleIndicationToDelete.id);
    fetchData();
  };

  const deleteDialogFooter = (
    <>
      <Button label={t('common.no')} icon="pi pi-times" className="p-button-text" onClick={() => hideDeleteDialog()} />
      <Button
        label={t('common.yes')}
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => deleteScaleIndication()}
      />
    </>
  );

  return (
    <>
      <Panel header={t('weighings_list.header')} className={generalStyle.panelTitle} headerTemplate={panelTemplate}>
        <WeighingsListToolbar
          t={t}
          scales={scales}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          selectedScales={selectedScales}
          setSelectedScales={setSelectedScales}
          paginatorState={paginatorState}
          setPaginatorState={setPaginatorState}
          paginationParams={paginationParams}
          measurements={weighings}
          allTags={allTags}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          selectedIds={selectedIds}
        />

        <DataTable
          value={weighings}
          responsiveLayout="stack"
          breakpoint="768px"
          dataKey="id"
          paginator
          rows={paginatorState.rows}
          rowHover
          paginatorTemplate={getPaginatorTemplate(paginatorState, null, null, setPaginatorState)}
          emptyMessage={t('weighings_list.empty_message')}
          onSort={(event) =>
            handleSort(event, paginatorState, paginationParams, setPaginatorState, setPaginationParams)
          }
          sortField={paginationParams.order.orderColumn}
          sortOrder={paginationParams.order.isAscending ? 1 : -1}
          removableSort
        >
          <Column style={{ width: '3em' }} />
          <Column field="date" header={t('common.date')} sortable body={dateTemplate} />
          <Column field="scaleName" header={t('weighings_list.scale')} />
          <Column field="tag" header={t('common.tag')} />
          <Column field="value" header={t('weighings_list.indication')} />
          {alibiColumnVisible && <Column field="alibi" header={t('weighings_list.alibi')} />}
          {customColumns.map((c) => {
            return <Column key={c} field={`metadata.${c}`} header={c} />;
          })}
          <Column headerStyle={{ width: '8rem' }} header={t('common.actions')} align="center" body={actionsTemplate} />
        </DataTable>

        <Dialog
          visible={deleteDialogVisible}
          className="p-fluid"
          header={
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
              {t('common.confirm')}
            </span>
          }
          modal
          footer={deleteDialogFooter}
          onHide={hideDeleteDialog}
          breakpoints={{ '896px': '90vw' }}
          style={{ minWidth: '450px' }}
        >
          <div className="confirmation-content">
            {scaleIndicationToDelete && (
              <span>
                <Trans
                  i18nKey="weighings_list.confirm_delete_message"
                  values={{ date: scaleIndicationToDelete.date }}
                  components={[<strong />]}
                />
              </span>
            )}
          </div>
        </Dialog>
      </Panel>

      <Toast ref={toast} />
    </>
  );
};
