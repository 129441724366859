import { useEffect, useState } from 'react';

import versionService from '../../../service/api/VersionService';
import { modeContext } from '../../../services/ModeContext';
export function useVersion() {
  const modeCtx = modeContext();
  const [version, setVersion] = useState('');

  const getVersion = async () => {
    const version = await versionService.getVersion();
    setVersion(version);
  };

  useEffect(() => {
    if (modeCtx.isOffline) {
      getVersion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return version;
}
