import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';

import { Button } from 'primereact/button';
import FormErrorMessage from '../../../_shared/FormErrorMessage';
import { GetChangeOfStateConfigForm } from './validation';
import { Dropdown } from 'primereact/dropdown';
import { COS_INTERVAL_UNITS } from '../../../../enums/auto-weighings.enum';
import { ChangeOfStateConfigDialogParams } from './types';
import { InputSwitch } from 'primereact/inputswitch';
import WriteOnlyButton from '../../../controls/WriteOnlyButton';

export const ChangeOfStateConfigDialog = (props: ChangeOfStateConfigDialogParams) => {
  const {
    t,
    stateConfigDialog,
    setStateConfigDialog,
    initialStateConfigValues,
    scaleId,
    header,
    showSaveToast,
    weighingUnit,
  } = props;

  const onSuccess = (): void => setStateConfigDialog(false);
  const hideDialog = () => onSuccess();

  const stateForm = GetChangeOfStateConfigForm(initialStateConfigValues, onSuccess, scaleId, t, showSaveToast, header);

  const dropdownValues = [
    { name: t('auto_weighings.minute'), value: COS_INTERVAL_UNITS.MINUTE },
    { name: t('auto_weighings.two_minutes'), value: COS_INTERVAL_UNITS.TWO_MINUTES },
    {
      name: t('auto_weighings.five_minutes'),
      value: COS_INTERVAL_UNITS.FIVE_MINUTES,
    },
    { name: t('auto_weighings.ten_minutes'), value: COS_INTERVAL_UNITS.TEN_MINUTES },
  ];

  const stateDialogFooter = () => {
    return (
      <>
        <Button
          type="reset"
          label={t('common.cancel')}
          icon="pi pi-times"
          className="p-button-text"
          onClick={onSuccess}
        />
        <WriteOnlyButton
          type="submit"
          label={t('common.save')}
          icon="pi pi-check"
          className="p-button-text"
          onClick={stateForm.submitForm}
        />
      </>
    );
  };

  return (
    <Dialog
      visible={stateConfigDialog}
      style={{ width: '450px' }}
      header={header}
      modal
      className="p-fluid"
      footer={stateDialogFooter}
      onHide={hideDialog}
    >
      <div className="field">
        <label htmlFor="time-interval-in-minutes">{t('auto_weighings.time_interval')}</label>

        <Dropdown
          id="timeIntervalInMinutes"
          value={stateForm.values.timeIntervalInMinutes}
          onChange={stateForm.handleChange}
          options={dropdownValues}
          optionLabel="name"
          placeholder={t('auto_weighings.select_time_interval')}
          className="mr-2 mb-2 mt-2"
        />

        <FormErrorMessage fieldName="timeIntervalInMinutes" formikInstance={stateForm} />
      </div>

      <div className="field">
        <label htmlFor="min-indication-change">{`${t(
          'auto_weighings.min_indication_change'
        )} (${weighingUnit})`}</label>
        <InputNumber
          id="minIndicationChange"
          value={stateForm.values.minIndicationChange}
          onValueChange={stateForm.handleChange}
          integeronly
          required
          autoFocus
          min={1}
        />
        <FormErrorMessage fieldName="minIndicationChange" formikInstance={stateForm} />
      </div>

      <div className="field">
        <label htmlFor="max-acceptable-deviation">{`${t(
          'auto_weighings.max_acceptable_deviation'
        )} (${weighingUnit})`}</label>
        <InputNumber
          id="maxAcceptableDeviation"
          value={stateForm.values.maxAcceptableDeviation}
          onValueChange={stateForm.handleChange}
          integeronly
          required
          autoFocus
          min={1}
        />
        <FormErrorMessage fieldName="maxAcceptableDeviation" formikInstance={stateForm} />
      </div>

      <div className="field">
        <label htmlFor="is-enabled">{t('auto_weighings.config_enabled')}</label>
        <br />
        <InputSwitch id="isEnabled" checked={stateForm.values.isEnabled} onChange={stateForm.handleChange} />
      </div>
    </Dialog>
  );
};
