import { InputText } from 'primereact/inputtext';
import { useTranslation } from 'react-i18next';
import { Animal } from '../../types/Animal';

type Props = {
  animal: Animal;
};

function AnimalDetailsSection({ animal }: Props) {
  const { t } = useTranslation();

  return (
    <>
      <div className="card">
        <h5>{t('animals.animal_details')}</h5>
        <div className="grid">
          <div className="field col-6">
            <label htmlFor="name">{t('common.name')}</label>
            <InputText id="name" value={animal.name} disabled={true} className=" w-full" />
          </div>

          <div className="field col-6">
            <label htmlFor="description">{t('animals.description')}</label>
            <InputText id="description" value={animal.description} disabled={true} className=" w-full" />
          </div>

          <div className="field col-6">
            <label htmlFor="rfid">{t('animals.rfid')}</label>
            <InputText id="rfid" value={animal.rfid} disabled={true} className=" w-full" />
          </div>
        </div>
      </div>
    </>
  );
}

export default AnimalDetailsSection;
