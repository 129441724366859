import { useEffect, useState, useRef } from 'react';

import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from 'primereact/button';
import { ChangeOfStateConfigDialog } from './ChangeOfStateConfigDialog';
import { DataView } from 'primereact/dataview';
import { CHANGE_OF_STATE_DIRECTION } from '../../../../enums/change-of-states-config.enums';
import changeOfStateConfigsService from '../../../../service/api/ChangeOfStateConfigsService';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { SETTINGS } from '../../../../enums/settings.enum';
import { WEIGHING_UNITS } from '../../../../enums/weighing-units.enum';

const layout = 'list';

type Props = {
  weighingUnit: WEIGHING_UNITS;
};

export const ChangeOfStateConfig = (props: Props) => {
  const { weighingUnit } = props;
  const { t } = useTranslation();
  const toast = useRef<any>();

  const [positiveStateDialog, setPositiveStateDialog] = useState(false);
  const [negativeStateDialog, setNegativeStateDialog] = useState<any>(false);

  const [positiveStateConfigValues, setPositiveStateConfigValues] = useState<any>({});
  const [negativeStateConfigValues, setNegativeStateConfigValues] = useState<any>({});

  const positiveStateConfigName = t('auto_weighings.positive_state');
  const negativeStateConfigName = t('auto_weighings.negative_state');

  const { scaleId } = useParams<any>();

  const showSaveToast = (stateName: string) => {
    toast.current.show({
      severity: 'success',
      summary: `${stateName}`,
      detail: t('changes_of_states.toast_saved'),
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  useEffect(() => {
    changeOfStateConfigsService.getConfigs(scaleId).then((configs) => {
      for (let config of configs) {
        const stateConfig = {
          id: config.id,
          scaleId: config.scaleId,
          timeIntervalInMinutes: config.timeIntervalInMinutes,
          minIndicationChange: config.minIndicationChange,
          maxAcceptableDeviation: config.maxAcceptableDeviation,
          direction: config.direction,
          isEnabled: config.isEnabled,
        };

        stateConfig.direction === CHANGE_OF_STATE_DIRECTION.POSITIVE
          ? setPositiveStateConfigValues(stateConfig)
          : setNegativeStateConfigValues(stateConfig);
      }
    });
  }, [scaleId, positiveStateDialog, negativeStateDialog]);

  const editButton = (stateName: string): JSX.Element => {
    switch (stateName) {
      case t('auto_weighings.positive_state'):
        setPositiveStateDialog(true);
        break;

      case t('auto_weighings.negative_state'):
        setNegativeStateDialog(true);
        break;

      default:
        return null;
    }

    return null;
  };

  const dataViewListItem = (state: any): JSX.Element | any => {
    let stateName =
      state.direction === CHANGE_OF_STATE_DIRECTION.POSITIVE ? positiveStateConfigName : negativeStateConfigName;

    return (
      <div key={state?.id} className="col-12">
        <div className="flex flex-column md:flex-row align-items-center p-3 w-full">
          <div className="flex-1 text-center md:text-left">
            <div className="font-bold text-left md:text-left mt-1">
              <h5>{stateName}</h5>
            </div>
          </div>

          {state.isEnabled ? (
            <Tag
              className="p-button-raised p-button-success mr-2 mb-2 pr-4 pl-4"
              value={t('changes_of_states.turn_on')}
              severity="success"
              style={{
                width: '100px',
                height: '34px',
                fontSize: '14px',
              }}
            />
          ) : (
            <Tag
              className="p-button-raised p-button-warning mr-2 mb-2 pl-4 pr-4"
              value={t('changes_of_states.turn_off')}
              severity="warning"
              style={{
                width: '100px',
                height: '34px',
                fontSize: '14px',
              }}
            />
          )}

          <Button
            id="button"
            onClick={() => editButton(stateName)}
            label={t('common.edit')}
            iconPos="right"
            className="p-button-raised p-button-primary pr-4 pl-4 mr-2 mb-2"
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <DataView
        value={[positiveStateConfigValues, negativeStateConfigValues]}
        layout={layout}
        itemTemplate={dataViewListItem}
        emptyMessage={''}
      />

      <ChangeOfStateConfigDialog
        t={t}
        stateConfigDialog={positiveStateDialog}
        setStateConfigDialog={setPositiveStateDialog}
        initialStateConfigValues={positiveStateConfigValues}
        scaleId={scaleId}
        header={t('auto_weighings.positive_state')}
        showSaveToast={showSaveToast}
        weighingUnit={weighingUnit}
      />

      <ChangeOfStateConfigDialog
        t={t}
        stateConfigDialog={negativeStateDialog}
        setStateConfigDialog={setNegativeStateDialog}
        initialStateConfigValues={negativeStateConfigValues}
        scaleId={scaleId}
        header={t('auto_weighings.negative_state')}
        showSaveToast={showSaveToast}
        weighingUnit={weighingUnit}
      />

      <Toast ref={toast} />
    </>
  );
};
