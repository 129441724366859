import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import AppWrapper from './AppWrapper';
//import * as serviceWorker from './serviceWorker';
import './i18n/config';
import { Provider } from 'react-redux';
import store from './store';
import { AuthContextProvider } from './store/auth-context';
import { WebSockerConfig } from './components/WebSockerConfig';

ReactDOM.render(
  <Provider store={store}>
    <HashRouter>
      <AuthContextProvider>
        <WebSockerConfig>
          <AppWrapper />
        </WebSockerConfig>
      </AuthContextProvider>
    </HashRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
