import { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';

import { DataView } from 'primereact/dataview';
import { InputText } from 'primereact/inputtext';

import { AutoWeighingsToolbar } from './AutoWeighingsToolbar';
import { Calendar } from 'primereact/calendar';
import { isValid, parseISO } from 'date-fns';
import { Dropdown } from 'primereact/dropdown';
import { INTERVAL_UNITS } from '../../../enums/auto-weighings.enum';
import autoWeighingConfigsService from '../../../service/api/AutoWeighingConfigsService';
import { useTranslation } from 'react-i18next';
import { Prompt, useParams } from 'react-router-dom';
import { AutoWeighingConfig, AutoWeighingsParams } from './types';
import { Panel } from 'primereact/panel';

import generalStyle from '../../../assets/custom-styles/general.module.scss';
import { panelTemplate } from '../../_shared/panel-template';

import useConfirmTabClose from '../../_shared/hooks/useConfirmTabClose';
import { InputNumber } from 'primereact/inputnumber';
import { Toast } from 'primereact/toast';
import { SETTINGS } from '../../../enums/settings.enum';
import { deepCopy } from '../../_shared/objectHelpers';

const layout = 'list';

export const AutoWeighings = (props: AutoWeighingsParams) => {
  const toast = useRef<any>();

  const { scaleName } = props;
  const { scaleId } = useParams<any>();
  const { t } = useTranslation();
  const [allConfigs, setAllConfigs] = useState([]);
  const [initialConfigs, setInitialConfigs] = useState([]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  useConfirmTabClose(unsavedChanges, t);

  const showSaveToast = () => {
    toast.current.show({
      severity: 'success',
      summary: t('auto_weighings.auto_weighings_info'),
      detail: t('auto_weighings.toast_saved'),
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  const dropdownValues = [
    { label: t('auto_weighings.second'), value: INTERVAL_UNITS.SECOND },
    { label: t('auto_weighings.minute'), value: INTERVAL_UNITS.MINUTE },
    { label: t('auto_weighings.hour'), value: INTERVAL_UNITS.HOUR },
    { label: t('auto_weighings.day'), value: INTERVAL_UNITS.DAY },
    { label: t('auto_weighings.month'), value: INTERVAL_UNITS.MONTH },
  ];

  const secondsDropdownValues = [
    { label: 5, value: 5 },
    { label: 10, value: 10 },
    { label: 15, value: 15 },
    { label: 20, value: 20 },
    { label: 25, value: 25 },
    { label: 30, value: 30 },
    { label: 35, value: 35 },
    { label: 40, value: 40 },
    { label: 45, value: 45 },
    { label: 50, value: 50 },
    { label: 55, value: 55 },
  ];

  useEffect(() => {
    autoWeighingConfigsService.getAllConfigs(scaleId).then((configs) => {
      let parsedData: any = [];

      configs.forEach((element: any, index: any) => {
        parsedData.push({ ...element, index: index + 1 });
      });

      setAllConfigs(parsedData);
      setInitialConfigs(deepCopy(parsedData));
    });
  }, [scaleId]);

  const updateElement = (e: any, config: AutoWeighingConfig, property: string) => {
    let outputArray: any = [];

    allConfigs.forEach((element: any) => {
      if (element.id === config.id) {
        element[property] = e.target.value;
        outputArray.push(element);
      } else {
        outputArray.push(element);
      }
    });

    setAllConfigs(outputArray);
    setUnsavedChanges(true);
  };

  const updateIsActivated = (config: AutoWeighingConfig) => {
    let outputArray: any = [];

    allConfigs.forEach((element: any) => {
      if (element.id === config.id) {
        element.isActivated = !element.isActivated;

        outputArray.push(element);
      } else {
        outputArray.push(element);
      }
    });

    setAllConfigs(outputArray);
    setUnsavedChanges(true);
  };

  const isConfigInvalid = (config: AutoWeighingConfig) => {
    if (!config.intervalUnit || !config.startDate) return true;

    return false;
  };

  const deleteConfig = (configToDelete: AutoWeighingConfig) => {
    const configs = allConfigs.filter((config) => config.id !== configToDelete.id);

    setAllConfigs(configs);
    setUnsavedChanges(true);
  };

  const dataViewListItem = (config: any): JSX.Element | any => {
    return (
      <div key={config?.id} className="col-12">
        <div className="flex flex-column md:flex-row align-items-center p-3 w-full">
          <div className="my-4 md:my-0 w-9 md:h-2.5rem md:w-1rem pt-2 mr-4">
            <div className="font-bold   text-center md:text-center mt-1">
              <h5>{config.index}</h5>
            </div>
          </div>
          <Dropdown
            value={config.intervalUnit}
            onChange={(e) => updateElement(e, config, 'intervalUnit')}
            options={dropdownValues}
            optionLabel="label"
            placeholder={t('auto_weighings.interval_unit')}
            className="mr-2 mb-2 mt-2"
            style={{ width: '130px' }}
          />

          {config.intervalUnit !== INTERVAL_UNITS.SECOND ? (
            <InputNumber
              id="intervalUnitValue"
              value={config.intervalUnitValue}
              onValueChange={(e) => updateElement(e, config, 'intervalUnitValue')}
              min={1}
              max={3600}
              maxFractionDigits={0}
              className="mr-2 mb-2 mt-2"
              placeholder={t('auto_weighings.interval_unit_value')}
              inputStyle={{ width: '130px' }}
            />
          ) : (
            <Dropdown
              value={config.intervalUnitValue}
              onChange={(e) => updateElement(e, config, 'intervalUnitValue')}
              options={secondsDropdownValues}
              optionLabel="label"
              placeholder={t('auto_weighings.interval_unit_value')}
              className="mr-2 mb-2 mt-2"
              style={{ width: '130px' }}
            />
          )}

          <Calendar
            placeholder={t('auto_weighings.start_from')}
            locale="pl"
            inputId="startDate"
            value={isValid(parseISO(config.startDate)) ? parseISO(config.startDate) : config.startDate}
            className="mr-2"
            onChange={(element: any) => updateElement(element, config, 'startDate')}
            showTime
            showSeconds
            showIcon
          />

          <div className="flex-1">
            <InputText
              key={config.id}
              type="text"
              placeholder={t('common.tag')}
              className="mr-2 mb-2 mt-2"
              value={config.tag}
              onChange={(e: any) => updateElement(e, config, 'tag')}
            />
          </div>
          <div className="flex flex-row md:flex-row justify-content-between w-full md:w-auto align-items-center md:align-items-end mt-5 md:mt-0">
            <Button
              id="button"
              onClick={() => updateIsActivated(config)}
              disabled={isConfigInvalid(config)}
              label={config.isActivated ? t('auto_weighings.turn_off') : t('auto_weighings.turn_on')}
              iconPos="right"
              className={
                config.isActivated
                  ? 'p-button-raised p-button-success mr-2 mb-2 pl-4 pr-4'
                  : 'p-button-raised p-button-warning mr-2 mb-2 pr-4 pl-4'
              }
              style={{ width: '100px' }}
            />

            <Button
              id="button"
              onClick={() => deleteConfig(config)}
              label={t('common.delete')}
              iconPos="right"
              className="p-button-raised p-button-danger pr-4 pl-4 mr-2 mb-2"
            />
          </div>
        </div>
      </div>
    );
  };

  const hasUnsavedChanges = () => {
    return JSON.stringify(allConfigs) !== JSON.stringify(initialConfigs);
  };

  return (
    <div>
      <div className="grid list-demo">
        <div className="col-12">
          <Panel
            header={`${t('auto_weighings.header')} - ${scaleName}`}
            className={generalStyle.panelTitle}
            headerTemplate={(e) => panelTemplate(e)}
          >
            <AutoWeighingsToolbar
              t={t}
              scaleId={scaleId}
              allConfigs={allConfigs}
              setAllConfigs={setAllConfigs}
              setInitialConfigs={setInitialConfigs}
              setUnsavedChanges={setUnsavedChanges}
              showSaveToast={showSaveToast}
            />

            <div className="card">
              <DataView
                value={allConfigs}
                layout={layout}
                itemTemplate={dataViewListItem}
                emptyMessage={t('auto_weighings.no_data')}
              ></DataView>

              <Prompt when={hasUnsavedChanges()} message={t('auto_weighings.unsaved_changes')} />
            </div>
          </Panel>
        </div>

        <Toast ref={toast} />
      </div>
    </div>
  );
};
