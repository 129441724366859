import axios from 'axios';

const PATH = `auto-weighing-configs`;

class AutoWeighingsConfigService {
  async getAllConfigs(scaleId: string) {
    const result = await axios.get(`/scales/${scaleId}/${PATH}`);

    return result.data;
  }

  async updateConfig(scaleId: string, configs: any) {
    return axios.post(`/scales/${scaleId}/${PATH}`, configs);
  }
}

let autoWeighingConfigsService = new AutoWeighingsConfigService();
export default autoWeighingConfigsService;
