import axios from 'axios';
import { PaginationParams, DateFilters } from '../../types/general-types';

class ChangesOfStatesService {
  async getAll(id: string, paginationParams: PaginationParams, dateFilters: DateFilters | null) {
    let dataToSend: any = {
      paginationParams,
    };

    if (dateFilters) dataToSend.dateFilters = dateFilters;

    const result = await axios.post(`/scales/${id}/changes-of-states`, dataToSend);

    return result.data;
  }

  async getReport(id: string, fileFormat: string, isAscending: boolean, dateFilters: DateFilters | null) {
    let dataToSend: any = {
      fileFormat,
      isAscending,
    };

    if (dateFilters) dataToSend.dateFilters = dateFilters;

    return axios.post(`/scales/${id}/report`, dataToSend, {
      responseType: 'arraybuffer',
    });
  }
}

let changesOfStatesService = new ChangesOfStatesService();
export default changesOfStatesService;
