import { PasswordRecoveryDialog } from './PasswordRecoveryDialog';
import { PasswordRecoveryInitialValues, PasswordRecoveryProps } from './types';

const initialValues: PasswordRecoveryInitialValues = {
  email: '',
};

export const PasswordRecoveryPanel = (props: PasswordRecoveryProps) => {
  const { t, isVisible, dialogHeader, onSuccess, onHide } = props;

  return (
    <>
      <PasswordRecoveryDialog
        t={t}
        initialValues={initialValues}
        isVisible={isVisible}
        dialogHeader={dialogHeader}
        onSuccess={onSuccess}
        onHide={onHide}
      />
    </>
  );
};
