import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import groupTemplatesService from '../../service/api/GroupTemplatesService';
import { Dialog } from 'primereact/dialog';
import GroupTemplateDialog from './GroupTemplateDialog';
import { Trans, useTranslation } from 'react-i18next';
import { GroupTemplate } from '../../types/GroupTemplate';

import { Toast } from 'primereact/toast';
import { SETTINGS } from '../../enums/settings.enum';
import { Tag } from 'primereact/tag';
import WriteOnlyButton from '../controls/WriteOnlyButton';
import GroupTemplateGenerateDialog from './GroupTemplateGenerateDialog';

export const GroupTemplateManagement = () => {
  const toast = useRef<any>();

  const [globalFilter, setGlobalFilter] = useState('');
  const [loading, setLoading] = useState(true);

  const [groupTemplates, setGroupTemplates] = useState<GroupTemplate[]>(null);
  const [groupTemplateToDelete, setGroupTemplateToDelete] = useState<GroupTemplate>();

  const [selectedRowToEdit, setSelectedRowToEdit] = useState<GroupTemplate>(null);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [addEditDialogVisible, setAddEditDialogVisible] = useState(false);
  const [generateDialogVisible, setGenerateDialogVisible] = useState(false);
  const { t } = useTranslation();

  const showSaveToast = (summary: string) => {
    toast.current.show({
      severity: 'success',
      summary: summary,
      detail: t('groupTemplates.toast_saved'),
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  const showAddToast = (summary?: string) => {
    toast.current.show({
      severity: 'success',
      summary: summary,
      detail: t('groupTemplates.toast_added'),
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  const showDeleteToast = (summary: string) => {
    toast.current.show({
      severity: 'info',
      summary: summary,
      detail: t('groupTemplates.toast_deleted'),
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  const loadData = () => {
    setLoading(true);
    Promise.all([groupTemplatesService.getAll()]).then((response) => {
      const _groupTemplates = response[0];

      setGroupTemplates(_groupTemplates);
      setLoading(false);
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const groupTemplatesTableHeader = (
    <div className="table-header">
      {t('groupTemplates.header')}
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder={t('common.search')}
        />
        <Button
          type="button"
          icon="pi pi-plus"
          label={t('common.add')}
          className="p-button ml-2"
          onClick={() => {
            addGroupTemplate();
          }}
        ></Button>
        <Button
          type="button"
          icon="pi pi-plus"
          label={t('groupTemplates.generate')}
          className="p-button ml-2"
          onClick={() => {
            generateGroupTemplate();
          }}
        ></Button>
      </span>
    </div>
  );

  const closeAddEditModal = () => {
    setAddEditDialogVisible(false);
    loadData();
  };

  const closeGenerateModal = () => {
    setGenerateDialogVisible(false);
    loadData();
  };

  const confirmDeleteGroupTemplate = (groupTemplate: GroupTemplate) => {
    setGroupTemplateToDelete(groupTemplate);
    setDeleteDialogVisible(true);
  };

  const deleteGroupTemplate = () => {
    setLoading(true);
    groupTemplatesService.delete(groupTemplateToDelete.id).then(() => {
      loadData();

      showDeleteToast(groupTemplateToDelete.name);
    });

    setDeleteDialogVisible(false);
  };
  const hideDeleteDialog = () => {
    setDeleteDialogVisible(false);
  };

  const editGroupTemplate = (_groupTemplate: GroupTemplate) => {
    setSelectedRowToEdit(_groupTemplate);
    setAddEditDialogVisible(true);
  };

  const addGroupTemplate = () => {
    setSelectedRowToEdit(null);
    setAddEditDialogVisible(true);
  };

  const generateGroupTemplate = () => {
    setSelectedRowToEdit(null);
    setGenerateDialogVisible(true);
  };

  const deleteDialogFooter = (
    <>
      <Button label={t('common.no')} icon="pi pi-times" className="p-button-text" onClick={() => hideDeleteDialog()} />
      <Button
        label={t('common.yes')}
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => deleteGroupTemplate()}
      />
    </>
  );

  const boolColumnTemplate = (data: GroupTemplate, props: ColumnBodyOptions, trueValue: string, falseValue: string) => {
    return (
      <React.Fragment>
        {data[props.field] === true ? (
          <Tag className="mr-2" icon="pi pi-check" severity="success" value={trueValue}></Tag>
        ) : (
          <Tag className="mr-2" icon="pi pi-times-circle" severity="danger" value={falseValue}></Tag>
        )}
      </React.Fragment>
    );
  };

  const bodyTemplate = (data: GroupTemplate, props: ColumnBodyOptions) => {
    return <>{data[props.field]}</>;
  };

  const actionTemplate = (data: GroupTemplate) => (
    <span>
      <Button
        type="button"
        icon={data.isUsed ? 'pi pi-eye' : 'pi pi-pencil'}
        tooltip={data.isUsed ? t('common.preview') : t('common.edit')}
        tooltipOptions={{ position: 'top' }}
        className="p-button-success mr-2"
        onClick={() => {
          editGroupTemplate(data);
        }}
      ></Button>
      <WriteOnlyButton
        type="button"
        disabled={data.isUsed}
        icon="pi pi-trash"
        tooltip={t('common.delete')}
        tooltipOptions={{ position: 'top' }}
        className="p-button-success"
        onClick={() => {
          confirmDeleteGroupTemplate(data);
        }}
      />
    </span>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <DataTable
            value={groupTemplates}
            paginator
            className="p-datatable-customers"
            rows={10}
            dataKey="id"
            rowHover
            globalFilter={globalFilter}
            emptyMessage={t('groupTemplates.empty_message')}
            loading={loading}
            header={groupTemplatesTableHeader}
          >
            <Column field="name" header={t('common.name')} sortable body={bodyTemplate}></Column>
            <Column
              field="isUsed"
              header={t('groupTemplates.is_used')}
              sortable
              body={(data, props) => boolColumnTemplate(data, props, t('common.yes'), t('common.no'))}
            ></Column>
            <Column
              headerStyle={{ width: '8rem' }}
              header={t('common.actions')}
              align="center"
              body={actionTemplate}
            ></Column>
          </DataTable>
          <GroupTemplateDialog
            dialogVisible={addEditDialogVisible}
            closeAddEditModal={closeAddEditModal}
            groupTemplate={selectedRowToEdit}
            showAddToast={showAddToast}
            showSaveToast={showSaveToast}
          />
          <GroupTemplateGenerateDialog
            dialogVisible={generateDialogVisible}
            closeModal={closeGenerateModal}
            showAddToast={showAddToast}
          />

          <Dialog
            visible={deleteDialogVisible}
            className="p-fluid"
            header={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                {t('common.confirm')}
              </span>
            }
            modal
            footer={deleteDialogFooter}
            onHide={hideDeleteDialog}
            breakpoints={{ '896px': '90vw' }}
            style={{ minWidth: '450px' }}
          >
            <div className="confirmation-content">
              {groupTemplateToDelete && (
                <span>
                  <Trans
                    i18nKey="common.confirm_delete_message"
                    values={{ name: groupTemplateToDelete.name }}
                    components={[<strong />]}
                  />
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>

      <Toast ref={toast} />
    </div>
  );
};
