import React, { useRef, useState } from 'react';
import { Calendar } from 'primereact/calendar';

import { format, parseISO } from 'date-fns';
import { Toolbar } from 'primereact/toolbar';
import { MultiSelect } from 'primereact/multiselect';
import { handleDateFilters, handleEndDate, handleStartDate } from '../_shared/functions';
import { REPORT_TYPES } from '../../enums/report-types.enum';
import { getFileFromBuffer } from '../_shared/getFile';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useMediaQuery } from 'usehooks-ts';
import weighingsService from '../../service/api/WeighingsService';

import generalStyle from '../../assets/custom-styles/general.module.scss';
import { WeighingListToolbarProps } from './types';
import { SETTINGS } from '../../enums/settings.enum';

export const WeighingsListToolbar = (props: WeighingListToolbarProps) => {
  const {
    t,
    scales,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    selectedScales,
    setSelectedScales,
    paginatorState,
    setPaginatorState,
    paginationParams,
    measurements,
    allTags,
    selectedTags,
    setSelectedTags,
    selectedIds,
  } = props;

  const op = useRef(null);

  const [pdfLoading, setPdfLoading] = useState(false);
  const [excelLoading, setExcelLoading] = useState(false);

  const isTabletView = useMediaQuery(SETTINGS.TABLET_VIEW);
  const isMobileView = useMediaQuery(SETTINGS.MOBILE_VIEW);

  const itemTemplate = (option: any) => {
    return (
      <div className="flex align-items-center">
        <span className={`mr-2`} />
        <span>{option.name}</span>
      </div>
    );
  };

  const tagTemplate = (option: string) => {
    return (
      <div className="flex align-items-center">
        <span className={`mr-2`} />
        <span>{option}</span>
      </div>
    );
  };

  const selectedScaleTemplate = (option: any) => {
    if (option) {
      return (
        <div className="inline-flex align-items-center font-bold border-round mr-2">
          <span>{option.name}</span>
        </div>
      );
    }

    return t('scales_indications.available_scales');
  };

  const selectTagTemplate = (option: string) => {
    if (option) {
      return (
        <div className="inline-flex align-items-center font-bold border-round mr-2">
          <span>{option}</span>
        </div>
      );
    }

    return t('weighings_list.select_tag');
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Calendar
          placeholder={t('common.date_from')}
          locale="pl"
          inputId="startDate"
          value={startDate ? parseISO(startDate) : undefined}
          className={`mr-1 mt-2 col-11 md:col-3`}
          onChange={(element: any) =>
            handleStartDate(element?.value, endDate, setStartDate, setEndDate, paginatorState, setPaginatorState)
          }
          showIcon
        />

        <Calendar
          placeholder={t('common.date_to')}
          locale="pl"
          inputId="endDate"
          value={endDate ? parseISO(endDate) : undefined}
          className={`mr-1 mt-2 col-11 md:col-3`}
          onChange={(element: any) =>
            handleEndDate(element?.value, startDate, setStartDate, setEndDate, paginatorState, setPaginatorState)
          }
          showIcon
        />

        <MultiSelect
          value={selectedScales}
          onChange={(e) => setSelectedScales(e.value)}
          options={scales}
          optionLabel="name"
          placeholder={t('scales_indications.available_scales')}
          filter
          itemTemplate={itemTemplate}
          selectedItemTemplate={selectedScaleTemplate}
          className={`mr-1 mt-2 col-11 md:col-3 pt-1 pb-1`}
          maxSelectedLabels={1}
          selectedItemsLabel={`${t('scales_indications.selected_scales')}: ${selectedScales?.length}`}
        />

        <MultiSelect
          value={selectedTags}
          onChange={(e) => setSelectedTags(e.value)}
          options={allTags}
          disabled={!allTags.length}
          optionLabel="tag"
          placeholder={t('weighings_list.select_tag')}
          filter
          className={`mr-1 mt-2 col-11 md:col-3 pt-1 pb-1`}
          itemTemplate={tagTemplate}
          selectedItemTemplate={selectTagTemplate}
          maxSelectedLabels={1}
          selectedItemsLabel={`${t('weighings_list.selected_tags')}: ${selectedTags?.length}`}
        />
      </React.Fragment>
    );
  };

  const toggle = (event: any) => {
    op.current.toggle(event);
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          type="button"
          label={t('common.export')}
          onClick={toggle}
          className={`col-11  md:col-12 mr-1 mt-2 md:pr-3 md:pl-3`}
          icon="pi pi-download"
          iconPos="right"
          disabled={!measurements.length}
        />
        <OverlayPanel ref={op} appendTo={document.body}>
          <Button
            label="PDF"
            className="p-button-plain p-button-text"
            icon="pi pi-file-pdf"
            onClick={() => {
              downloadReport(REPORT_TYPES.PDF);
              setPdfLoading(true);
            }}
            disabled={excelLoading}
            loading={pdfLoading}
          />
          <br />
          <Button
            label="Excel"
            className="p-button-plain p-button-text export-button-menu-width"
            icon="pi pi-file-excel"
            onClick={() => {
              downloadReport(REPORT_TYPES.EXCEL);
              setExcelLoading(true);
            }}
            disabled={pdfLoading}
            loading={excelLoading}
          />
        </OverlayPanel>
      </React.Fragment>
    );
  };

  const MobileToolbar = () => {
    const LeftToolbar = () => leftToolbarTemplate();
    const RightToolbar = () => rightToolbarTemplate();

    return (
      <React.Fragment>
        <div className={generalStyle.mobileToolbar}>
          <LeftToolbar />
          <RightToolbar />
        </div>
      </React.Fragment>
    );
  };

  const exportReport = (options: any, reportFormat: string) => {
    const dateFilters = handleDateFilters(startDate, endDate);

    weighingsService
      .getReport(selectedIds, selectedTags, options.format, paginationParams.order.isAscending, dateFilters)
      .then((report: any) => {
        reportFormat === REPORT_TYPES.EXCEL ? setExcelLoading(false) : setPdfLoading(false);

        !options.download && getFileFromBuffer(report.data, options.format);
        options.download && getFileFromBuffer(report.data, options.format, prepareFileName());
      });
  };

  const downloadReport = (reportFormat: string) => {
    let report: any = {
      download: true,
    };

    if (reportFormat === REPORT_TYPES.EXCEL) {
      report.format = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      report.extension = '.xlsx';
    } else {
      report.format = 'application/pdf';
      report.extension = '.pdf';
    }

    exportReport(report, reportFormat);
  };

  const prepareFileName = (): string => {
    const formattedDate = format(Date.now(), 'yyyyMMdd_HHmmss');
    return `${t('weighings_list.weighings_list_report')}__${formattedDate}`;
  };

  return isTabletView || isMobileView ? (
    <MobileToolbar />
  ) : (
    <Toolbar className="mb-4 grid" left={leftToolbarTemplate} right={rightToolbarTemplate} />
  );
};
