import { OrderIngredient } from './OrderIngredient';

export enum OrderStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  PAUSED = 'PAUSED',
  CANCELLED = 'CANCELLED',
  FINISHED = 'FINISHED',
}

export interface Order {
  id: string;

  name: string;

  recipeId: string;

  scaleId?: string;

  status?: OrderStatus;

  startDate: string;

  endDate?: string;

  recipeName?: string;

  scaleName?: string;

  ingredients?: OrderIngredient[];
}

export interface CreateOrderRequest {
  name: string;

  recipeId: string;
}
