import { InputText } from 'primereact/inputtext';
import React from 'react';
import { OrderIngredient } from '../../types/OrderIngredient';
import { GroupConnectors } from '../../types/GroupConnectors';

export function OrderIngredientTemplate(
  orderIngredient: OrderIngredient,
  index: number,
  availableConnectors: GroupConnectors[],
  t
) {
  const name = availableConnectors.find((c) => c.id === orderIngredient.groupConnectorId)?.groupTemplateConnectorName;

  return (
    <React.Fragment key={index}>
      <div className="field col-6">
        <label htmlFor={`ingredients[${index}].name`}>{t('orderIngredients.ingredient')}</label>
        <InputText id={`ingredients[${index}].name`} value={name} readOnly={true} disabled={true} />
      </div>

      <div className="field col-3">
        <label htmlFor={`ingredients[${index}].quantity`}>{t('orderIngredients.quantity')}</label>
        <InputText
          id={`ingredients[${index}].quantity`}
          value={
            orderIngredient.actualQuantity === orderIngredient.expectedQuantity
              ? orderIngredient.actualQuantity
              : `${orderIngredient.actualQuantity} / ${orderIngredient.expectedQuantity}`
          }
          readOnly={true}
          disabled={true}
        />
      </div>
    </React.Fragment>
  );
}
