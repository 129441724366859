import { FormikErrors, FormikHelpers, useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { useEffect, useState } from 'react';
import groupTemplatesService from '../../service/api/GroupTemplatesService';
import FormErrorMessage from '../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../_shared/FormErrorMessageScroller';
import { useTranslation } from 'react-i18next';
import { deepCopy } from '../_shared/objectHelpers';
import { InputNumber } from 'primereact/inputnumber';
import { GroupTemplateGenerate } from '../../types/GroupTemplateGenerate';
import WriteOnlyButton from '../controls/WriteOnlyButton';
import { InputText } from 'primereact/inputtext';

interface FormValuesProps extends GroupTemplateGenerate {}

const INIT_FORM_STATE: FormValuesProps = {
  name: '',
  type: null,
  inputs: null,
  outputs: null,
};

type Props = {
  dialogVisible: boolean;
  closeModal: VoidFunction;
  showAddToast: (name?: string) => void;
};

function GroupTemplateGenerateDialog({
  dialogVisible,
  closeModal: closeAddEditModal,

  showAddToast,
}: Props) {
  const { t } = useTranslation();

  const [initFormValues, setInitFormValues] = useState(deepCopy(INIT_FORM_STATE));

  const hideDialog = () => {
    formik.resetForm({ values: deepCopy(INIT_FORM_STATE) });
    closeAddEditModal();
  };

  const templeTypes = [
    { label: t('groupTemplates.type_THR'), value: 'THR' },
    { label: t('groupTemplates.type_dosage'), value: 'DOSAGE' },
  ];

  useEffect(() => {
    setInitFormValues(deepCopy(INIT_FORM_STATE));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogVisible]);

  const formik = useFormik({
    initialValues: initFormValues,
    validate: (data: FormValuesProps) => {
      let errors: FormikErrors<FormValuesProps> = {};

      if (!data.name?.trim()) {
        errors.name = t('common.error_name_required');
      }

      if (!data.type?.trim()) {
        errors.type = t('groupTemplates.template_type_required');
      }

      if (!data.outputs) {
        errors.outputs = t('groupTemplates.error_outputs_required');
      }

      // if (!data.inputs && !data.outputs) {
      //   errors.outputs = t('groupTemplates.error_inputs_or_outputs_required');
      //   errors.outputs = t('groupTemplates.error_inputs_or_outputs_required');
      // }

      return errors;
    },
    onSubmit: async (formData: FormValuesProps, helpers: FormikHelpers<FormValuesProps>) => {
      try {
        await groupTemplatesService.generate(formData);
        showAddToast();

        hideDialog();
      } catch (error: any) {
        if (error.response.status === 409) {
          const field = error.response.data.field || 'name';
          helpers.setFieldError(field, t(`groupTemplates.error_${field}_already_taken`));
        }
      }

      helpers.setSubmitting(false);
    },
    enableReinitialize: true,
  });

  const DialogFooter = (
    <>
      <Button
        type="reset"
        label={t('common.cancel')}
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <WriteOnlyButton
        type="submit"
        label={t('common.save')}
        icon="pi pi-check"
        className="p-button-text"
        onClick={formik.submitForm}
      />
    </>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik}>
      <Dialog
        visible={dialogVisible}
        header={t('groupTemplates.group_template_params')}
        modal
        className="p-fluid"
        footer={DialogFooter}
        onHide={hideDialog}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
        style={{ width: '60vw' }}
      >
        <form>
          <div className="col-10">
            <div className="field">
              <label htmlFor="name">{t('common.name')}</label>
              <InputText
                id="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                className={formik.touched.name && formik.errors.name && 'p-invalid'}
              />
              <FormErrorMessage fieldName="name" formikInstance={formik} />
            </div>
            <div className="field">
              <label htmlFor="type">{t('common.type')}</label>
              <Dropdown
                id="type"
                value={formik.values.type}
                onChange={formik.handleChange}
                options={templeTypes}
                className={formik.touched?.type && formik.errors?.type && 'p-invalid'}
              />
              <FormErrorMessage fieldName="type" formikInstance={formik} />
            </div>
            <div className="field">
              <label htmlFor="outputs">{t('groupTemplates.outputs_count')}</label>
              <InputNumber
                id="outputs"
                value={formik.values.outputs}
                min={0}
                max={100}
                onValueChange={formik.handleChange}
                className={formik.errors?.outputs && formik.touched?.outputs && 'p-invalid'}
              />
              <FormErrorMessage fieldName="outputs" formikInstance={formik} />
            </div>

            {
              //not used for now, uncomment when needed
              /* <div className="field">
              <label htmlFor="inputs">{t('groupTemplates.inputs_count')}</label>
              <InputNumber
                id="inputs"
                value={formik.values.inputs}
                min={0}
                max={100}
                onValueChange={formik.handleChange}
                className={formik.errors?.inputs && formik.touched?.inputs && 'p-invalid'}
              />
              <FormErrorMessage fieldName="inputs" formikInstance={formik} />
            </div> */
            }
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
}

export default GroupTemplateGenerateDialog;
