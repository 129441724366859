import axios from 'axios';
import { Role, RoleDetails } from '../../types/Role';

class RolesService {
  async getAll(): Promise<Role[]> {
    const result = await axios.get('/roles');
    return result.data;
  }
  async getDetails(): Promise<RoleDetails[]> {
    const result = await axios.get('/roles/details');
    return result.data;
  }
  async create(data: RoleDetails): Promise<void> {
    return axios.post(`/roles/`, data);
  }
  async edit(data: RoleDetails, id: string): Promise<void> {
    return axios.put(`/roles/${id}`, data);
  }
  async delete(id: string): Promise<void> {
    return axios.delete(`/roles/${id}`);
  }
}

let rolesService = new RolesService();
export default rolesService;
