import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import rolesService from '../../service/api/RolesService';
import usersService from '../../service/api/UsersService';
import { Tag } from 'primereact/tag';
import UserAdminDialog from './UserAdminDialog';
import { Trans, useTranslation } from 'react-i18next';
import { User } from '../../types/User';
import { Role } from '../../types/Role';
import { RootState } from '../../store';
import { SETTINGS } from '../../enums/settings.enum';
import { Toast } from 'primereact/toast';
import WriteOnlyButton from '../controls/WriteOnlyButton';
import { Dialog } from 'primereact/dialog';

const emptyUser = {
  id: null,
};

export const UserManagement = () => {
  const toast = useRef<any>();
  const [users, setUsers] = useState<User[]>(null);
  const [globalFilter, setGlobalFilter] = useState('');
  const [loading, setLoading] = useState(true);
  const [deleteUserDialogVisible, setDeleteUserDialogVisible] = useState(false);
  const [userToDelete, setUserToDelete] = useState<User>();

  const [roles, setUserRoles] = useState<Role[]>();
  const [selectedRowToEdit, setSelectedRowToEdit] = useState<User>(null);
  const [userDialogVisible, setUserDialogVisible] = useState(false);
  const loggedUserContext = useSelector((state: RootState) => state.user.context);
  const { t } = useTranslation();

  const showSaveToast = (summary: string) => {
    toast.current.show({
      severity: 'success',
      summary: summary,
      detail: t('users.toast_saved'),
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  const showAddToast = (summary: string) => {
    toast.current.show({
      severity: 'success',
      summary: summary,
      detail: t('users.toast_added'),
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  const showDeleteToast = (summary: string) => {
    toast.current.show({
      severity: 'info',
      summary: summary,
      detail: t('users.toast_deleted'),
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  const loadData = () => {
    setLoading(true);
    Promise.all([usersService.getAll(), rolesService.getAll()]).then((response) => {
      setUserRoles(response[1]);
      const rolesDict = Object.assign({}, ...response[1].map((r) => ({ [r.id]: r.name })));
      const users = response[0];
      users.forEach((u) => {
        u.roleName = rolesDict[u.roleId];
      });
      setUsers(users);
      setLoading(false);
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const addEnabled =
    loggedUserContext.limits?.usersNumber == null || loggedUserContext.limits.usersNumber > users?.length;

  const usersTableHeader = (
    <div className="table-header">
      <h3 className="m-0">{t('users.header')}</h3>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder={t('common.search')}
        />
        <Button
          type="button"
          icon="pi pi-plus"
          label={t('common.add')}
          className="p-button ml-2"
          disabled={!addEnabled}
          onClick={() => {
            addUser();
          }}
        ></Button>
      </span>
    </div>
  );

  const closeAddEditModal = () => {
    setUserDialogVisible(false);
    loadData();
  };

  const confirmDeleteUser = (user) => {
    setUserToDelete(user);
    setDeleteUserDialogVisible(true);
  };

  const deleteUser = () => {
    setLoading(true);
    usersService.delete(userToDelete.id).then(() => {
      loadData();

      showDeleteToast(userToDelete.email);
    });

    setDeleteUserDialogVisible(false);
  };
  const hideDeleteDialog = () => {
    setDeleteUserDialogVisible(false);
  };

  const editUser = (_user: User) => {
    setSelectedRowToEdit(_user);
    setUserDialogVisible(true);
  };

  const addUser = () => {
    setSelectedRowToEdit(emptyUser as User);
    setUserDialogVisible(true);
  };

  const deleteDialogFooter = (
    <>
      <Button label={t('common.no')} icon="pi pi-times" className="p-button-text" onClick={() => hideDeleteDialog()} />
      <Button label={t('common.yes')} icon="pi pi-check" className="p-button-text" onClick={() => deleteUser()} />
    </>
  );

  const bodyTemplate = (data: User, props: ColumnBodyOptions) => {
    return <>{data[props.field]}</>;
  };

  const readonlyBodyTemplate = (data: User) => {
    return (
      <React.Fragment>
        {data.readonly === true ? (
          <Tag className="mr-2" icon="pi pi-times-circle" severity="danger" value={t('users.readonly')}></Tag>
        ) : (
          <Tag className="mr-2" icon="pi pi-check" severity="success" value={t('users.write_access')}></Tag>
        )}
      </React.Fragment>
    );
  };

  const actionTemplate = (data: User) => (
    <span>
      <Button
        type="button"
        icon="pi pi-pencil"
        tooltip={t('common.edit')}
        tooltipOptions={{ position: 'top' }}
        className="p-button-success mr-2"
        onClick={() => {
          editUser(data);
        }}
      ></Button>
      <WriteOnlyButton
        type="button"
        icon="pi pi-trash"
        tooltip={t('common.delete')}
        tooltipOptions={{ position: 'top' }}
        className="p-button-success"
        disabled={data.id === loggedUserContext.id}
        onClick={() => {
          confirmDeleteUser(data);
        }}
      />
    </span>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <DataTable
            value={users}
            paginator
            className="p-datatable-customers"
            rows={10}
            dataKey="id"
            rowHover
            globalFilter={globalFilter}
            emptyMessage={t('users.users_empty_message')}
            loading={loading}
            header={usersTableHeader}
          >
            <Column field="email" header={t('users.email')} sortable body={bodyTemplate}></Column>
            <Column field="firstName" header={t('users.first_name')} sortable body={bodyTemplate}></Column>
            <Column field="lastName" header={t('users.last_name')} sortable body={bodyTemplate}></Column>
            <Column field="roleName" header={t('users.role')} sortable body={bodyTemplate}></Column>
            <Column
              field="readonly"
              header={t('users.readonly')}
              sortable
              body={readonlyBodyTemplate}
              align="center"
            ></Column>
            <Column
              headerStyle={{ width: '8rem' }}
              header={t('common.actions')}
              align="center"
              body={actionTemplate}
            ></Column>
          </DataTable>
          <UserAdminDialog
            userDialogVisible={userDialogVisible}
            closeAddEditModal={closeAddEditModal}
            user={selectedRowToEdit}
            availableRoles={roles}
            showAddToast={showAddToast}
            showSaveToast={showSaveToast}
          />
          <Dialog
            visible={deleteUserDialogVisible}
            className="p-fluid"
            header={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                {t('common.confirm')}
              </span>
            }
            modal
            footer={deleteDialogFooter}
            onHide={hideDeleteDialog}
            breakpoints={{ '896px': '90vw' }}
            style={{ minWidth: '450px' }}
          >
            <div className="confirmation-content">
              {userToDelete && (
                <span>
                  <Trans
                    i18nKey="common.confirm_delete_message"
                    values={{ name: userToDelete.email }}
                    components={[<strong />]}
                  />
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>
      <Toast ref={toast} />
    </div>
  );
};
