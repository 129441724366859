import { useFormik } from 'formik';
import { TFunction } from 'i18next';
import usersService from '../../../service/api/UsersService';

export const GetConfirmPasswordRecoveryForm = (initialValues: any, onSuccess: any, t: TFunction) => {
  const form = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validate: (data: any) => {
      let errors: any = {};

      if (!data.password) {
        errors.password = t('register_dialog.empty_password_error');
      }

      if (!data.confirmedPassword) {
        errors.confirmedPassword = t('register_dialog.confirmed_password_required');
      }

      if (data.password !== data.confirmedPassword) {
        errors.confirmedPassword = t('register_dialog.confirmed_password_error');
      }

      return errors;
    },
    onSubmit: async (formData: any) => {
      await usersService.confirmForgotPassword(formData);

      onSuccess();
    },
  });

  return form;
};
