import { Dialog } from 'primereact/dialog';

import { Button } from 'primereact/button';
import FormErrorMessage from '../_shared/FormErrorMessage';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { RegisterDialogProps } from './types';
import { GetRegisterForm } from './validation';
import { getDomain } from '../_shared/functions';

export const RegisterDialog = (props: RegisterDialogProps) => {
  const { t, isVisible, initialValues, dialogHeader, onSubmit, onHide } = props;

  const registeredOnDomain = getDomain(window.location.href);

  const registerForm = GetRegisterForm(initialValues, registeredOnDomain, onSubmit, t);

  const registerDialogFooter = () => {
    return (
      <>
        <Button
          type="submit"
          label={t('register_dialog.register')}
          icon="pi pi-check"
          className="p-button-success mt-3 mr-2 mb-2 pr-4 pt-2 pb-2"
          onClick={registerForm.submitForm}
        />
      </>
    );
  };

  return (
    <Dialog
      visible={isVisible}
      style={{ width: '600px' }}
      header={dialogHeader(t('register_dialog.header'))}
      modal
      className="p-fluid"
      footer={registerDialogFooter}
      onHide={onHide}
    >
      <div className="col-12 md:col-11 mb-3 ml-auto mr-auto">
        <div className="field">
          <label htmlFor="email">
            <b>{t('register_dialog.email')}</b>
          </label>
          <span className="p-input-icon-left">
            <i className="pi pi-user" />
            <InputText
              id="email"
              value={registerForm.values.email}
              onChange={registerForm.handleChange}
              type="email"
              disabled={!!initialValues.email}
              readOnly={!!initialValues.email}
              required
              autoFocus
            />
          </span>
          <FormErrorMessage fieldName="email" formikInstance={registerForm} />
        </div>

        <div className="field">
          <label htmlFor="password">
            <b>{t('register_dialog.password')}</b>
          </label>
          <span className="p-input-icon-left">
            <i className="pi pi-lock" />
            <InputText
              id="password"
              value={registerForm.values.password}
              onChange={registerForm.handleChange}
              type="password"
              required
              autoFocus={!!initialValues.email}
            />
          </span>
          <FormErrorMessage fieldName="password" formikInstance={registerForm} />
        </div>

        <div className="field mb-6">
          <label htmlFor="confirmedPassword">
            <b>{t('register_dialog.confirm_password')}</b>
          </label>
          <span className="p-input-icon-left">
            <i className="pi pi-lock" />
            <InputText
              id="confirmedPassword"
              value={registerForm.values.confirmedPassword}
              onChange={registerForm.handleChange}
              type="password"
              required
            />
          </span>
          <FormErrorMessage fieldName="confirmedPassword" formikInstance={registerForm} />
        </div>

        <div className="mt-0 mb-2">
          <div className="field-checkbox">
            <Checkbox
              id="termsAccepted"
              inputId="termsAccepted"
              value={registerForm.values.termsAccepted}
              checked={registerForm.values.termsAccepted}
              onChange={registerForm.handleChange}
              required
            />
            <label htmlFor="termsAccepted">{t('register_dialog.accept_terms')}</label>
          </div>
          <FormErrorMessage fieldName="termsAccepted" formikInstance={registerForm} />
        </div>

        <div className="field-checkbox">
          <Checkbox
            id="registeredToNewsletter"
            inputId="registeredToNewsletter"
            value={registerForm.values.registeredToNewsletter}
            checked={registerForm.values.registeredToNewsletter}
            onChange={registerForm.handleChange}
          />
          <label htmlFor="registeredToNewsletter">{t('register_dialog.newsletter_option')}</label>
        </div>
      </div>
    </Dialog>
  );
};
