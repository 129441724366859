import { useFormik } from 'formik';
import { TFunction } from 'i18next';
import changeOfStateConfigsService from '../../../../service/api/ChangeOfStateConfigsService';
import { InitialStateConfigValues } from './types';

export const GetChangeOfStateConfigForm = (
  initialStateValues: InitialStateConfigValues,
  onSuccess: () => void,
  scaleId: string,
  t: TFunction,
  showSaveToast: (stateName: string) => void,
  header: string
) => {
  const form = useFormik({
    initialValues: initialStateValues,
    enableReinitialize: true,
    validate: (data: any) => {
      let errors: any = {};
      if (!data.minIndicationChange) {
        errors.minIndicationChange = t('auto_weighings.min_indication_change_required');
      }

      if (!data.maxAcceptableDeviation) {
        errors.maxAcceptableDeviation = t('auto_weighings.max_acceptable_deviation_required');
      }

      return errors;
    },
    onSubmit: async (formData: any) => {
      await changeOfStateConfigsService.updateConfig(scaleId, formData.id, formData);
      showSaveToast(header);
      onSuccess();
    },
  });

  return form;
};
