import { FormikErrors, FormikHelpers, useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import animalsService from '../../service/api/AnimalsService';
import FormErrorMessage from '../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../_shared/FormErrorMessageScroller';
import { useTranslation } from 'react-i18next';
import { Animal } from '../../types/Animal';
import validator from 'validator';
import WriteOnlyButton from '../controls/WriteOnlyButton';

interface FormValuesProps extends Animal {}

const INIT_FORM_STATE = {
  id: null,
  name: '',
  description: '',
  rfid: '',
  divisionId: null,
};

type Props = {
  dialogVisible: boolean;
  closeAddEditModal: VoidFunction;
  animal: Animal;
  availableDivisions: any[];
  showAddToast: (hostName: string) => void;
  showSaveToast: (hostName: string) => void;
};

function AnimalDialog({
  dialogVisible,
  closeAddEditModal,
  animal,
  showAddToast,
  showSaveToast,
  availableDivisions,
}: Props) {
  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);

  const hideDialog = () => {
    formik.resetForm({ values: INIT_FORM_STATE });
    closeAddEditModal();
  };

  const divisions = availableDivisions?.map((r) => {
    return { label: r.name, value: r.id };
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (dialogVisible && animal) {
      setInitFormValues({
        id: animal.id,
        name: animal.name,
        description: animal.description,
        rfid: animal.rfid,
        divisionId: animal.divisionId,
      });
    } else {
      setInitFormValues(INIT_FORM_STATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogVisible]);

  const formik = useFormik({
    initialValues: initFormValues,
    validate: (data: FormValuesProps) => {
      let errors: FormikErrors<FormValuesProps> = {};

      if (!data.name?.trim()) {
        errors.name = t('common.error_name_required');
      }
      if (!data.rfid?.trim()) {
        errors.rfid = t('animals.error_rfid_required');
      }
      if (!validator.isHexadecimal(data.rfid)) {
        errors.rfid = t('animals.error_rfid_hex');
      }
      if (!data.divisionId) {
        errors.divisionId = t('animals.error_division_required');
      }
      return errors;
    },
    onSubmit: async (formData: FormValuesProps, helpers: FormikHelpers<FormValuesProps>) => {
      try {
        if (!formData.id) {
          await animalsService.create(formData);
          showAddToast(formData.name);
        } else {
          await animalsService.edit(formData, formData.id);
          showSaveToast(formData.name);
        }

        hideDialog();
      } catch (error: any) {
        if (error.response.status === 409) {
          if (error.response.data.field === 'name') {
            helpers.setFieldError('name', t('animals.error_name_already_taken'));
          } else if (error.response.data.field === 'rfid') {
            helpers.setFieldError('rfid', t('animals.error_rfid_already_taken'));
          }
        }
      }
      helpers.setSubmitting(false);
    },
    enableReinitialize: true,
  });

  const DialogFooter = (
    <>
      <Button
        type="reset"
        label={t('common.cancel')}
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <WriteOnlyButton
        type="submit"
        label={t('common.save')}
        icon="pi pi-check"
        className="p-button-text"
        onClick={formik.submitForm}
      />
    </>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik}>
      <Dialog
        visible={dialogVisible}
        header={t('animals.animal_details')}
        modal
        className="p-fluid"
        footer={DialogFooter}
        onHide={hideDialog}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
        style={{ width: '40vw' }}
      >
        <form>
          <div className="col-10">
            <div className="field">
              <label htmlFor="name">{t('common.name')}</label>
              <InputText
                id="name"
                value={formik.values.name}
                readOnly={animal ? true : undefined}
                disabled={animal ? true : undefined}
                onChange={formik.handleChange}
                className={formik.touched.name && formik.errors.name && 'p-invalid'}
              />
              <FormErrorMessage fieldName="name" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="description">{t('animals.description')}</label>
              <InputText
                id="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                className={formik.touched.description && formik.errors.description && 'p-invalid'}
              />
              <FormErrorMessage fieldName="description" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="rfid">{t('animals.rfid')}</label>
              <InputText
                id="rfid"
                value={formik.values.rfid}
                onChange={formik.handleChange}
                className={formik.touched.rfid && formik.errors.rfid && 'p-invalid'}
              />
              <FormErrorMessage fieldName="rfid" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="divisionId">{t('animals.division')}</label>
              <Dropdown
                id="divisionId"
                value={formik.values.divisionId}
                onChange={formik.handleChange}
                options={divisions}
                placeholder={t('common.select')}
                className={formik.touched.divisionId && formik.errors.divisionId && 'p-invalid'}
              />
              <FormErrorMessage fieldName="divisionId" formikInstance={formik} />
            </div>
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
}

export default AnimalDialog;
