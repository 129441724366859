import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { useEffect, useState } from 'react';
import hostsService from '../../../service/api/HostsService';
import FormErrorMessage from '../../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../../_shared/FormErrorMessageScroller';
import { useTranslation } from 'react-i18next';
import { HostDialogProps } from './types';
import { Dropdown } from 'primereact/dropdown';
import WriteOnlyButton from '../../controls/WriteOnlyButton';

const INIT_FORM_STATE: any = {
  id: '',
  name: '',
  description: '',
  active: false,
  divisionId: null,
};

function HostDialog(props: HostDialogProps) {
  const { dialogVisible, closeAddEditModal, host, availableDivisions, showAddToast, showSaveToast } = props;

  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);

  const hideDialog = () => {
    formik.resetForm(INIT_FORM_STATE);
    closeAddEditModal();
  };

  const divisions = availableDivisions?.map((r) => {
    return { label: r.name, value: r.id };
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (dialogVisible && host) {
      setInitFormValues({
        id: host.id,
        name: host.name,
        description: host.description,
        active: host.active,
        divisionId: host.divisionId,
      });
    } else {
      setInitFormValues(INIT_FORM_STATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogVisible]);

  const formik = useFormik({
    initialValues: initFormValues,
    validate: (data) => {
      let errors: any = {};

      if (!data.name?.trim()) {
        errors.name = t('common.error_name_required');
      }

      if (!data.divisionId) {
        errors.divisionId = t('hosts.error_division_required');
      }

      return errors;
    },
    onSubmit: async (formData, helpers) => {
      if (!formData.id) {
        hostsService
          .create(formData)
          .then(() => {
            hideDialog();
            showAddToast(formData.name);
          })
          .catch((error) => {
            if (error.response.status === 409) {
              helpers.setFieldError('name', t('hosts.error_name_already_taken'));
            }
          })
          .finally(() => helpers.setSubmitting(false));
      } else {
        hostsService
          .edit(formData, formData.id)
          .then(() => {
            hideDialog();
            showSaveToast(formData.name);
          })
          .catch((error) => {
            if (error.response.status === 409) {
              helpers.setFieldError('name', t('hosts.error_name_already_taken'));
            }
          })
          .finally(() => helpers.setSubmitting(false));
      }
    },
    enableReinitialize: true,
  });

  const DialogFooter = (
    <>
      <Button
        type="reset"
        label={t('common.cancel')}
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <WriteOnlyButton
        type="submit"
        label={t('common.save')}
        icon="pi pi-check"
        className="p-button-text"
        onClick={formik.submitForm}
      />
    </>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik}>
      <Dialog
        visible={dialogVisible}
        header={t('hosts.host_details')}
        modal
        className="p-fluid"
        footer={DialogFooter}
        onHide={hideDialog}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
        style={{ width: '40vw' }}
      >
        <form>
          <div className="col-10">
            <div className="field">
              <label htmlFor="name">{t('common.name')}</label>
              <InputText
                id="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                className={formik.touched.name && formik.errors.name && 'p-invalid'}
              />
              <FormErrorMessage fieldName="name" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="description">{t('hosts.description')}</label>
              <InputText
                id="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                className={formik.touched.description && formik.errors.description && 'p-invalid'}
              />
              <FormErrorMessage fieldName="description" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="divisionId">{t('hosts.division')}</label>
              <Dropdown
                id="divisionId"
                value={formik.values.divisionId}
                onChange={formik.handleChange}
                options={divisions}
                placeholder={t('common.select')}
                className={formik.touched.divisionId && formik.errors.divisionId && 'p-invalid'}
              />
              <FormErrorMessage fieldName="divisionId" formikInstance={formik} />
            </div>
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
}

export default HostDialog;
