import { useEffect, useState } from 'react';
import { Chart } from 'primereact/chart';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import 'chartjs-adapter-date-fns';
import { useTranslation } from 'react-i18next';
import { chartDaysModeOptions, chartHoursModeOptions } from '../../_shared/chart-settings';
import { SETTINGS } from '../../../enums/settings.enum';
import scalesService from '../../../service/api/ScalesService';
import { CHART_MODE } from '../../../enums/scale-chart.enum';
import { ChartToolbar } from './ChartToolbar';
import { ScaleChartProps } from './types';
import { Panel } from 'primereact/panel';

import generalStyle from '../../../assets/custom-styles/general.module.scss';
import { panelTemplate } from '../../_shared/panel-template';
import weighingsService from '../../../service/api/WeighingsService';
import { handleDateFilters } from '../../_shared/functions';

export const ScaleChart = (props: ScaleChartProps): JSX.Element => {
  const { scaleName } = props;
  const { t } = useTranslation();

  const noTagString = t('common.no_tag');

  const [startDate, setStartDate] = useState(format(new Date(), SETTINGS.API_DATE_FORMAT));
  const [endDate, setEndDate] = useState(format(new Date(), SETTINGS.API_DATE_FORMAT));
  const [chartData, setChartData] = useState<any>();
  const [chartOptions, setChartOptions] = useState<any>(chartDaysModeOptions(t));
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const { scaleId } = useParams<{ scaleId: string }>();

  useEffect(() => {
    weighingsService.getTags(scaleId).then((tags) => {
      tags.push(noTagString);
      setTags(tags);
    });
  }, [scaleId, noTagString]);

  useEffect(() => {
    const dateFilters = handleDateFilters(startDate, endDate);
    const tagFilters = { tags: selectedTags, noTag: selectedTags.includes(noTagString) };

    scalesService.getWeighingsForScale(scaleId, dateFilters, tagFilters).then((data) => {
      setChartData({
        datasets: [
          {
            data: data?.chartData,
          },
        ],
      });

      if (data.mode === CHART_MODE.HOURS) {
        setChartOptions(chartHoursModeOptions(t));
      } else {
        setChartOptions(chartDaysModeOptions(t));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scaleId, startDate, endDate, selectedTags]);

  return (
    <>
      <Panel
        header={`${t('scale_chart.header')} ${scaleName}`}
        className={generalStyle.panelTitle}
        headerTemplate={(e) => panelTemplate(e)}
      >
        <ChartToolbar
          t={t}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          tags={tags}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        />

        <div className="grid p-fluid">
          <div className="col-12 lg:col-12">
            <div className="card">
              <Chart type="line" data={chartData} options={chartOptions} />
            </div>
          </div>
        </div>
      </Panel>
    </>
  );
};
