import { useEffect } from 'react';
import { TFunction } from 'react-i18next';

const useConfirmTabClose = (isUnsafeTabClose: boolean, t: TFunction) => {
  const confirmationMessage = t('hooks.unsaved_changes_confirmation_message');

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (isUnsafeTabClose) {
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
      return null;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [isUnsafeTabClose, confirmationMessage]);
};

export default useConfirmTabClose;
