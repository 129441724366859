import { FormikErrors, FormikHelpers, useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import ordersService from '../../service/api/OrdersService';
import FormErrorMessage from '../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../_shared/FormErrorMessageScroller';
import { useTranslation } from 'react-i18next';
import { CreateOrderRequest } from '../../types/Order';

interface FormValuesProps extends CreateOrderRequest {}

const INIT_FORM_STATE: FormValuesProps = {
  name: '',
  recipeId: null,
};

type Props = {
  dialogVisible: boolean;
  closeAddEditModal: VoidFunction;
  recipeId: string;
  showAddToast: (name: string) => void;
};

function OrderDialog({
  dialogVisible,
  closeAddEditModal,
  recipeId,

  showAddToast,
}: Props) {
  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);

  const hideDialog = () => {
    formik.resetForm({ values: INIT_FORM_STATE });
    closeAddEditModal();
  };

  const { t } = useTranslation();

  useEffect(() => {
    setInitFormValues({ ...INIT_FORM_STATE, recipeId: recipeId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogVisible]);

  const formik = useFormik({
    initialValues: initFormValues,
    validate: (data: FormValuesProps) => {
      let errors: FormikErrors<FormValuesProps> = {};

      if (!data.name?.trim()) {
        errors.name = t('common.error_name_required');
      }

      return errors;
    },
    onSubmit: async (formData: FormValuesProps, helpers: FormikHelpers<FormValuesProps>) => {
      try {
        await ordersService.create(formData);

        showAddToast(formData.name);

        hideDialog();
      } catch (error: any) {
        if (error.response.status === 409) {
          const field = error.response.data.field;
          const errorCode = error.response.data.error;
          if (field) {
            helpers.setFieldError(field, t(`orders.error_${field}_already_taken`));
          } else if (errorCode) {
            helpers.setFieldError('name', t(`scales_indications.errors.${error.response.data.error}`));
          } else {
            helpers.setFieldError('name', t('orders.error_another_order_in_progress'));
          }
        }
      }
      helpers.setSubmitting(false);
    },
    enableReinitialize: true,
  });

  const DialogFooter = (
    <>
      <Button
        type="reset"
        label={t('common.cancel')}
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        type="submit"
        label={t('common.save')}
        icon="pi pi-check"
        className="p-button-text"
        onClick={formik.submitForm}
      />
    </>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik}>
      <Dialog
        visible={dialogVisible}
        header={t('orders.order_details')}
        modal
        className="p-fluid"
        footer={DialogFooter}
        onHide={hideDialog}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
        style={{ width: '40vw' }}
      >
        <form>
          <div className="col-10">
            <div className="field">
              <label htmlFor="name">{t('common.name')}</label>
              <InputText
                id="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                className={formik.touched.name && formik.errors.name && 'p-invalid'}
              />
              <FormErrorMessage fieldName="name" formikInstance={formik} />
            </div>
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
}

export default OrderDialog;
