import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { format } from 'date-fns';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';

import customersService from '../../service/api/CustomersService';

import licensesService from '../../service/api/LicensesService';
import { Dialog } from 'primereact/dialog';
import LicenseDialog from './LicenseDialog';
import { Trans, useTranslation } from 'react-i18next';
import { License } from '../../types/License';
import { SETTINGS } from '../../enums/settings.enum';

import { Customer } from '../../types/Customer';

import WriteOnlyButton from '../controls/WriteOnlyButton';

export const LicenseManagement = () => {
  const toast = useRef<any>();

  const [globalFilter, setGlobalFilter] = useState('');
  const [loading, setLoading] = useState(true);

  const [licenses, setLicenses] = useState<License[]>(null);
  const [licenseToDelete, setLicenseToDelete] = useState<License>();

  const [customers, setCustomers] = useState<Customer[]>();

  const [selectedRowToEdit, setSelectedRowToEdit] = useState<License>(null);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [addEditDialogVisible, setAddEditDialogVisible] = useState(false);
  const { t } = useTranslation();

  const showSaveToast = (summary: string) => {
    toast.current.show({
      severity: 'success',
      summary: summary,
      detail: t('licenses.toast_saved'),
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  const showAddToast = (summary: string) => {
    toast.current.show({
      severity: 'success',
      summary: summary,
      detail: t('licenses.toast_added'),
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  const showDeleteToast = (summary: string) => {
    toast.current.show({
      severity: 'info',
      summary: summary,
      detail: t('licenses.toast_deleted'),
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  const showCopyToast = (summary: string) => {
    toast.current.show({
      severity: 'success',
      summary: summary,
      detail: t('licenses.toast_copied'),
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  const loadData = () => {
    setLoading(true);
    Promise.all([licensesService.getAll(), customersService.getAll()]).then((response) => {
      setCustomers(response[1]);
      const customersDict = Object.assign({}, ...response[1].map((r) => ({ [r.id]: r.name })));

      const _licenses = response[0];
      _licenses.forEach((_x) => {
        _x.customerName = customersDict[_x.customerId];
      });
      setLicenses(_licenses);
      setLoading(false);
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const licensesTableHeader = (
    <div className="table-header">
      {t('licenses.header')}
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder={t('common.search')}
        />
        <Button
          type="button"
          icon="pi pi-plus"
          label={t('common.add')}
          className="p-button ml-2"
          onClick={() => {
            addLicense();
          }}
        ></Button>
      </span>
    </div>
  );

  const closeAddEditModal = () => {
    setAddEditDialogVisible(false);
    loadData();
  };

  const confirmDeleteLicense = (license: License) => {
    setLicenseToDelete(license);
    setDeleteDialogVisible(true);
  };

  const deleteLicense = () => {
    setLoading(true);
    licensesService.delete(licenseToDelete.id).then(() => {
      loadData();

      showDeleteToast(licenseToDelete.customerName);
    });

    setDeleteDialogVisible(false);
  };
  const hideDeleteDialog = () => {
    setDeleteDialogVisible(false);
  };

  const copyLicenseKey = async (license: License) => {
    const licenseKey = await licensesService.getLicenseKey(license.id);
    navigator.clipboard.writeText(licenseKey);
    showCopyToast(license.customerName);
  };

  const editLicense = (_license: License) => {
    setSelectedRowToEdit(_license);
    setAddEditDialogVisible(true);
  };

  const addLicense = () => {
    setSelectedRowToEdit(null);
    setAddEditDialogVisible(true);
  };

  const deleteDialogFooter = (
    <>
      <Button label={t('common.no')} icon="pi pi-times" className="p-button-text" onClick={() => hideDeleteDialog()} />
      <Button label={t('common.yes')} icon="pi pi-check" className="p-button-text" onClick={() => deleteLicense()} />
    </>
  );

  const bodyTemplate = (data: License, props: ColumnBodyOptions) => {
    return <>{data[props.field]}</>;
  };

  const calendarColumnTemplate = (data: License, props: ColumnBodyOptions) => {
    const value = data[props.field];
    const formattedValue = value ? format(value, SETTINGS.FULL_DATE_FORMAT) : '-';
    return <>{formattedValue}</>;
  };

  const actionTemplate = (data: License) => (
    <span>
      <Button
        type="button"
        icon="pi pi-pencil"
        tooltip={t('common.edit')}
        tooltipOptions={{ position: 'top' }}
        className="p-button-success mr-2"
        onClick={() => {
          editLicense(data);
        }}
      />
      <WriteOnlyButton
        type="button"
        icon="pi pi-trash"
        tooltip={t('common.delete')}
        tooltipOptions={{ position: 'top' }}
        className="p-button-success"
        onClick={() => {
          confirmDeleteLicense(data);
        }}
      />
      <Button
        type="button"
        icon="pi pi-copy"
        tooltip={t('licenses.copyToClipboard')}
        tooltipOptions={{ position: 'top' }}
        className="p-button-success ml-2"
        disabled={!data.hasLicenseKey}
        onClick={() => {
          copyLicenseKey(data);
        }}
      />
    </span>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <DataTable
            value={licenses}
            paginator
            className="p-datatable-customers"
            rows={10}
            dataKey="id"
            rowHover
            globalFilter={globalFilter}
            emptyMessage={t('licenses.empty_message')}
            loading={loading}
            header={licensesTableHeader}
          >
            <Column field="customerName" header={t('licenses.customer')} sortable body={bodyTemplate}></Column>
            <Column field="type" header={t('licenses.type')} sortable body={bodyTemplate}></Column>
            <Column field="email" header={t('licenses.email')} sortable body={bodyTemplate}></Column>
            <Column field="deviceKey" header={t('licenses.device_key')} sortable body={bodyTemplate}></Column>
            <Column field="version" header={t('licenses.version')} sortable body={bodyTemplate}></Column>
            <Column
              field="expirationDate"
              header={t('licenses.expiration_date')}
              sortable
              body={calendarColumnTemplate}
            ></Column>
            <Column
              headerStyle={{ width: '12rem' }}
              header={t('common.actions')}
              align="center"
              body={actionTemplate}
            ></Column>
          </DataTable>
          <LicenseDialog
            dialogVisible={addEditDialogVisible}
            closeAddEditModal={closeAddEditModal}
            license={selectedRowToEdit}
            availableCustomers={customers}
            showAddToast={showAddToast}
            showSaveToast={showSaveToast}
          />
          <Dialog
            visible={deleteDialogVisible}
            className="p-fluid"
            header={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                {t('common.confirm')}
              </span>
            }
            modal
            footer={deleteDialogFooter}
            onHide={hideDeleteDialog}
            breakpoints={{ '896px': '90vw' }}
            style={{ minWidth: '450px' }}
          >
            <div className="confirmation-content">
              {licenseToDelete && (
                <span>
                  <Trans
                    i18nKey="common.confirm_delete_message"
                    values={{ name: licenseToDelete.customerName }}
                    components={[<strong />]}
                  />
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>

      <Toast ref={toast} />
    </div>
  );
};
