export const getFileFromBuffer = (buffer: any, mime: any, downloadFileName: any = '') => {
  const blob = new Blob([buffer], { type: mime || 'application/octet-stream' });
  getFileFromBlob(blob, downloadFileName);
};

export const getFileFromBlob = (blob: any, downloadFileName: any) => {
  const url = window.URL.createObjectURL(blob);
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.target = '_blank';
  if (downloadFileName) {
    anchor.download = downloadFileName;
  }
  anchor.click();
};
