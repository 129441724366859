import { useHistory } from 'react-router-dom';
import { SETTINGS } from './enums/settings.enum';
import { useTranslation } from 'react-i18next';
import { useVersion } from './components/_shared/hooks/useVersion';

const AppFooter = (): JSX.Element => {
  const history = useHistory();

  const { t } = useTranslation();

  const version = useVersion();

  return (
    <div className="layout-footer">
      <div className="grid">
        <div className="col-6">
          <button type="button" className="p-link logo-container">
            <img id="layout-footer-logo" src="assets/layout/images/logo-black.png" alt="application logo" width="220" />
          </button>
        </div>
        <div className="col-6 footer-icons">
          {version && (
            <span className="mr-2">
              {t('common.version')} {version}
            </span>
          )}
          <button type="button" className="p-link" onClick={() => history.push(SETTINGS.DEFAULT_PAGE)}>
            <i className="pi pi-home"></i>
          </button>
          <a href="http://www.saasnative.com/">
            <button type="button" className="p-link">
              <i className="pi pi-globe"></i>
            </button>
          </a>
          <a href="mailto:contact@saasnative.com">
            <button type="button" className="p-link">
              <i className="pi pi-envelope"></i>
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default AppFooter;
