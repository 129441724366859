import { useDispatch } from 'react-redux';
import { scaleIndicationsActions } from '../store/scale-indications-slice';

export const WebSockerConfig = (props) => {
  const dispatch = useDispatch();
  let ws;

  const restartWs = () => {
    ws?.close();
    configureWs();
  };

  let timeoutId = setInterval(restartWs, 30000 + 1000);

  const heartbeat = () => {
    clearTimeout(timeoutId);

    timeoutId = setInterval(restartWs, 30000 + 1000);
  };

  const connectToServer = async () => {
    let wsAddress;
    if (process.env.REACT_APP_WEBSOCKET_PATH && process.env.REACT_APP_WEBSOCKET_PORT) {
      wsAddress = `ws://${window.location.hostname}:${process.env.REACT_APP_WEBSOCKET_PORT}/${process.env.REACT_APP_WEBSOCKET_PATH}`;
    } else {
      wsAddress = process.env.REACT_APP_WEBSOCKET_ADDRESS;
    }
    if (!wsAddress) {
      return null;
    }

    const socket = new WebSocket(wsAddress);
    return new Promise((resolve, _reject) => {
      const timer = setInterval(() => {
        if (socket.readyState === 1) {
          clearInterval(timer);
          resolve(socket);
        }
      }, 10);
    });
  };

  const configureWs = async () => {
    try {
      ws = await connectToServer();
      if (!ws) {
        return;
      }

      ws.onmessage = (message) => {
        if (message.data === 'PING') {
          heartbeat();
          ws.send('PONG');
          return;
        }
        const data = JSON.parse(message.data);
        if (data.action === 'updateScaleIndication') {
          dispatch(scaleIndicationsActions.updateScaleIndication(data.data));
          return;
        }
      };
    } catch (e) {
      console.error('Error connecting to websocket', e);
    }
  };

  configureWs();

  return <>{props.children}</>;
};
