import React from 'react';

import { Toolbar } from 'primereact/toolbar';
import { MultiSelect } from 'primereact/multiselect';
import { ScalesListToolbarProps } from './types';

export const ScalesListToolbar = (props: ScalesListToolbarProps) => {
  const { t, scales, itemsSelected, setItemsSelected } = props;

  const itemTemplate = (option: any) => {
    return (
      <div className="flex align-items-center">
        <span className={`mr-2`} />
        <span>{option.name}</span>
      </div>
    );
  };

  const selectedItemTemplate = (option: any) => {
    if (option) {
      return (
        <div className="inline-flex align-items-center font-bold border-round mr-2">
          <span>{option.name}</span>
        </div>
      );
    }

    return t('scales_indications.available_scales');
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <MultiSelect
          value={itemsSelected}
          onChange={(e) => setItemsSelected(e.value)}
          options={scales}
          optionLabel="name"
          placeholder={t('scales_indications.available_scales')}
          filter
          itemTemplate={itemTemplate}
          selectedItemTemplate={selectedItemTemplate}
          maxSelectedLabels={1}
          className={`mr-5 mt-2 col-12 pr-2 pb-1 pt-1`}
          selectedItemsLabel={`${t('scales_indications.selected_scales')}: ${itemsSelected?.length}`}
        />
      </React.Fragment>
    );
  };

  return <Toolbar className="mb-4 grid p-fluid" left={leftToolbarTemplate} />;
};
