import { Dialog } from 'primereact/dialog';

import { Button } from 'primereact/button';

import { InputText } from 'primereact/inputtext';
import FormErrorMessage from '../../_shared/FormErrorMessage';
import { GetConfirmPasswordRecoveryForm } from './validation';
import { confirmPasswordRecoveryDialogProps } from '../types';

export const ConfirmPasswordRecoveryDialog = (props: confirmPasswordRecoveryDialogProps) => {
  const { t, initialValues, isVisible, onSuccess, dialogHeader } = props;

  const confirmPasswordRecoveryForm = GetConfirmPasswordRecoveryForm(initialValues, onSuccess, t);

  const confirmPasswordRecoveryDialogFooter = () => {
    return (
      <>
        <Button
          type="submit"
          label={t('common.continue')}
          className="p-button-success mt-3 mr-2 mb-2 pr-4 pt-2 pb-2 pl-4"
          onClick={confirmPasswordRecoveryForm.submitForm}
        />
      </>
    );
  };

  return (
    <Dialog
      visible={isVisible}
      style={{ width: '600px' }}
      header={dialogHeader(t('confirm_password_recovery_dialog.header'))}
      modal
      className="p-fluid"
      footer={confirmPasswordRecoveryDialogFooter}
      onHide={() => null}
      closable={false}
    >
      <div className="col-12 md:col-11 mb-3 ml-auto mr-auto">
        <div className="field">
          <label htmlFor="password">
            <b>{t('register_dialog.password')}</b>
          </label>
          <span className="p-input-icon-left">
            <i className="pi pi-lock" />
            <InputText
              id="password"
              value={confirmPasswordRecoveryForm.values.password}
              onChange={confirmPasswordRecoveryForm.handleChange}
              type="password"
              required
              autoFocus
            />
          </span>
          <FormErrorMessage fieldName="password" formikInstance={confirmPasswordRecoveryForm} />
        </div>

        <div className="field mb-6">
          <label htmlFor="confirmedPassword">
            <b>{t('register_dialog.confirm_password')}</b>
          </label>
          <span className="p-input-icon-left">
            <i className="pi pi-lock" />
            <InputText
              id="confirmedPassword"
              value={confirmPasswordRecoveryForm.values.confirmedPassword}
              onChange={confirmPasswordRecoveryForm.handleChange}
              type="password"
              required
              autoFocus
            />
          </span>
          <FormErrorMessage fieldName="confirmedPassword" formikInstance={confirmPasswordRecoveryForm} />
        </div>

        <div className="field">
          <label htmlFor="confirmationCode">
            <b>{t('confirmation_dialog.confirmation_code')}</b>
          </label>
          <span className="p-input-icon-left">
            <i className="pi pi-lock-open" />
            <InputText
              id="confirmationCode"
              value={confirmPasswordRecoveryForm.values.confirmationCode}
              onChange={confirmPasswordRecoveryForm.handleChange}
              type="text"
              required
              autoFocus
            />
          </span>
          <FormErrorMessage fieldName="confirmationCode" formikInstance={confirmPasswordRecoveryForm} />
        </div>
      </div>
    </Dialog>
  );
};
