import React, { useState, useRef } from 'react';
import { Calendar } from 'primereact/calendar';
import { parseISO, format } from 'date-fns';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { REPORT_TYPES } from '../../../enums/report-types.enum';
import { dateFilter, handleDateFilters, handleEndDate, handleStartDate } from '../../_shared/functions';
import changesOfStatesService from '../../../service/api/ChangesOfStatesService';
import { getFileFromBuffer } from '../../_shared/getFile';
import { ChangesOfStatesToolbarParams } from './types';
import { useMediaQuery } from 'usehooks-ts';
import generalStyle from '../../../assets/custom-styles/general.module.scss';
import { SETTINGS } from '../../../enums/settings.enum';
import { OverlayPanel } from 'primereact/overlaypanel';

export const ChangesOfStatesToolbar = (props: ChangesOfStatesToolbarParams) => {
  const {
    t,
    scaleId,
    scaleName,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    paginatorState,
    setPaginatorState,
    paginationParams,
    setPaginationParams,
    changesOfStates,
  } = props;

  const op = useRef(null);

  const [pdfLoading, setPdfLoading] = useState(false);
  const [excelLoading, setExcelLoading] = useState(false);

  const isTabletView = useMediaQuery(SETTINGS.TABLET_VIEW);
  const isMobileView = useMediaQuery(SETTINGS.MOBILE_VIEW);

  const clearDates = (): void => {
    setStartDate('');
    setEndDate('');
    setPaginatorState({ ...paginatorState, currentPage: 1 });
    setPaginationParams({
      ...paginationParams,
      order: {
        isAscending: false,
        orderColumn: '',
      },
    });
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Calendar
          placeholder={t('common.date_from')}
          locale="pl"
          inputId="startDate"
          value={startDate ? parseISO(startDate) : undefined}
          className={`mr-1 mt-2 col-11 md:col-3`}
          onChange={(element: any) =>
            handleStartDate(element?.value, endDate, setStartDate, setEndDate, paginatorState, setPaginatorState)
          }
          showIcon
        />

        <Calendar
          placeholder={t('common.date_to')}
          locale="pl"
          inputId="endDate"
          value={endDate ? parseISO(endDate) : undefined}
          className={`mr-1 mt-2 col-11 md:col-3`}
          onChange={(element: any) =>
            handleEndDate(element?.value, startDate, setStartDate, setEndDate, paginatorState, setPaginatorState)
          }
          showIcon
        />

        <Button
          id="button"
          onClick={() => dateFilter(1, setStartDate, setEndDate, paginatorState, setPaginatorState)}
          label={t('common.today')}
          icon="pi pi-filter"
          iconPos="right"
          className={`p-button-primary mr-1 mt-2 col-11 md:col-2 pt-2 pb-2`}
        />

        <Button
          id="button"
          onClick={() => dateFilter(7, setStartDate, setEndDate, paginatorState, setPaginatorState)}
          label={t('common.last_seven_days')}
          icon="pi pi-filter"
          iconPos="right"
          className={`p-button-primary mr-1 mt-2 col-11 md:col-2 pt-2 pb-2`}
        />

        <Button
          id="button"
          onClick={() => clearDates()}
          label={t('common.clear')}
          icon="pi pi-times"
          iconPos="right"
          className={`p-button-primary mr-1 mt-2 col-11 md:col-2 pt-2 pb-2`}
        />
      </React.Fragment>
    );
  };

  const toggle = (event: any) => {
    op.current.toggle(event);
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          type="button"
          label={t('common.export')}
          onClick={toggle}
          className={`col-11 md:col-12 mr-1 mt-2 md:pr-3 md:pl-3`}
          icon="pi pi-download"
          iconPos="right"
          disabled={!changesOfStates.length}
        />
        <OverlayPanel ref={op} appendTo={document.body}>
          <Button
            label="PDF"
            className="p-button-plain p-button-text"
            icon="pi pi-file-pdf"
            onClick={() => {
              downloadReport(REPORT_TYPES.PDF);
              setPdfLoading(true);
            }}
            disabled={excelLoading}
            loading={pdfLoading}
          />
          <br />
          <Button
            label="Excel"
            className="p-button-plain p-button-text export-button-menu-width"
            icon="pi pi-file-excel"
            onClick={() => {
              downloadReport(REPORT_TYPES.EXCEL);
              setExcelLoading(true);
            }}
            disabled={pdfLoading}
            loading={excelLoading}
          />
        </OverlayPanel>
      </React.Fragment>
    );
  };

  const exportReport = (options: any, reportFormat: string) => {
    const dateFilters = handleDateFilters(startDate, endDate);

    changesOfStatesService
      .getReport(scaleId, options.format, paginationParams.order.isAscending, dateFilters)
      .then((report: any) => {
        reportFormat === REPORT_TYPES.EXCEL ? setExcelLoading(false) : setPdfLoading(false);

        !options.download && getFileFromBuffer(report.data, options.format);
        options.download && getFileFromBuffer(report.data, options.format, prepareFileName());
      });
  };

  const downloadReport = (reportFormat: string) => {
    let report: any = {
      download: true,
    };

    if (reportFormat === REPORT_TYPES.EXCEL) {
      report.format = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      report.extension = '.xlsx';
    } else {
      report.format = 'application/pdf';
      report.extension = '.pdf';
    }

    exportReport(report, reportFormat);
  };

  const prepareFileName = (): string => {
    const formattedDate = format(Date.now(), 'yyyyMMdd_HHmmss');
    return `${t('changes_of_states.changes_of_states_file_name_report')}_${scaleName}_${formattedDate}`;
  };

  const MobileToolbar = () => {
    const LeftToolbar = () => leftToolbarTemplate();
    const RightToolbar = () => rightToolbarTemplate();

    return (
      <React.Fragment>
        <div className={generalStyle.mobileToolbar}>
          <LeftToolbar />
          <RightToolbar />
        </div>
      </React.Fragment>
    );
  };

  return isTabletView || isMobileView ? (
    <MobileToolbar />
  ) : (
    <Toolbar className="mb-4 grid" left={leftToolbarTemplate} right={rightToolbarTemplate} />
  );
};
