import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import hostsService from '../../service/api/HostsService';

import ioService from '../../service/api/IoService';
import { Dialog } from 'primereact/dialog';
import IoDialog from './IoDialog';
import { Trans, useTranslation } from 'react-i18next';
import { Io, ioModels } from '../../types/Io';

import { Toast } from 'primereact/toast';
import { SETTINGS } from '../../enums/settings.enum';
import WriteOnlyButton from '../controls/WriteOnlyButton';

export const IoManagement = () => {
  const toast = useRef<any>();

  const [globalFilter, setGlobalFilter] = useState('');
  const [loading, setLoading] = useState(true);

  const [io, setIo] = useState<Io[]>(null);
  const [ioToDelete, setIoToDelete] = useState<Io>();

  const [hosts, setHosts] = useState<any[]>();

  const [selectedRowToEdit, setSelectedRowToEdit] = useState<Io>(null);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [addEditDialogVisible, setAddEditDialogVisible] = useState(false);
  const { t } = useTranslation();

  const showSaveToast = (summary: string) => {
    toast.current.show({
      severity: 'success',
      summary: summary,
      detail: t('io.toast_saved'),
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  const showAddToast = (summary: string) => {
    toast.current.show({
      severity: 'success',
      summary: summary,
      detail: t('io.toast_added'),
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  const showDeleteToast = (summary: string) => {
    toast.current.show({
      severity: 'info',
      summary: summary,
      detail: t('io.toast_deleted'),
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  const loadData = () => {
    setLoading(true);
    Promise.all([ioService.getAll(), hostsService.getActive()]).then((response) => {
      setHosts(response[1]);
      const hostsDict = Object.assign({}, ...response[1].map((r) => ({ [r.id]: r.name })));

      const _io = response[0];
      _io.forEach((_x) => {
        _x.hostName = hostsDict[_x.hostId];
        _x.modelName = ioModels.find((m) => m.id === _x.model)?.name;
      });
      setIo(_io);
      setLoading(false);
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const ioTableHeader = (
    <div className="table-header">
      {t('io.header')}
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder={t('common.search')}
        />
        <Button
          type="button"
          icon="pi pi-plus"
          label={t('common.add')}
          className="p-button ml-2"
          onClick={() => {
            addIo();
          }}
        ></Button>
      </span>
    </div>
  );

  const closeAddEditModal = () => {
    setAddEditDialogVisible(false);
    loadData();
  };

  const confirmDeleteIo = (io: Io) => {
    setIoToDelete(io);
    setDeleteDialogVisible(true);
  };

  const deleteIo = () => {
    setLoading(true);
    ioService.delete(ioToDelete.id).then(() => {
      loadData();

      showDeleteToast(ioToDelete.name);
    });

    setDeleteDialogVisible(false);
  };
  const hideDeleteDialog = () => {
    setDeleteDialogVisible(false);
  };

  const editIo = (_io: Io) => {
    setSelectedRowToEdit(_io);
    setAddEditDialogVisible(true);
  };

  const addIo = () => {
    setSelectedRowToEdit(null);
    setAddEditDialogVisible(true);
  };

  const deleteDialogFooter = (
    <>
      <Button label={t('common.no')} icon="pi pi-times" className="p-button-text" onClick={() => hideDeleteDialog()} />
      <Button label={t('common.yes')} icon="pi pi-check" className="p-button-text" onClick={() => deleteIo()} />
    </>
  );

  const bodyTemplate = (data: Io, props: ColumnBodyOptions) => {
    return <>{data[props.field]}</>;
  };

  const actionTemplate = (data: Io) => (
    <span>
      <Button
        type="button"
        icon={data.isUsed ? 'pi pi-eye' : 'pi pi-pencil'}
        tooltip={data.isUsed ? t('common.preview') : t('common.edit')}
        tooltipOptions={{ position: 'top' }}
        className="p-button-success mr-2"
        onClick={() => {
          editIo(data);
        }}
      ></Button>
      <WriteOnlyButton
        type="button"
        disabled={data.isUsed}
        icon="pi pi-trash"
        tooltip={t('common.delete')}
        tooltipOptions={{ position: 'top' }}
        className="p-button-success"
        onClick={() => {
          confirmDeleteIo(data);
        }}
      />
    </span>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <DataTable
            value={io}
            paginator
            className="p-datatable-customers"
            rows={10}
            dataKey="id"
            rowHover
            globalFilter={globalFilter}
            emptyMessage={t('io.empty_message')}
            loading={loading}
            header={ioTableHeader}
          >
            <Column field="name" header={t('common.name')} sortable body={bodyTemplate}></Column>
            <Column field="description" header={t('io.description')} sortable body={bodyTemplate}></Column>
            <Column field="hostName" header={t('io.host')} sortable body={bodyTemplate}></Column>
            <Column field="modelName" header={t('io.model')} sortable body={bodyTemplate}></Column>
            <Column field="ipAddress" header={t('io.ip_address')} sortable body={bodyTemplate}></Column>
            <Column
              headerStyle={{ width: '8rem' }}
              header={t('common.actions')}
              align="center"
              body={actionTemplate}
            ></Column>
          </DataTable>
          <IoDialog
            dialogVisible={addEditDialogVisible}
            closeAddEditModal={closeAddEditModal}
            io={selectedRowToEdit}
            availableHosts={hosts}
            showAddToast={showAddToast}
            showSaveToast={showSaveToast}
          />
          <Dialog
            visible={deleteDialogVisible}
            className="p-fluid"
            header={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                {t('common.confirm')}
              </span>
            }
            modal
            footer={deleteDialogFooter}
            onHide={hideDeleteDialog}
            breakpoints={{ '896px': '90vw' }}
            style={{ minWidth: '450px' }}
          >
            <div className="confirmation-content">
              {ioToDelete && (
                <span>
                  <Trans
                    i18nKey="common.confirm_delete_message"
                    values={{ name: ioToDelete.name }}
                    components={[<strong />]}
                  />
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>

      <Toast ref={toast} />
    </div>
  );
};
