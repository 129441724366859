import axios from 'axios';
import { Customer } from '../../types/Customer';

class CustomersService {
  async getAll(): Promise<Customer[]> {
    const result = await axios.get('/customers');
    return result.data;
  }
  async get(id: string): Promise<Customer> {
    const result = await axios.get(`/customers/${id}`);
    return result.data;
  }
  async delete(id: string): Promise<void> {
    return axios.delete(`/customers/${id}`);
  }
  async create(postData: Customer): Promise<void> {
    return axios.post(`/customers`, mapToRequestBody(postData));
  }
  async edit(postData: Customer, id: string): Promise<void> {
    return axios.put(`/customers/${id}`, mapToRequestBody(postData));
  }

  async reassign(customerId: string): Promise<void> {
    return axios.put('/customers/reassign', { id: customerId });
  }
}

const mapToRequestBody = (customer: Customer): any => {
  const result = {
    id: customer.id,
    name: customer.name,
    firstName: customer.firstName,
    lastName: customer.lastName,
    taxId: customer.taxId,
    comment: customer.comment,
  };

  return result;
};

const customersService = new CustomersService();
export default customersService;
