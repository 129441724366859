import axios from 'axios';
import { Io } from '../../types/Io';

class IoService {
  async getAll(): Promise<Io[]> {
    const result = await axios.get('/io');
    return result.data;
  }
  async get(id: string): Promise<Io> {
    const result = await axios.get(`/io/${id}`);
    return result.data;
  }
  async delete(id: string): Promise<void> {
    return axios.delete(`/io/${id}`);
  }
  async create(postData: Io): Promise<void> {
    return axios.post(`/io`, mapToRequestBody(postData));
  }
  async edit(postData: Io, id: string): Promise<void> {
    return axios.put(`/io/${id}`, mapToRequestBody(postData));
  }
}

const mapToRequestBody = (io: Io): any => {
  const result = {
    id: io.id,
    name: io.name,
    description: io.description,
    hostId: io.hostId,
    model: io.model,
    ipAddress: io.ipAddress,
  };

  return result;
};

const ioService = new IoService();
export default ioService;
