import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import measurementsSeriesService from '../../../service/api/MeasurementsSeriesService';
import { Dialog } from 'primereact/dialog';
import { Trans, useTranslation } from 'react-i18next';
import { MeasurementsSeries } from '../../../types/MeasurementsSeries';
import { useHistory } from 'react-router-dom';
import WriteOnlyButton from '../../controls/WriteOnlyButton';
import { format, parseISO } from 'date-fns';

type Props = {
  open?: boolean;
  loading: boolean;
  measurementsSeries: MeasurementsSeries[];
  dataChanged: VoidFunction;
};

export const MeasurementsSeriesManagement = ({ measurementsSeries, dataChanged, open = false }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [globalFilter, setGlobalFilter] = useState('');

  const [measurementsSeriesToDelete, setMeasurementsSeriesToDelete] = useState<MeasurementsSeries>();
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);

  const [measurementsSeriesToClose, setMeasurementsSeriesToClose] = useState<MeasurementsSeries>();
  const [closeDialogVisible, setCloseDialogVisible] = useState(false);

  const measurementsSeriesTableHeader = (
    <div className="table-header">
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder={t('common.search')}
        />
      </span>
    </div>
  );

  const confirmDeleteMeasurementsSeries = (measurementsSeries: MeasurementsSeries) => {
    setMeasurementsSeriesToDelete(measurementsSeries);
    setDeleteDialogVisible(true);
  };

  const confirmCloseMeasurementsSeries = (measurementsSeries: MeasurementsSeries) => {
    setMeasurementsSeriesToClose(measurementsSeries);
    setCloseDialogVisible(true);
  };

  const closeMeasurementsSeries = () => {
    measurementsSeriesService.close(measurementsSeriesToClose.id).then(() => {
      dataChanged();
    });

    hideCloseDialog();
  };

  const hideCloseDialog = () => {
    setCloseDialogVisible(false);
  };

  const deleteMeasurementsSeries = () => {
    measurementsSeriesService.delete(measurementsSeriesToDelete.id).then(() => {
      dataChanged();
    });

    setDeleteDialogVisible(false);
  };
  const hideDeleteDialog = () => {
    setDeleteDialogVisible(false);
  };

  const deleteDialogFooter = (
    <>
      <Button label={t('common.no')} icon="pi pi-times" className="p-button-text" onClick={() => hideDeleteDialog()} />
      <Button
        label={t('common.yes')}
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => deleteMeasurementsSeries()}
      />
    </>
  );

  const closeDialogFooter = (
    <>
      <Button label={t('common.no')} icon="pi pi-times" className="p-button-text" onClick={() => hideCloseDialog()} />
      <Button
        label={t('common.yes')}
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => closeMeasurementsSeries()}
      />
    </>
  );

  const trimmedBodyTemplate = (data: MeasurementsSeries, props: ColumnBodyOptions) => {
    const content = data[props.field];
    let trimmedText = content?.slice(0, 30);
    if (trimmedText.length < content.length) trimmedText += '...';
    return trimmedText;
  };

  const bodyTemplate = (data: MeasurementsSeries, props: ColumnBodyOptions) => {
    return <>{data[props.field]}</>;
  };

  const dateTemplate = (data: MeasurementsSeries, props: ColumnBodyOptions): JSX.Element => (
    <>{format(parseISO(data[props.field]), t('formats.full_format'))}</>
  );

  const actionTemplate = (data: MeasurementsSeries) => (
    <span>
      <Button
        type="button"
        label={t('common.preview')}
        className="p-button-info mr-2"
        onClick={() => {
          history.push(`/measurements-series/${data.id}/details`);
        }}
      ></Button>
      {open && (
        <WriteOnlyButton
          type="button"
          label={t('measurementsSeries.close')}
          className="p-button-warning mr-2"
          onClick={() => {
            confirmCloseMeasurementsSeries(data);
          }}
        />
      )}
      <WriteOnlyButton
        type="button"
        label={t('common.delete')}
        className="p-button-danger"
        onClick={() => {
          confirmDeleteMeasurementsSeries(data);
        }}
      />
    </span>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <DataTable
            value={measurementsSeries}
            paginator
            className="p-datatable-customers"
            rows={10}
            dataKey="id"
            rowHover
            globalFilter={globalFilter}
            emptyMessage={t('measurementsSeries.empty_message')}
            header={measurementsSeriesTableHeader}
          >
            <Column field="name" header={t('common.name')} sortable body={bodyTemplate}></Column>
            <Column
              field="autoWeighingConfigTag"
              header={t('measurementsSeries.auto_weighing_config_tag')}
              body={bodyTemplate}
            ></Column>
            <Column
              field="description"
              header={t('measurementsSeries.description')}
              body={trimmedBodyTemplate}
            ></Column>
            <Column field="createdAt" header={t('measurementsSeries.created_at')} sortable body={dateTemplate}></Column>
            {!open && (
              <Column field="closedAt" header={t('measurementsSeries.closed_at')} sortable body={dateTemplate}></Column>
            )}
            <Column
              headerStyle={{ width: '20rem' }}
              header={t('common.actions')}
              align="center"
              body={actionTemplate}
            ></Column>
          </DataTable>

          <Dialog
            visible={deleteDialogVisible}
            className="p-fluid"
            header={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                {t('common.confirm')}
              </span>
            }
            modal
            footer={deleteDialogFooter}
            onHide={hideDeleteDialog}
            breakpoints={{ '896px': '90vw' }}
            style={{ minWidth: '450px' }}
          >
            <div className="confirmation-content">
              {measurementsSeriesToDelete && (
                <span>
                  <Trans
                    i18nKey="common.confirm_delete_message"
                    values={{ name: measurementsSeriesToDelete.name }}
                    components={[<strong />]}
                  />
                </span>
              )}
            </div>
          </Dialog>

          <Dialog
            visible={closeDialogVisible}
            className="p-fluid"
            header={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                {t('common.confirm')}
              </span>
            }
            modal
            footer={closeDialogFooter}
            onHide={hideCloseDialog}
            breakpoints={{ '896px': '90vw' }}
            style={{ minWidth: '450px' }}
          >
            <div className="confirmation-content">
              {measurementsSeriesToClose && (
                <span>
                  <Trans
                    i18nKey="measurementsSeries.confirm_close_message"
                    values={{ name: measurementsSeriesToClose.name ?? t('measurementsSeries.series_accusative') }}
                  />
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};
