import { useState, useEffect } from 'react';

import { Column } from 'primereact/column';

import { DataTable, DataTablePFSEvent } from 'primereact/datatable';
import { parseISO, format, toDate } from 'date-fns';

import { ReceptionsToolbar } from './ReceptionsToolbar';
import { PaginatorState, SubTableOrder, PaginationParams } from '../../types/general-types';
import { useTranslation } from 'react-i18next';
import { handleDateFilters, handleSort } from '../_shared/functions';
import receptionsService from '../../service/api/ReceptionsService';

import getPaginatorTemplate from '../_shared/getPaginatorTemplate';
import { ReceptionsReceivesData } from './types';

const INIT_PAGINATION_PARAMETERS = {
  order: {
    isAscending: false,
    orderColumn: 'date',
  },
  page: {
    index: 1,
    size: 10,
  },
};

const INIT_PAGINATOR_STATE = {
  currentPage: 1,
  totalPages: 1,
  rows: 10,
};

const INIT_SUBTABLE_ORDER = {
  isAscending: false,
  orderColumn: 'hour',
};

export const ReceptionsRecords = (): JSX.Element => {
  const [records, setRecords] = useState<any>([]);
  const [expandedRows, setExpandedRows] = useState<any>(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [paginatorState, setPaginatorState] = useState<PaginatorState>(INIT_PAGINATOR_STATE);
  const [paginationParams, setPaginationParams] = useState<PaginationParams>(INIT_PAGINATION_PARAMETERS);
  const [subTableOrder, setSubTableOrder] = useState<SubTableOrder>(INIT_SUBTABLE_ORDER);

  const { t } = useTranslation();

  useEffect(() => {
    if (
      paginatorState.currentPage !== paginationParams.page.index ||
      paginatorState.rows !== paginationParams.page.size
    ) {
      const params = {
        ...paginationParams,
        page: {
          index: paginatorState.currentPage,
          size: paginatorState.rows,
        },
      };
      setPaginationParams(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationParams, paginatorState]);

  useEffect(() => {
    const dateFilters = handleDateFilters(startDate, endDate);

    receptionsService.getAllReceptions(paginationParams, dateFilters).then((receptions) => {
      setRecords(receptions.data);
      setPaginatorState({
        ...paginatorState,
        totalPages: receptions.totalPageNumber,
        currentPage: paginatorState.currentPage > receptions.totalPageNumber ? 1 : paginatorState.currentPage,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, paginationParams]);

  const handleSubTableSort = (event: DataTablePFSEvent): void => {
    setSubTableOrder({
      isAscending: event.sortOrder === 1 ? true : false,
      orderColumn: event.sortField,
    });
  };

  const dateTemplate = (rowData: ReceptionsReceivesData): JSX.Element => (
    <>{format(toDate(parseISO(rowData.date)), t('formats.date_format'))}</>
  );

  const rowExpansionTemplate = (singleReceive: ReceptionsReceivesData): JSX.Element => {
    return (
      <div className="orders-subtable">
        <DataTable
          value={singleReceive.details}
          responsiveLayout="scroll"
          onSort={handleSubTableSort}
          sortField={subTableOrder.orderColumn}
          sortOrder={subTableOrder.isAscending ? 1 : -1}
        >
          <Column field="hour" header={t('common.hour')} sortable></Column>
          <Column field="scaleId" header={t('receptions_records.scale_id')}></Column>
          <Column field="userId" header={t('receptions_records.supplier_id')}></Column>
          <Column field="userName" header={t('receptions_records.supplier_name')}></Column>
          <Column field="litres" header={t('receptions_records.kilograms')}></Column>
        </DataTable>
      </div>
    );
  };

  return (
    <div className="grid table-demo">
      <div className="col-12">
        <div className="card">
          <h5>{t('receptions_records.suppliers_list')}</h5>

          <ReceptionsToolbar
            t={t}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />

          <DataTable
            value={records}
            expandedRows={expandedRows}
            onRowToggle={(e: any) => setExpandedRows(e.data)}
            responsiveLayout="scroll"
            rowExpansionTemplate={rowExpansionTemplate}
            dataKey="id"
            paginator
            rows={paginatorState.rows}
            rowHover
            paginatorTemplate={getPaginatorTemplate(paginatorState, null, null, setPaginatorState)}
            emptyMessage={t('receptions_records.empty_message')}
            onSort={(event) =>
              handleSort(event, paginatorState, paginationParams, setPaginatorState, setPaginationParams)
            }
            sortField={paginationParams.order.orderColumn}
            sortOrder={paginationParams.order.isAscending ? 1 : -1}
          >
            <Column expander style={{ width: '3em' }} />
            <Column field="date" header={t('common.date')} sortable body={dateTemplate} />
            <Column field="totalReceptionsNumber" header={t('receptions_records.receptions_number')} />
            <Column field="totalLitres" header={t('receptions_records.kilograms')} />
          </DataTable>
        </div>
      </div>
    </div>
  );
};
