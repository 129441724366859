import { FormikErrors, FormikHelpers, useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import React, { useEffect, useState } from 'react';
import licensesService from '../../service/api/LicensesService';
import FormErrorMessage from '../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../_shared/FormErrorMessageScroller';
import { useTranslation } from 'react-i18next';
import { License, LicenseType } from '../../types/License';
import validator from 'validator';

import { Customer } from '../../types/Customer';

import WriteOnlyButton from '../controls/WriteOnlyButton';

interface FormValuesProps extends License {}

const INIT_FORM_STATE: FormValuesProps = {
  id: null,
  customerId: null,
  type: null,
  email: '',
  deviceKey: '',
  version: '',
  expirationDate: null,
  limits: {
    usersNumber: null,
    scalesNumber: null,
  },
  comment: '',
};

type Props = {
  dialogVisible: boolean;
  closeAddEditModal: VoidFunction;
  license: License;

  availableCustomers: Customer[];

  showAddToast: (name: string) => void;
  showSaveToast: (name: string) => void;
};

function LicenseDialog({
  dialogVisible,
  closeAddEditModal,
  license,

  availableCustomers,

  showAddToast,
  showSaveToast,
}: Props) {
  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);

  const hideDialog = () => {
    formik.resetForm({ values: INIT_FORM_STATE });
    closeAddEditModal();
  };

  const customers = availableCustomers?.map((r) => {
    return { label: r.name, value: r.id };
  });

  const { t } = useTranslation();

  const licenseTypes = [
    { label: t('licenses.enums.OFFLINE'), value: LicenseType.OFFLINE },
    { label: t('licenses.enums.ONLINE'), value: LicenseType.ONLINE },
  ];

  useEffect(() => {
    if (dialogVisible && license) {
      setInitFormValues({
        id: license.id,
        customerId: license.customerId,
        type: license.type,
        email: license.email,
        deviceKey: license.deviceKey,
        version: license.version,
        expirationDate: license.expirationDate,
        limits: {
          usersNumber: license.limits.usersNumber,
          scalesNumber: license.limits.scalesNumber,
        },
        comment: license.comment,
      });
    } else {
      setInitFormValues(INIT_FORM_STATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogVisible]);

  const formik = useFormik({
    initialValues: initFormValues,
    validate: (data: FormValuesProps) => {
      let errors: FormikErrors<FormValuesProps> = {};

      if (!data.customerId) {
        errors.customerId = t('licenses.error_customer_required');
      }
      if (!data.type?.trim()) {
        errors.type = t('licenses.error_type_required');
      }
      if (data.deviceKey) {
        if (data.deviceKey.length !== 64) {
          errors.deviceKey = t('licenses.error_device_key_length');
        }
        if (!validator.isHexadecimal(data.deviceKey)) {
          errors.deviceKey = t('licenses.error_device_key_hexadecimal');
        }
      }
      return errors;
    },
    onSubmit: async (formData: FormValuesProps, helpers: FormikHelpers<FormValuesProps>) => {
      try {
        if (!formData.id) {
          await licensesService.create(formData);

          showAddToast(formData.email);
        } else {
          await licensesService.edit(formData, formData.id);

          showSaveToast(formData.email);
        }

        hideDialog();
      } catch (error: any) {
        if (error.response.status === 409) {
          const field = error.response.data.field || 'name';
          helpers.setFieldError(field, t(`licenses.error_${field}_already_taken`));
        }
      }
      helpers.setSubmitting(false);
    },
    enableReinitialize: true,
  });

  const DialogFooter = (
    <>
      <Button
        type="reset"
        label={t('common.cancel')}
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <WriteOnlyButton
        type="submit"
        label={t('common.save')}
        icon="pi pi-check"
        className="p-button-text"
        onClick={formik.submitForm}
      />
    </>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik}>
      <Dialog
        visible={dialogVisible}
        header={t('licenses.license_details')}
        modal
        className="p-fluid"
        footer={DialogFooter}
        onHide={hideDialog}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
        style={{ width: '40vw' }}
      >
        <form>
          <div className="col-10">
            <div className="field">
              <label htmlFor="customerId">{t('licenses.customer')}</label>
              <Dropdown
                id="customerId"
                value={formik.values.customerId}
                onChange={formik.handleChange}
                options={customers}
                placeholder={t('common.select')}
                emptyMessage={t('licenses.customers_empty_message')}
                className={formik.touched.customerId && formik.errors.customerId && 'p-invalid'}
              />
              <FormErrorMessage fieldName="customerId" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="type">{t('licenses.type')}</label>

              <Dropdown
                id="type"
                value={formik.values.type}
                onChange={formik.handleChange}
                options={licenseTypes}
                className={formik.touched.type && formik.errors.type && 'p-invalid'}
              />

              <FormErrorMessage fieldName="type" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="email">{t('licenses.email')}</label>
              <InputText
                id="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                className={formik.touched.email && formik.errors.email && 'p-invalid'}
              />
              <FormErrorMessage fieldName="email" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="deviceKey">{t('licenses.device_key')}</label>
              <InputText
                id="deviceKey"
                value={formik.values.deviceKey}
                onChange={formik.handleChange}
                className={formik.touched.deviceKey && formik.errors.deviceKey && 'p-invalid'}
              />
              <FormErrorMessage fieldName="deviceKey" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="version">{t('licenses.version')}</label>
              <InputText
                id="version"
                value={formik.values.version}
                onChange={formik.handleChange}
                className={formik.touched.version && formik.errors.version && 'p-invalid'}
              />
              <FormErrorMessage fieldName="version" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="expirationDate">{t('licenses.expiration_date')}</label>
              <Calendar
                id="expirationDate"
                dateFormat="dd.mm.yy"
                value={formik.values.expirationDate}
                onChange={formik.handleChange}
                showTime
                showSeconds
                className={formik.touched.expirationDate && formik.errors.expirationDate && 'p-invalid'}
              />
              <FormErrorMessage fieldName="expirationDate" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="limits.usersNumber">{t('licenses.users_number')}</label>
              <InputNumber
                id="limits.usersNumber"
                value={formik.values.limits.usersNumber}
                onValueChange={formik.handleChange}
                className={formik.touched.limits?.usersNumber && formik.errors.limits?.usersNumber && 'p-invalid'}
              />
              <FormErrorMessage fieldName="usersNumber" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="limits.scalesNumber">{t('licenses.scales_number')}</label>
              <InputNumber
                id="limits.scalesNumber"
                value={formik.values.limits.scalesNumber}
                onValueChange={formik.handleChange}
                className={formik.touched.limits?.scalesNumber && formik.errors.limits?.scalesNumber && 'p-invalid'}
              />
              <FormErrorMessage fieldName="scalesNumber" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="comment">{t('licenses.comment')}</label>
              <InputTextarea
                autoResize
                id="comment"
                value={formik.values.comment}
                onChange={formik.handleChange}
                className={formik.touched.comment && formik.errors.comment && 'p-invalid'}
              />
              <FormErrorMessage fieldName="comment" formikInstance={formik} />
            </div>
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
}

export default LicenseDialog;
