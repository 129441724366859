import { useContext, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { AxiosConfigContainer } from '../components/AxiosConfig';
import AuthContext from '../store/auth-context';
import { useTranslation } from 'react-i18next';
import { RegisterPanel } from '../components/Register/Register';
import { ConfirmationPanel } from '../components/Register/Confirmation/Confirmation';
import { PasswordRecoveryPanel } from '../components/PasswordRecovery/PasswordRecovery';
import { ConfirmPasswordRecoveryPanel } from '../components/PasswordRecovery/Confirmation/Confirmation';
import { LoginPanel } from '../components/LoginPanel/LoginPanel';
import usersService from '../service/api/UsersService';
import { SETTINGS } from '../enums/settings.enum';

const Login = (): JSX.Element => {
  const authCtx = useContext(AuthContext);

  const history = useHistory<RouteComponentProps['history']>();
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const [otherError, setOtherError] = useState(false);
  const [loginDialogVisible, setLoginDialogVisible] = useState(true);
  const [registerDialogVisible, setRegisterDialogVisible] = useState(false);
  const [firstTimeLoginDialogVisible, setFirstTimeLoginDialogVisible] = useState(false);
  const [confirmRegisterDialogVisible, setConfirmDialogVisible] = useState(false);
  const [passwordRecoveryDialogVisible, setPasswordRecoveryDialogVisible] = useState(false);
  const [confirmPasswordRecoveryDialogVisible, setConfirmPasswordRecoveryDialogVisible] = useState(false);
  const [registerEmail, setRegisterEmail] = useState('');
  const [registerPassword, setRegisterPassword] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [loginEmail, setLoginEmail] = useState('');
  const [recoveryPasswordEmail, setRecoveryPasswordEmail] = useState('');

  const { t } = useTranslation();

  const login = async (email: string, password: string) => {
    try {
      const { idToken, accessToken } = await usersService.login(email, password);
      authCtx.login(idToken, accessToken);

      history.push(SETTINGS.DEFAULT_PAGE);
    } catch (error: any) {
      let code = error?.response?.data?.error;

      if (!code && error.response.status === 401) {
        code = 'UserNotFoundException';
      }

      switch (code) {
        case 'UserNotConfirmedException':
          setRegisterEmail(email);
          setLoginDialogVisible(false);
          setConfirmDialogVisible(true);

          await usersService.resendConfirmationCode({ email: email });
          break;

        case 'NewPasswordRequiredException':
          setLoginDialogVisible(false);
          setFirstTimeLoginDialogVisible(true);
          break;

        case 'UserNotFoundException':
        case 'NotAuthorizedException':
        case 'Unauthorized':
          setInvalidCredentials(true);
          setOtherError(false);
          break;

        default:
          setOtherError(true);
          break;
      }
    }
  };

  const openRegisterPanel = (): void => {
    setLoginDialogVisible(false);
    setRegisterDialogVisible(true);
  };

  const openConfirmationDialog = (email: string, password: string) => {
    setRegisterEmail(email);
    setRegisterPassword(password);
    setRegisterDialogVisible(false);
    setConfirmDialogVisible(true);
  };

  const openPasswordRecoveryPanel = () => {
    setLoginDialogVisible(false);
    setPasswordRecoveryDialogVisible(true);
  };

  const loginRegisteredUser = async () => {
    setConfirmDialogVisible(false);

    const password = registerPassword ? registerPassword : loginPassword;

    return login(registerEmail, password);
  };

  const openConfirmPasswordRecoveryDialog = (email: string) => {
    setRecoveryPasswordEmail(email);

    setPasswordRecoveryDialogVisible(false);
    setConfirmPasswordRecoveryDialogVisible(true);
  };

  const closeConfirmPasswordRecoveryDialog = () => {
    setConfirmPasswordRecoveryDialogVisible(false);
    setLoginDialogVisible(true);
  };

  const hideRegisterFirstTimeDialog = (): void => {
    setLoginDialogVisible(true);
    setFirstTimeLoginDialogVisible(false);
  };

  const hideRegisterDialog = (): void => {
    setLoginDialogVisible(true);
    setRegisterDialogVisible(false);
  };

  const hidePasswordRecoveryDialog = (): void => {
    setLoginDialogVisible(true);
    setPasswordRecoveryDialogVisible(false);
  };

  const dialogHeader = (subHeader: string) => {
    return (
      <div className="grid">
        <div className="col-12">
          <div className="flex flex-column md:flex-row align-items-center p-4">
            <div className="flex-1 text-center">
              <img className="login-logo" src="assets/layout/images/logo-black.png" alt="" width="220" />
            </div>
          </div>
          <p className="font-aller text-center mt-1 mb-2">{subHeader}</p>
        </div>
      </div>
    );
  };

  return (
    <>
      <AxiosConfigContainer />

      <div className="login-body">
        {loginDialogVisible && (
          <LoginPanel
            t={t}
            openPasswordRecoveryPanel={openPasswordRecoveryPanel}
            invalidCredentials={invalidCredentials}
            otherError={otherError}
            openRegisterPanel={openRegisterPanel}
            dialogHeader={dialogHeader}
            setInvalidCredentials={setInvalidCredentials}
            setLoginPassword={setLoginPassword}
            setLoginEmail={setLoginEmail}
            onSuccess={login}
          />
        )}

        {firstTimeLoginDialogVisible && (
          <RegisterPanel
            t={t}
            isVisible={firstTimeLoginDialogVisible}
            onSubmit={async (formData) => {
              const { idToken, accessToken } = await usersService.initAccount(formData);
              authCtx.login(idToken, accessToken);
              history.push(SETTINGS.DEFAULT_PAGE);
            }}
            dialogHeader={() => dialogHeader(t('register_dialog.set_password_header'))}
            oldPassword={loginPassword}
            email={loginEmail}
            onHide={hideRegisterFirstTimeDialog}
          />
        )}

        {registerDialogVisible && (
          <RegisterPanel
            t={t}
            isVisible={registerDialogVisible}
            onSubmit={async (formData) => {
              await usersService.signUp(formData);
              openConfirmationDialog(formData.email, formData.password);
            }}
            dialogHeader={dialogHeader}
            onHide={hideRegisterDialog}
          />
        )}
        {confirmRegisterDialogVisible && (
          <ConfirmationPanel
            t={t}
            isVisible={confirmRegisterDialogVisible}
            registerEmail={registerEmail}
            onSuccess={loginRegisteredUser}
            dialogHeader={dialogHeader}
          />
        )}

        {passwordRecoveryDialogVisible && (
          <PasswordRecoveryPanel
            t={t}
            isVisible={passwordRecoveryDialogVisible}
            dialogHeader={dialogHeader}
            onSuccess={openConfirmPasswordRecoveryDialog}
            onHide={hidePasswordRecoveryDialog}
          />
        )}
        {confirmPasswordRecoveryDialogVisible && (
          <ConfirmPasswordRecoveryPanel
            t={t}
            isVisible={confirmPasswordRecoveryDialogVisible}
            recoveryPasswordEmail={recoveryPasswordEmail}
            onSuccess={closeConfirmPasswordRecoveryDialog}
            dialogHeader={dialogHeader}
          />
        )}
      </div>
    </>
  );
};

export default Login;
