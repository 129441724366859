import { RegisterDialog } from './RegisterDialog';
import { RegisterPanelInitialValues, RegisterPanelProps } from './types';

const initialValues: RegisterPanelInitialValues = {
  email: '',
  password: '',
  oldPassword: '',
  confirmedPassword: '',
  registeredToNewsletter: false,
  termsAccepted: false,
};

export const RegisterPanel = (props: RegisterPanelProps) => {
  const { t, isVisible, dialogHeader, onSubmit, onHide, oldPassword = '', email = '' } = props;

  return (
    <>
      <RegisterDialog
        t={t}
        isVisible={isVisible}
        initialValues={{ ...initialValues, oldPassword, email }}
        dialogHeader={dialogHeader}
        onSubmit={onSubmit}
        onHide={onHide}
      />
    </>
  );
};
