import { classNames } from 'primereact/utils';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Avatar } from 'primereact/avatar';
import { useTranslation } from 'react-i18next';
import AuthContext from './store/auth-context';
import { useContext } from 'react';
import { RootState } from './store';
import { SETTINGS } from './enums/settings.enum';
import { Dropdown } from 'primereact/dropdown';
import { modeContext } from './services/ModeContext';

const AppTopbar = (props: any) => {
  const history = useHistory();
  const { t } = useTranslation();
  const authCtx = useContext(AuthContext);
  const modeCtx = modeContext();

  const loggedUserContext = useSelector((state: RootState) => state.user.context);

  const onLogoutClicked = () => {
    authCtx.logout();
  };

  const topbarMenuClassName = classNames('topbar-menu fadeInDown', {
    'topbar-menu-visible': props.topbarMenuActive,
  });

  const activeTopbarItemClassName = (name: string) => {
    return name === props.activeTopbarItem ? 'active-topmenuitem' : null;
  };

  const getAvatarLabel = () => {
    const initials = [loggedUserContext?.firstName?.charAt(0), loggedUserContext?.lastName?.charAt(0)].join('');
    if (initials) {
      return initials.toUpperCase();
    }

    return loggedUserContext?.email?.charAt(0)?.toUpperCase() ?? 'U';
  };

  const allCustomers = props.allCustomers?.map((customer) => {
    return { label: customer.name, value: customer.id };
  });

  const handleCustomerChange = async (e) => {
    await props.onCustomerChange(e.value);
  };

  return (
    <div className="layout-topbar">
      <button className="layout-topbar-logo p-link" onClick={() => history.push(SETTINGS.DEFAULT_PAGE)}>
        <img id="layout-topbar-logo" src="assets/layout/images/logo-white.png" alt="application logo" />
      </button>

      <button className="layout-menu-button p-link" onClick={props.onMenuButtonClick}>
        <i className="pi pi-bars"></i>
      </button>
      {allCustomers && allCustomers.length > 1 && (
        <Dropdown
          id="divisionId"
          value={props.customerId}
          onChange={handleCustomerChange}
          options={allCustomers}
          placeholder={t('common.select')}
          className={`col-4`}
        />
      )}

      <button id="topbar-menu-button" className="p-link" onClick={props.onTopbarMenuButtonClick}>
        <i className="pi pi-ellipsis-v"></i>
      </button>

      <ul className={topbarMenuClassName}>
        <li className={activeTopbarItemClassName('profile')}>
          <button className="p-link" onClick={(e) => props.onTopbarItemClick(e, 'profile')}>
            <Avatar label={getAvatarLabel()} size="large" shape="circle"></Avatar>
          </button>

          <ul className="fadeInDown">
            <li role="menuitem">
              <button className="p-link" onClick={props.onShowUserDetailsClick}>
                <i className="pi pi-fw pi-user"></i>
                <span>{t('users.user_details')}</span>
              </button>
            </li>
            <li role="menuitem">
              <button className="p-link" onClick={props.onChangePasswordClick}>
                <i className="pi pi-fw pi-undo"></i>
                <span>{t('users.change_password')}</span>
              </button>
            </li>
            {modeCtx.isOffline && (
              <li role="menuitem">
                <button className="p-link" onClick={props.onUpdateLicenseClick}>
                  <i className="pi pi-fw pi-key"></i>
                  <span>{t('verifyLicenseKeyPage.menuHeader')}</span>
                </button>
              </li>
            )}
            <li role="menuitem">
              <button className="p-link" onClick={onLogoutClicked}>
                <i className="pi pi-fw pi-sign-out"></i>
                <span>{t('users.log_out')}</span>
              </button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default AppTopbar;
