import axios from 'axios';
import { MeasurementsSeries } from '../../types/MeasurementsSeries';

type Filter = {
  scaleId: string;
  open: boolean;
}

class MeasurementsSeriesService {
  async getAll(filter: Filter): Promise<MeasurementsSeries[]> {
    const result = await axios.get(`/measurements-series`, { params: filter });
    return result.data;
  }
  async get(id: string): Promise<MeasurementsSeries> {
    const result = await axios.get(`/measurements-series/${id}`);
    return result.data;
  }
  async delete(id: string): Promise<void> {
    return axios.delete(`/measurements-series/${id}`);
  }

  async close(id: string): Promise<void> {
    return axios.post(`/measurements-series/${id}/close`);
  }
  async create(postData: MeasurementsSeries): Promise<void> {
    return axios.post(`/measurements-series`, mapToRequestBody(postData));
  }
  async edit(postData: MeasurementsSeries, id: string): Promise<void> {
    return axios.put(`/measurements-series/${id}`, mapToRequestBody(postData));
  }

  async getReport(
      id: string,
      fileFormat: string,
  ) {
    let dataToSend: any = {
      fileFormat,
      isAscending: true,
    };

    return axios.post(`/measurements-series/${id}/report`, dataToSend, {
      responseType: 'arraybuffer',
    });
  }
}

const mapToRequestBody = (measurementsSeries: MeasurementsSeries): any => {
  return {
    id: measurementsSeries.id,
    name: measurementsSeries.name,
    description: measurementsSeries.description,
    scaleId: measurementsSeries.scaleId,
    autoWeighingConfigId: measurementsSeries.autoWeighingConfigId,
  };
};

const measurementsSeriesService = new MeasurementsSeriesService();
export default measurementsSeriesService;
