import axios from 'axios';
import { Permission } from '../../types/Permission';

class PermissionsService {
  async getAll(): Promise<Permission[]> {
    const result = await axios.get('/permissions');
    return result.data;
  }
}

let permissionsService = new PermissionsService();
export default permissionsService;
