import { ConfirmPasswordRecoveryInitialValues, ConfirmPasswordRecoveryProps } from '../types';
import { ConfirmPasswordRecoveryDialog } from './ConfirmationDialog';

export const ConfirmPasswordRecoveryPanel = (props: ConfirmPasswordRecoveryProps) => {
  const { t, isVisible, recoveryPasswordEmail, onSuccess, dialogHeader } = props;

  const initialValues: ConfirmPasswordRecoveryInitialValues = {
    email: recoveryPasswordEmail,
    confirmationCode: '',
    password: '',
    confirmedPassword: '',
  };

  return (
    <>
      <ConfirmPasswordRecoveryDialog
        t={t}
        initialValues={initialValues}
        isVisible={isVisible}
        onSuccess={onSuccess}
        dialogHeader={dialogHeader}
      />
    </>
  );
};
