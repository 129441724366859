import { useFormik } from 'formik';
import { TFunction } from 'i18next';
import usersService from '../../../service/api/UsersService';

export const GetConfirmationForm = (initialValues: any, onSuccess: any, t: TFunction) => {
  const form = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validate: (data: any) => {
      let errors: any = {};

      if (!data.confirmationCode) {
        errors.confirmationCode = t('confirmation_dialog.confirmation_code_required');
      }

      return errors;
    },
    onSubmit: async (formData: any, helpers: any) => {
      try {
        await usersService.confirmSignUp(formData);

        await onSuccess();
      } catch (error: any) {
        let code = error?.response?.data?.error;

        switch (code) {
          case 'UserNotFoundException':
            helpers.setFieldError('confirmationCode', t('confirmation_dialog.user_not_found_error'));
            break;
          case 'ExpiredCodeException':
            helpers.setFieldError('confirmationCode', t('confirmation_dialog.confirmation_code_expired'));
            break;
          case 'CodeMismatchException':
            helpers.setFieldError('confirmationCode', t('confirmation_dialog.confirmation_code_error'));
            break;
        }
      }
    },
  });

  return form;
};
