import { Dialog } from 'primereact/dialog';

import { Button } from 'primereact/button';
import FormErrorMessage from '../_shared/FormErrorMessage';
import { InputText } from 'primereact/inputtext';
import { GetPasswordRecoveryForm } from './validation';
import { PasswordRecoveryDialogProps } from './types';

export const PasswordRecoveryDialog = (props: PasswordRecoveryDialogProps) => {
  const { t, initialValues, isVisible, dialogHeader, onSuccess, onHide } = props;

  const passwordRecoveryForm = GetPasswordRecoveryForm(initialValues, onSuccess, t);

  const passwordRecoveryDialogFooter = () => {
    return (
      <>
        <Button
          type="submit"
          label={t('common.continue')}
          className="p-button-success mt-3 mr-2 mb-2 pr-4 pt-2 pb-2 pl-4"
          onClick={passwordRecoveryForm.submitForm}
        />
      </>
    );
  };

  return (
    <Dialog
      visible={isVisible}
      style={{ width: '600px' }}
      header={dialogHeader(t('password_recovery_dialog.header'))}
      modal
      className="p-fluid"
      footer={passwordRecoveryDialogFooter}
      onHide={onHide}
    >
      <div className="col-12 md:col-11 mb-3 ml-auto mr-auto">
        <p>{t('password_recovery_dialog.description')}</p>
        <div className="field">
          <label htmlFor="email">
            <b>{t('register_dialog.email')}</b>
          </label>
          <span className="p-input-icon-left">
            <i className="pi pi-user" />
            <InputText
              id="email"
              value={passwordRecoveryForm.values.email}
              onChange={passwordRecoveryForm.handleChange}
              type="email"
              required
              autoFocus
            />
          </span>
          <FormErrorMessage fieldName="email" formikInstance={passwordRecoveryForm} />
        </div>
      </div>
    </Dialog>
  );
};
