import { parseISO, format, toDate, subDays, addDays } from 'date-fns';
import { DataTablePFSEvent } from 'primereact/datatable';
import { SETTINGS } from '../../enums/settings.enum';
import { PaginationParams, PaginatorState } from '../../types/general-types';

export const handleStartDate = (
  passedDate: Date,
  endDate: string,
  setStartDate: React.Dispatch<React.SetStateAction<string>>,
  setEndDate: React.Dispatch<React.SetStateAction<string>>,
  paginatorState: PaginatorState = null,
  setPaginatorState: React.Dispatch<React.SetStateAction<PaginatorState>> = null
): void => {
  if (!passedDate) return;

  if (endDate && parseISO(endDate) < passedDate) {
    setEndDate(format(toDate(addDays(passedDate, 1)), SETTINGS.API_DATE_FORMAT));
  }

  setStartDate(format(toDate(passedDate), SETTINGS.API_DATE_FORMAT));

  if (paginatorState && setPaginatorState) {
    setPaginatorState({ ...paginatorState, currentPage: 1 });
  }
};

export const handleEndDate = (
  passedDate: Date,
  startDate: string,
  setStartDate: React.Dispatch<React.SetStateAction<string>>,
  setEndDate: React.Dispatch<React.SetStateAction<string>>,
  paginatorState: PaginatorState = null,
  setPaginatorState: React.Dispatch<React.SetStateAction<PaginatorState>> = null
): void => {
  if (!passedDate) return;

  if (startDate && parseISO(startDate) > passedDate) {
    setStartDate(format(toDate(subDays(passedDate, 1)), SETTINGS.API_DATE_FORMAT));
  }
  setEndDate(format(toDate(passedDate), SETTINGS.API_DATE_FORMAT));

  if (paginatorState && setPaginatorState) {
    setPaginatorState({ ...paginatorState, currentPage: 1 });
  }
};

export const dateFilter = (
  numberOfDays: number,
  setStartDate: React.Dispatch<React.SetStateAction<string>>,
  setEndDate: React.Dispatch<React.SetStateAction<string>>,
  paginatorState: PaginatorState = null,
  setPaginatorState: React.Dispatch<React.SetStateAction<PaginatorState>> = null
) => {
  const date = format(new Date(), SETTINGS.API_DATE_FORMAT);
  const resultDate = format(subDays(new Date(), numberOfDays), SETTINGS.API_DATE_FORMAT);

  setStartDate(resultDate);
  setEndDate(date);

  if (paginatorState && setPaginatorState) {
    setPaginatorState({ ...paginatorState, currentPage: 1 });
  }
};

export const handleSort = (
  event: DataTablePFSEvent,
  paginatorState: PaginatorState,
  paginationParams: PaginationParams,
  setPaginatorState: React.Dispatch<React.SetStateAction<PaginatorState>>,
  setPaginationParams: React.Dispatch<React.SetStateAction<PaginationParams>>
): void => {
  setPaginatorState({ ...paginatorState, currentPage: 1 });

  setPaginationParams({
    ...paginationParams,
    order: {
      isAscending: event.sortOrder === 1 ? true : false,
      orderColumn: event.sortField ? event.sortField : '',
    },
  });
};

export const handleDateFilters = (startDate: string, endDate: string) => {
  let dateFilterObject: any = {};

  if (startDate) dateFilterObject.startDate = startDate;

  if (endDate) dateFilterObject.endDate = format(toDate(addDays(parseISO(endDate), 1)), SETTINGS.API_DATE_FORMAT);

  return dateFilterObject;
};

export const getDomain = (urlPath: string): string => {
  let domain = new URL(urlPath);

  return domain?.hostname;
};
