import { createSlice } from '@reduxjs/toolkit';
import { differenceInMilliseconds, parseISO } from 'date-fns';

const scaleIndicationsSlice = createSlice({
  name: 'scaleIndications',
  initialState: {
    indications: {},
  },
  reducers: {
    updateScaleIndication(state, value) {
      const id = value.payload.scaleId;
      const existingIndication = state.indications[id];
      if (existingIndication) {
        if (existingIndication.measurementTime > value.payload.measurementTime) {
          // we already have a newer indication
          return;
        }

        if (
          differenceInMilliseconds(
            parseISO(value.payload.measurementTime),
            parseISO(existingIndication.measurementTime)
          ) < 100
        ) {
          // we already have fresh enough indication
          // we cannot update the indication too often because react cannot handle too many re-renders
          return;
        }
      }
      state.indications[id] = value.payload;
    },
    setScaleIndications(state, value) {
      state.indications = value.payload;
    },
  },
});

export const scaleIndicationsActions = scaleIndicationsSlice.actions;

export default scaleIndicationsSlice;
