import axios from 'axios';

class VersionService {
  async getVersion(): Promise<string> {
    const result = await axios.get('/version');
    return result.data?.version;
  }
}

let versionService = new VersionService();
export default versionService;
