import { Dispatch } from '@reduxjs/toolkit';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { userActions } from './user-slice';
import { SETTINGS } from '../enums/settings.enum';

const AuthContext = React.createContext({
  login: (_idToken: string, _accessToken: string) => {},
  logout: () => {},
});

const retrieveStoredLoggedUser = (dispatch: Dispatch) => {
  const storedAccessToken = localStorage.getItem('access_token');
  const storedIdToken = localStorage.getItem('id_token');

  storedAccessToken &&
    dispatch(
      userActions.setLoggedUser({
        accessToken: storedAccessToken,
        idToken: storedIdToken,
      })
    );
};

//TODO: AuthContextProvider props types
export const AuthContextProvider = (props: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  retrieveStoredLoggedUser(dispatch);

  const logoutHandler = useCallback(() => {
    dispatch(userActions.setLoggedUser(null));

    localStorage.removeItem('id_token');
    localStorage.removeItem('access_token');

    history.push('/login');
  }, [dispatch, history]);

  const loginHandler = (idToken: string, accessToken: string) => {
    dispatch(
      userActions.setLoggedUser({
        idToken,
        accessToken,
      })
    );

    localStorage.setItem('id_token', idToken);
    localStorage.setItem('access_token', accessToken);

    history.push(SETTINGS.DEFAULT_PAGE);
  };

  const contextValue = {
    login: loginHandler,
    logout: logoutHandler,
  };

  return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>;
};

export default AuthContext;
