import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { InputText } from 'primereact/inputtext';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Measurement } from '../../types/Measurement';
import animalsService from '../../service/api/AnimalsService';
import weighingsService from '../../service/api/WeighingsService';
import { SETTINGS } from '../../enums/settings.enum';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import WriteOnlyButton from '../controls/WriteOnlyButton';

type Props = {
  animalId: string;
};

export const AnimalMeasurements = ({ animalId }: Props) => {
  const toast = useRef<any>();
  const [globalFilter, setGlobalFilter] = useState('');
  const [loading, setLoading] = useState(true);
  const [measurementToDelete, setMeasurementToDelete] = useState<Measurement>();
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);

  const [measurements, setMeasurements] = useState<Measurement[]>([]);

  const { t } = useTranslation();

  const loadData = async () => {
    setLoading(true);
    setMeasurements(await animalsService.getMeasurements(animalId));
    setLoading(false);
  };
  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animalId]);

  const tableHeader = (
    <div className="table-header">
      {t('weighings_list.header')}
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder={t('common.search')}
        />
      </span>
    </div>
  );

  const bodyTemplate = (data: Measurement, props: ColumnBodyOptions) => {
    return <>{data[props.field]}</>;
  };

  const actionTemplate = (data: Measurement) => (
    <span>
      <WriteOnlyButton
        type="button"
        icon="pi pi-trash"
        tooltip={t('common.delete')}
        tooltipOptions={{ position: 'top' }}
        className="p-button-danger mr-2"
        onClick={() => {
          confirmDeleteMeasurement(data);
        }}
      ></WriteOnlyButton>
    </span>
  );

  const showDeleteToast = () => {
    toast.current.show({
      severity: 'success',
      summary: `${t('weighings_list.indication')}`,
      detail: t('weighings_list.delete_info'),
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  const confirmDeleteMeasurement = (measurement: Measurement) => {
    setMeasurementToDelete(measurement);
    setDeleteDialogVisible(true);
  };

  const hideDeleteDialog = () => {
    setDeleteDialogVisible(false);
  };

  const deleteMeasurement = async () => {
    await weighingsService.deleteScaleIndication(measurementToDelete.id);
    setDeleteDialogVisible(false);
    showDeleteToast();
    await loadData();
  };

  const deleteDialogFooter = (
    <>
      <Button label={t('common.no')} icon="pi pi-times" className="p-button-text" onClick={() => hideDeleteDialog()} />
      <Button
        label={t('common.yes')}
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => deleteMeasurement()}
      />
    </>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <DataTable
            value={measurements}
            paginator
            className="p-datatable-customers"
            rows={10}
            dataKey="id"
            rowHover
            globalFilter={globalFilter}
            emptyMessage={t('weighings_list.empty_message')}
            loading={loading}
            header={tableHeader}
          >
            <Column field="value" header={t('weighings_list.indication')} sortable body={bodyTemplate}></Column>
            <Column field="measurementTimeLocal" header={t('common.date')} sortable body={bodyTemplate}></Column>
            <Column
              headerStyle={{ width: '8rem' }}
              header={t('common.actions')}
              align="center"
              body={actionTemplate}
            ></Column>
          </DataTable>
          <Dialog
            visible={deleteDialogVisible}
            className="p-fluid"
            header={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                {t('common.confirm')}
              </span>
            }
            modal
            footer={deleteDialogFooter}
            onHide={hideDeleteDialog}
            breakpoints={{ '896px': '90vw' }}
            style={{ minWidth: '450px' }}
          >
            <div className="confirmation-content">
              {measurementToDelete && (
                <span>
                  <Trans
                    i18nKey="weighings_list.confirm_delete_message"
                    values={{ date: measurementToDelete.measurementTimeLocal }}
                    components={[<strong />]}
                  />
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>
      <Toast ref={toast} />
    </div>
  );
};
