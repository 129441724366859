import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import groupTemplatesService from '../../service/api/GroupTemplatesService';

import groupsService from '../../service/api/GroupsService';
import { Dialog } from 'primereact/dialog';
import GroupDialog from './GroupDialog';
import { Trans, useTranslation } from 'react-i18next';
import { Group } from '../../types/Group';

import { Toast } from 'primereact/toast';
import { SETTINGS } from '../../enums/settings.enum';

import { GroupTemplate } from '../../types/GroupTemplate';
import { Io } from '../../types/Io';
import ioService from '../../service/api/IoService';
import WriteOnlyButton from '../controls/WriteOnlyButton';

export const GroupManagement = () => {
  const toast = useRef<any>();

  const [globalFilter, setGlobalFilter] = useState('');
  const [loading, setLoading] = useState(true);

  const [groups, setGroups] = useState<Group[]>(null);
  const [groupToDelete, setGroupToDelete] = useState<Group>();

  const [groupTemplates, setGroupTemplates] = useState<GroupTemplate[]>();

  const [io, setIo] = useState<Io[]>();

  const [selectedRowToEdit, setSelectedRowToEdit] = useState<Group>(null);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [addEditDialogVisible, setAddEditDialogVisible] = useState(false);
  const { t } = useTranslation();

  const connectorTypeNameMap = {
    INPUT: t('groupTemplates.input'),
    OUTPUT: t('groupTemplates.output'),
  };

  const showSaveToast = (summary: string) => {
    toast.current.show({
      severity: 'success',
      summary: summary,
      detail: t('groups.toast_saved'),
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  const showAddToast = (summary: string) => {
    toast.current.show({
      severity: 'success',
      summary: summary,
      detail: t('groups.toast_added'),
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  const showDeleteToast = (summary: string) => {
    toast.current.show({
      severity: 'info',
      summary: summary,
      detail: t('groups.toast_deleted'),
      life: SETTINGS.TOAST_LIFETIME,
    });
  };

  const loadData = () => {
    setLoading(true);
    Promise.all([groupsService.getAll(), groupTemplatesService.getAll(), ioService.getAll()]).then((response) => {
      setGroupTemplates(response[1]);
      const groupTemplatesDict = Object.assign({}, ...response[1].map((r) => ({ [r.id]: r.name })));
      const groupTemplateConnectorsDict = response[1]
        .flatMap((r) => r.connectors)
        .reduce((dict, connector) => {
          dict[connector.id] = connector;
          return dict;
        }, {});

      setIo(response[2]);
      const ioDict = Object.assign({}, ...response[2].map((r) => ({ [r.id]: r.name })));

      const _groups = response[0];
      _groups.forEach((_x) => {
        _x.groupTemplateName = groupTemplatesDict[_x.groupTemplateId];
        _x.connectors.forEach((_c) => {
          _c.groupName = _x.name;
          _c.ioName = ioDict[_c.ioId];
          _c.type = groupTemplateConnectorsDict[_c.groupTemplateConnectorId]?.type;
          _c.typeName = connectorTypeNameMap[_c.type];
          _c.name = groupTemplateConnectorsDict[_c.groupTemplateConnectorId]?.name;
        });
      });
      setGroups(_groups);
      setLoading(false);
    });
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const groupsTableHeader = (
    <div className="table-header">
      {t('groups.header')}
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder={t('common.search')}
        />
        <Button
          type="button"
          icon="pi pi-plus"
          label={t('common.add')}
          className="p-button ml-2"
          onClick={() => {
            addGroup();
          }}
        ></Button>
      </span>
    </div>
  );

  const closeAddEditModal = () => {
    setAddEditDialogVisible(false);
    loadData();
  };

  const confirmDeleteGroup = (group: Group) => {
    setGroupToDelete(group);
    setDeleteDialogVisible(true);
  };

  const deleteGroup = () => {
    setLoading(true);
    groupsService.delete(groupToDelete.id).then(() => {
      loadData();

      showDeleteToast(groupToDelete.name);
    });

    setDeleteDialogVisible(false);
  };
  const hideDeleteDialog = () => {
    setDeleteDialogVisible(false);
  };

  const editGroup = (_group: Group) => {
    setSelectedRowToEdit(_group);
    setAddEditDialogVisible(true);
  };

  const addGroup = () => {
    setSelectedRowToEdit(null);
    setAddEditDialogVisible(true);
  };

  const deleteDialogFooter = (
    <>
      <Button label={t('common.no')} icon="pi pi-times" className="p-button-text" onClick={() => hideDeleteDialog()} />
      <Button label={t('common.yes')} icon="pi pi-check" className="p-button-text" onClick={() => deleteGroup()} />
    </>
  );

  const bodyTemplate = (data: Group, props: ColumnBodyOptions) => {
    return <>{data[props.field]}</>;
  };

  const actionTemplate = (data: Group) => (
    <span>
      <Button
        type="button"
        icon={data.isUsed ? 'pi pi-eye' : 'pi pi-pencil'}
        tooltip={data.isUsed ? t('common.preview') : t('common.edit')}
        tooltipOptions={{ position: 'top' }}
        className="p-button-success mr-2"
        onClick={() => {
          editGroup(data);
        }}
      ></Button>
      <WriteOnlyButton
        type="button"
        icon="pi pi-trash"
        disabled={data.isUsed}
        tooltip={t('common.delete')}
        tooltipOptions={{ position: 'top' }}
        className="p-button-success"
        onClick={() => {
          confirmDeleteGroup(data);
        }}
      />
    </span>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <DataTable
            value={groups}
            paginator
            className="p-datatable-customers"
            rows={10}
            dataKey="id"
            rowHover
            globalFilter={globalFilter}
            emptyMessage={t('groups.empty_message')}
            loading={loading}
            header={groupsTableHeader}
          >
            <Column field="name" header={t('common.name')} sortable body={bodyTemplate}></Column>
            <Column field="groupTemplateName" header={t('groups.group_template')} sortable body={bodyTemplate}></Column>
            <Column
              headerStyle={{ width: '8rem' }}
              header={t('common.actions')}
              align="center"
              body={actionTemplate}
            ></Column>
          </DataTable>
          <GroupDialog
            dialogVisible={addEditDialogVisible}
            closeAddEditModal={closeAddEditModal}
            group={selectedRowToEdit}
            availableGroupTemplates={groupTemplates}
            availableIo={io}
            showAddToast={showAddToast}
            showSaveToast={showSaveToast}
          />
          <Dialog
            visible={deleteDialogVisible}
            className="p-fluid"
            header={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                {t('common.confirm')}
              </span>
            }
            modal
            footer={deleteDialogFooter}
            onHide={hideDeleteDialog}
            breakpoints={{ '896px': '90vw' }}
            style={{ minWidth: '450px' }}
          >
            <div className="confirmation-content">
              {groupToDelete && (
                <span>
                  <Trans
                    i18nKey="common.confirm_delete_message"
                    values={{ name: groupToDelete.name }}
                    components={[<strong />]}
                  />
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>

      <Toast ref={toast} />
    </div>
  );
};
