import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import animalsService from '../../service/api/AnimalsService';
import { Animal } from '../../types/Animal';
import AnimalDetailsSection from './AnimalDetailsSection';
import { AnimalMeasurements } from './AnimalMeasurements';

export const AnimalDetailsPage = () => {
  const { id } = useParams<any>();
  const [animal, setAnimal] = useState<Animal>(null);

  const loadData = async (id: string) => {
    setAnimal(await animalsService.get(id));
  };

  useEffect(() => {
    loadData(id);
  }, [id]);

  return (
    <>
      {animal && <AnimalDetailsSection animal={animal}></AnimalDetailsSection>}
      <AnimalMeasurements animalId={id}></AnimalMeasurements>
    </>
  );
};
