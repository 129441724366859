import { Dropdown } from 'primereact/dropdown';
import { PaginatorState } from '../../types/general-types';

const getPaginatorTemplate = (
  paginatorState: PaginatorState,
  onPageChange: (_: number) => void,
  onRowsPerPageChange: (_: number) => void,
  setPaginatorState: React.Dispatch<React.SetStateAction<PaginatorState>>
): any => {
  const handleChangePage = (pageNumber: number) => {
    if (onPageChange) {
      onPageChange(pageNumber);
    }

    if (setPaginatorState) {
      setPaginatorState({ ...paginatorState, currentPage: pageNumber });
    }
  };

  const handleChangeRowsPerPage = (e: any) => {
    if (onRowsPerPageChange) {
      onRowsPerPageChange(e.value);
    }

    if (setPaginatorState) {
      setPaginatorState({ ...paginatorState, rows: e.value, currentPage: 1 });
    }
  };

  return {
    layout: 'FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown',
    RowsPerPageDropdown: () => {
      const dropdownOptions = [5, 10, 25, 50];

      return (
        <Dropdown
          value={paginatorState.rows}
          options={dropdownOptions}
          onChange={handleChangeRowsPerPage}
          appendTo={document.body}
        />
      );
    },
    FirstPageLink: () => (
      <button
        type="button"
        className={`p-paginator-first p-paginator-element p-link ${
          paginatorState.currentPage === 1 ? 'p-disabled' : ''
        }`}
        onClick={() => handleChangePage(1)}
        disabled={paginatorState.currentPage === 1}
      >
        <span className="p-paginator-icon pi pi-angle-double-left" />
      </button>
    ),
    PrevPageLink: () => (
      <button
        type="button"
        className={`p-paginator-prev p-paginator-element p-link ${
          paginatorState.currentPage === 1 ? 'p-disabled' : ''
        }`}
        onClick={() => handleChangePage(paginatorState.currentPage - 1)}
        disabled={paginatorState.currentPage === 1}
      >
        <span className="p-paginator-icon pi pi-angle-left" />
      </button>
    ),
    NextPageLink: () => (
      <button
        type="button"
        className={`p-paginator-next p-paginator-element p-link ${
          paginatorState.currentPage === paginatorState.totalPages || paginatorState.totalPages === 0
            ? 'p-disabled'
            : ''
        }`}
        onClick={() => handleChangePage(paginatorState.currentPage + 1)}
        disabled={paginatorState.currentPage === paginatorState.totalPages || paginatorState.totalPages === 0}
      >
        <span className="p-paginator-icon pi pi-angle-right" />
      </button>
    ),
    LastPageLink: () => (
      <button
        type="button"
        className={`p-paginator-last p-paginator-element p-link ${
          paginatorState.currentPage === paginatorState.totalPages ? 'p-disabled' : ''
        }`}
        onClick={() => handleChangePage(paginatorState.totalPages)}
        disabled={paginatorState.currentPage === paginatorState.totalPages}
      >
        <span className="p-paginator-icon pi pi-angle-double-right" />
      </button>
    ),
    CurrentPageReport: () => {
      return (
        <span className="p-paginator-pages">
          {[...Array(paginatorState.totalPages)].map((_, index) => {
            if (index + 1 > paginatorState.currentPage + 2) {
              return null;
            }
            if (index - 1 < paginatorState.currentPage - 4) {
              return null;
            }

            return (
              <button
                key={index}
                type="button"
                className={`p-paginator-page p-paginator-element p-link ${
                  index + 1 === paginatorState.currentPage ? 'p-highlight' : ''
                }`}
                onClick={() => handleChangePage(index + 1)}
              >
                {index + 1}
              </button>
            );
          })}
        </span>
      );
    },
  };
};

export default getPaginatorTemplate;
