import { useFormik } from 'formik';
import { TFunction } from 'i18next';
import validator from 'validator';
import { RegisterPanelInitialValues } from './types';

export const GetRegisterForm = (
  initialValues: RegisterPanelInitialValues,
  registeredOnDomain: string,
  onSubmit: any,
  t: TFunction
) => {
  const form = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validate: async (data: any) => {
      let errors: any = {};

      if (!data.email) {
        errors.email = t('register_dialog.empty_email_error');
      }

      if (!validator.isEmail(data.email)) {
        errors.email = t('register_dialog.incorrect_email_error');
      }

      if (!data.password) {
        errors.password = t('register_dialog.empty_password_error');
      }

      if (
        data.password &&
        !validator.isStrongPassword(data.password, {
          minLength: 8,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
        errors.password = t('register_dialog.password_requirements_not_fulfilled_error');
      }

      if (!data.confirmedPassword) {
        errors.confirmedPassword = t('register_dialog.confirmed_password_required');
      }

      if (data.password !== data.confirmedPassword) {
        errors.confirmedPassword = t('register_dialog.confirmed_password_error');
      }

      if (!data.termsAccepted) {
        errors.termsAccepted = t('register_dialog.terms_required');
      }

      return errors;
    },
    onSubmit: async (formData: any, helpers: any) => {
      try {
        await onSubmit({ ...formData, registeredOnDomain });
      } catch (error: any) {
        const errorCode = error.response.data.statusCode;

        switch (errorCode) {
          case 409:
            helpers.setFieldError('email', t('register_dialog.email_already_exists_error'));
            break;
        }
      }
    },
  });

  return form;
};
