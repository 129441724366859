import React from 'react';
import { Toolbar } from 'primereact/toolbar';

import { v4 as uuidv4 } from 'uuid';

import { Button } from 'primereact/button';
import autoWeighingConfigsService from '../../../service/api/AutoWeighingConfigsService';
import { INTERVAL_UNITS } from '../../../enums/auto-weighings.enum';
import { toDate, format, parseISO, isValid } from 'date-fns';
import { AutoWeighingConfig, AutoWeighingsToolbarProps } from './types';
import { useMediaQuery } from 'usehooks-ts';
import generalStyle from '../../../assets/custom-styles/general.module.scss';
import { SETTINGS } from '../../../enums/settings.enum';
import WriteOnlyButton from '../../controls/WriteOnlyButton';

const addIndex = (configs: AutoWeighingConfig[]) => {
  const indexes: number[] = [];

  if (!configs.length) {
    return 1;
  }

  for (const config of configs) {
    indexes.push(config.index);
  }

  return Math.max(...indexes) + 1;
};

export const AutoWeighingsToolbar = (props: AutoWeighingsToolbarProps) => {
  const { t, scaleId, allConfigs, setAllConfigs, setInitialConfigs, setUnsavedChanges, showSaveToast } = props;

  const isTabletView = useMediaQuery(SETTINGS.TABLET_VIEW);
  const isMobileView = useMediaQuery(SETTINGS.MOBILE_VIEW);

  const updateConfigs = async () => {
    const configsToUpdate = allConfigs.map(({ index, ...config }: any) => {
      const date = isValid(parseISO(config.startDate)) ? parseISO(config.startDate) : config.startDate;

      const formattedDate = format(toDate(date), 'yyyy-MM-dd HH:mm:ss.S');
      config.startDate = formattedDate;

      return config;
    });

    await autoWeighingConfigsService.updateConfig(scaleId, configsToUpdate);

    showSaveToast();
    setInitialConfigs(allConfigs);
    setUnsavedChanges(false);
  };

  const addNewConfig = () => {
    const emptyConfig: any = {
      id: uuidv4(),
      scaleId,
      intervalUnit: INTERVAL_UNITS.MONTH,
      startDate: new Date(),
      tag: '',
      isActivated: false,
      index: addIndex(allConfigs),
    };

    setAllConfigs([...allConfigs, emptyConfig]);
    setUnsavedChanges(true);
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          id="button"
          onClick={addNewConfig}
          label={t('common.add')}
          icon="pi pi-plus"
          iconPos="right"
          className={`p-button-raised p-button-primary mr-4 mt-2 col-11 md:col-12  pl-3 pr-3 pt-2 pb-2`}
        />
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <WriteOnlyButton
          id="button"
          onClick={updateConfigs}
          label={t('common.save')}
          className={`p-button-raised p-button-primary mr-4 mt-2 col-11 md:col-12  pl-3 pr-3 pt-2 pb-2`}
        />
      </React.Fragment>
    );
  };

  const MobileToolbar = () => {
    const LeftToolbar = () => leftToolbarTemplate();
    const RightToolbar = () => rightToolbarTemplate();

    return (
      <React.Fragment>
        <div className={generalStyle.mobileToolbar}>
          <LeftToolbar />
          <RightToolbar />
        </div>
      </React.Fragment>
    );
  };

  return isTabletView || isMobileView ? (
    <MobileToolbar />
  ) : (
    <Toolbar className="mb-4 grid" left={leftToolbarTemplate} right={rightToolbarTemplate} />
  );
};
