import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import scalesService from '../service/api/ScalesService';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface comparisonFunctionProps {
  location: {
    pathname: string;
  };
}

interface MeasurementsParams {
  scaleId: string;
}

const Measurements = (): JSX.Element => {
  const [measurements, setMeasurements] = useState(['some']);
  const [globalFilter, setGlobalFilter] = useState<string | null>(null);
  const dt = useRef(null);
  const { scaleId } = useParams<MeasurementsParams>();
  const { t } = useTranslation();

  // TODO:: validate that scaleId is a GUID
  useEffect(() => {
    scalesService.getWeighingsForScale(scaleId, { startDate: '', endDate: '' }, { tags: [], noTag: false
    }).then((data) => setMeasurements(data));
  }, [scaleId]);

  const bodyTemplate = (data: any, props: any): JSX.Element => {
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {data[props.field]}
      </>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{t('measurements.header')}</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter((e.target as HTMLInputElement).value)}
          placeholder={t('common.search')}
        />
      </span>
    </div>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <DataTable
            ref={dt}
            value={measurements}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={t('measurements.table_footer')}
            globalFilter={globalFilter}
            emptyMessage={t('measurements.empty_message')}
            header={header}
            responsiveLayout="scroll"
          >
            <Column
              field="id"
              header="Id"
              sortable
              body={bodyTemplate}
              headerStyle={{ width: '14%', minWidth: '10rem' }}
            ></Column>
            <Column
              field="value"
              header={t('measurements.value')}
              sortable
              body={bodyTemplate}
              headerStyle={{ width: '14%', minWidth: '10rem' }}
            ></Column>
            <Column
              field="measurementTime"
              header={t('measurements.time')}
              sortable
              body={bodyTemplate}
              headerStyle={{ width: '14%', minWidth: '10rem' }}
            ></Column>
          </DataTable>
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps: comparisonFunctionProps, nextProps: comparisonFunctionProps): boolean {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Measurements, comparisonFn);
