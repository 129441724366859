export const ioModels = [
  {
    id: 'E1210',
    name: 'ioLogik E1210',
    di: 16,
    do: 0,
    dio: 0,
  },
  {
    id: 'E1210-T',
    name: 'ioLogik E1210-T',
    di: 16,
    do: 0,
    dio: 0,
  },
  {
    id: 'E1211',
    name: 'ioLogik E1211',
    di: 0,
    do: 16,
    dio: 0,
  },
  {
    id: 'E1211-T',
    name: 'ioLogik E1211-T',
    di: 0,
    do: 16,
    dio: 0,
  },
  {
    id: 'E1212',
    name: 'ioLogik E1212',
    di: 8,
    do: 0,
    dio: 8,
  },
  {
    id: 'E1212-T',
    name: 'ioLogik E1212-T',
    di: 8,
    do: 0,
    dio: 8,
  },
  {
    id: 'E1213',
    name: 'ioLogik E1213',
    di: 8,
    do: 4,
    dio: 4,
  },
  {
    id: 'E1213-T',
    name: 'ioLogik E1213-T',
    di: 8,
    do: 4,
    dio: 4,
  },
  {
    id: 'E1214',
    name: 'ioLogik E1214',
    di: 6,
    dio: 0,
    do: 0,
  },
  {
    id: 'E1214-T',
    name: 'ioLogik E1214-T',
    di: 6,
    dio: 0,
    do: 0,
  },
  {
    id: 'E1242',
    name: 'ioLogik E1242',
    di: 4,
    dio: 0,
    do: 4,
  },
  {
    id: 'E1242-T',
    name: 'ioLogik E1242-T',
    di: 4,
    dio: 0,
    do: 4,
  },
] as const;

export type IoModel = (typeof ioModels)[number]['id'];

export interface Io {
  id: string;

  name: string;

  description?: string;

  hostId: string;

  model: IoModel;

  modelName?: string;

  ipAddress: string;

  hostName?: string;

  isUsed: boolean;
}
