import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnBodyOptions } from 'primereact/column';

import rawMaterialsService from '../../service/api/RawMaterialsService';
import { useTranslation } from 'react-i18next';
import { RawMaterial } from '../../types/RawMaterial';

import { SETTINGS } from '../../enums/settings.enum';
import { addDays, format } from 'date-fns';
import { Calendar, CalendarChangeParams } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { REPORT_TYPES } from '../../enums/report-types.enum';
import { getFileFromBuffer } from '../_shared/getFile';

export const RawMaterialReportPage = () => {
  const [loading, setLoading] = useState(true);

  const [rawMaterials, setRawMaterials] = useState<RawMaterial[]>(null);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const { t } = useTranslation();

  const [pdfLoading, setPdfLoading] = useState(false);
  const [excelLoading, setExcelLoading] = useState(false);
  const op = useRef(null);
  const toggle = (event: any) => {
    op.current.toggle(event);
  };

  const loadData = async () => {
    setLoading(true);
    const materials = await rawMaterialsService.getUsageSummary({
      startDate: startDate ? format(startDate, SETTINGS.API_DATE_FORMAT) : null,
      endDate: endDate ? format(addDays(endDate, 1), SETTINGS.API_DATE_FORMAT) : null,
    });
    setRawMaterials(materials);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const downloadReport = (reportFormat: string) => {
    let report: any = {
      download: true,
    };

    if (reportFormat === REPORT_TYPES.EXCEL) {
      report.format = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      report.extension = '.xlsx';
    } else {
      report.format = 'application/pdf';
      report.extension = '.pdf';
    }

    exportReport(report, reportFormat);
  };

  const exportReport = (options: any, reportFormat: string) => {
    const filters = {
      startDate: startDate ? format(startDate, SETTINGS.API_DATE_FORMAT) : null,
      endDate: endDate ? format(addDays(endDate, 1), SETTINGS.API_DATE_FORMAT) : null,
    };

    rawMaterialsService.getReport(options.format, filters).then((report: any) => {
      reportFormat === REPORT_TYPES.EXCEL ? setExcelLoading(false) : setPdfLoading(false);

      !options.download && getFileFromBuffer(report.data, options.format);
      options.download && getFileFromBuffer(report.data, options.format, prepareFileName());
    });
  };

  const prepareFileName = (): string => {
    const formattedDate = format(Date.now(), 'yyyyMMdd_HHmmss');
    return `${t('rawMaterials.report_name')}__${formattedDate}`;
  };

  const rawMaterialsTableHeader = (
    <div className="table-header">
      {t('rawMaterials.header')}
      <span className="p-input-icon-left">
        <Calendar
          placeholder={t('common.date_from')}
          locale="pl"
          inputId="startDate"
          value={startDate}
          className={`mr-1`}
          onChange={(element: CalendarChangeParams) => {
            setStartDate(element?.value as Date);
          }}
          showIcon
        />

        <Calendar
          placeholder={t('common.date_to')}
          locale="pl"
          inputId="endDate"
          value={endDate}
          className={`mr-1`}
          onChange={(element: CalendarChangeParams) => {
            setEndDate(element?.value as Date);
          }}
          showIcon
        />

        <React.Fragment>
          <Button
            type="button"
            label={t('common.export')}
            onClick={toggle}
            className={`mr-1 mt-2 md:pr-3 md:pl-3`}
            icon="pi pi-download"
            iconPos="right"
            disabled={!rawMaterials?.length}
          />
          <OverlayPanel ref={op} appendTo={document.body}>
            <Button
              label="PDF"
              className="p-button-plain p-button-text"
              icon="pi pi-file-pdf"
              onClick={() => {
                downloadReport(REPORT_TYPES.PDF);
                setPdfLoading(true);
              }}
              disabled={excelLoading}
              loading={pdfLoading}
            />
            <br />
            <Button
              label="Excel"
              className="p-button-plain p-button-text export-button-menu-width"
              icon="pi pi-file-excel"
              onClick={() => {
                downloadReport(REPORT_TYPES.EXCEL);
                setExcelLoading(true);
              }}
              disabled={pdfLoading}
              loading={excelLoading}
            />
          </OverlayPanel>
        </React.Fragment>
      </span>
    </div>
  );

  const bodyTemplate = (data: RawMaterial, props: ColumnBodyOptions) => {
    return <>{data[props.field]}</>;
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <DataTable
            value={rawMaterials}
            paginator
            className="p-datatable-customers"
            rows={10}
            dataKey="id"
            rowHover
            emptyMessage={t('rawMaterials.empty_message')}
            loading={loading}
            header={rawMaterialsTableHeader}
          >
            <Column field="name" header={t('common.name')} sortable body={bodyTemplate}></Column>
            <Column field="usage" header={t('rawMaterials.usage') + ' (kg)'} sortable body={bodyTemplate}></Column>
          </DataTable>
        </div>
      </div>
    </div>
  );
};
