import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import divisionsService from '../../../service/api/DivisionsService';
import FormErrorMessage from '../../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../../_shared/FormErrorMessageScroller';
import { useTranslation } from 'react-i18next';
import { Chips } from 'primereact/chips';
import WriteOnlyButton from '../../controls/WriteOnlyButton';

const INIT_FORM_STATE = {
  id: null,
  name: '',
  users: [],
  members: [],
};

function DivisionDialog({ dialogVisible, closeAddEditModal, division }) {
  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);

  const hideDialog = () => {
    formik.resetForm({ values: INIT_FORM_STATE });
    closeAddEditModal();
  };

  const { t } = useTranslation();

  useEffect(() => {
    if (dialogVisible && division) {
      setInitFormValues({
        id: division.id,
        name: division.name,
        users: division.users,
        members: division.members,
      });
    } else {
      setInitFormValues(INIT_FORM_STATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogVisible]);

  const formik = useFormik({
    initialValues: initFormValues,
    validate: (data) => {
      let errors: any = {};

      if (!data.name?.trim()) {
        errors.name = t('common.error_name_required');
      }
      return errors;
    },
    onSubmit: async (formData: any, helpers: any) => {
      if (!formData.id) {
        divisionsService
          .create(formData)
          .then((_res: any) => {
            hideDialog();
          })
          .catch((error) => {
            if (error.response.status === 409) {
              helpers.setFieldError('name', t('divisions.error_name_already_taken'));
            }
          })
          .finally(() => helpers.setSubmitting(false));
      } else {
        divisionsService
          .edit(formData, formData.id)
          .then(() => {
            hideDialog();
          })
          .catch((error) => {
            if (error.response.status === 409) {
              helpers.setFieldError('name', t('divisions.error_name_already_taken'));
            }
            if (error.response.status === 404) {
              helpers.setFieldError('members', t('divisions.error_invalid_user') + ': ' + error.response.data.error);
            }
            console.log('error', error);
          })
          .finally(() => helpers.setSubmitting(false));
      }
    },
    enableReinitialize: true,
  });

  const DialogFooter = (
    <>
      <Button
        type="reset"
        label={t('common.cancel')}
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <WriteOnlyButton
        type="submit"
        label={t('common.save')}
        icon="pi pi-check"
        className="p-button-text"
        onClick={formik.submitForm}
      />
    </>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik}>
      <Dialog
        visible={dialogVisible}
        header={t('divisions.division_details')}
        modal
        className="p-fluid"
        footer={DialogFooter}
        onHide={hideDialog}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
        style={{ width: '40vw' }}
      >
        <form>
          <div className="col-10">
            <div className="field">
              <label htmlFor="name">{t('common.name')}</label>
              <InputText
                id="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                className={formik.touched.name && formik.errors.name && 'p-invalid'}
              />
              <FormErrorMessage fieldName="name" formikInstance={formik} />
            </div>
            <div className="field">
              <label htmlFor="members">{t('divisions.members')}</label>
              <Chips
                id="members"
                name="members"
                value={formik.values.members}
                separator=","
                className={formik.touched.name && formik.errors.name && 'p-invalid'}
                onChange={(e) => {
                  formik.setFieldValue('members', e.value);
                }}
              />
              <FormErrorMessage fieldName="members" formikInstance={formik} />
            </div>
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
}

export default DivisionDialog;
