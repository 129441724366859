export enum INTERVAL_UNITS {
  SECOND = 'SECOND',
  MINUTE = 'MINUTE',
  HOUR = 'HOUR',
  DAY = 'DAY',
  MONTH = 'MONTH',
}

export enum COS_INTERVAL_UNITS {
  MINUTE = 1,
  TWO_MINUTES = 2,
  FIVE_MINUTES = 5,
  TEN_MINUTES = 10,
}
