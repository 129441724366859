import { FormikErrors, FormikHelpers, useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import groupTemplatesService from '../../service/api/GroupTemplatesService';
import FormErrorMessage from '../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../_shared/FormErrorMessageScroller';
import { useTranslation } from 'react-i18next';
import { GroupTemplate } from '../../types/GroupTemplate';
import { Fieldset } from 'primereact/fieldset';
import { deepCopy } from '../_shared/objectHelpers';
import { GroupTemplateConnector } from '../../types/GroupTemplateConnector';
import WriteOnlyButton from '../controls/WriteOnlyButton';

interface FormValuesProps extends GroupTemplate {}

const EMPTY_CONNECTOR = { name: '', type: 'OUTPUT', function: 'STANDARD' };

const INIT_FORM_STATE: FormValuesProps = {
  id: null,
  name: '',
  type: 'CUSTOM',
  isUsed: false,
  connectors: [deepCopy(EMPTY_CONNECTOR)],
};

type Props = {
  dialogVisible: boolean;
  closeAddEditModal: VoidFunction;
  groupTemplate: GroupTemplate;

  showAddToast: (name: string) => void;
  showSaveToast: (name: string) => void;
};

function GroupTemplateDialog({
  dialogVisible,
  closeAddEditModal,
  groupTemplate,

  showAddToast,
  showSaveToast,
}: Props) {
  const { t } = useTranslation();

  const [initFormValues, setInitFormValues] = useState(deepCopy(INIT_FORM_STATE));

  const hideDialog = () => {
    formik.resetForm({ values: deepCopy(INIT_FORM_STATE) });
    closeAddEditModal();
  };

  const connectorTypes = [
    { label: t('groupTemplates.input'), value: 'INPUT' },
    { label: t('groupTemplates.output'), value: 'OUTPUT' },
  ];

  useEffect(() => {
    if (dialogVisible && groupTemplate) {
      setInitFormValues({
        id: groupTemplate.id,
        name: groupTemplate.name,
        type: groupTemplate.type,
        isUsed: groupTemplate.isUsed,
        connectors: groupTemplate.connectors,
      });
    } else {
      setInitFormValues(deepCopy(INIT_FORM_STATE));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogVisible]);

  const formik = useFormik({
    initialValues: initFormValues,
    validate: (data: FormValuesProps) => {
      let errors: FormikErrors<FormValuesProps> = {};

      if (!data.name?.trim()) {
        errors.name = t('common.error_name_required');
      }

      const connectorsErrors: FormikErrors<GroupTemplateConnector[]> = [];
      data.connectors.forEach((connector, index) => {
        if (!connector.name?.trim()) {
          connectorsErrors[index] = { name: t('common.error_name_required') };
          return;
        }
      });

      if (connectorsErrors.length) {
        errors.connectors = connectorsErrors;
      }

      return errors;
    },
    onSubmit: async (formData: FormValuesProps, helpers: FormikHelpers<FormValuesProps>) => {
      try {
        if (!formData.id) {
          await groupTemplatesService.create(formData);

          showAddToast(formData.name);
        } else {
          await groupTemplatesService.edit(formData, formData.id);

          showSaveToast(formData.name);
        }

        hideDialog();
      } catch (error: any) {
        if (error.response.status === 409) {
          const field = error.response.data.field || 'name';
          if (field.includes(':')) {
            const [f, index, cause] = field.split(':');
            helpers.setFieldError(`connectors[${index}].${f}`, t(`groupTemplates.error_connector_${f}_${cause}`));
          } else {
            helpers.setFieldError(field, t(`groupTemplates.error_${field}_already_taken`));
          }
        }
      }
      helpers.setSubmitting(false);
    },
    enableReinitialize: true,
  });

  const DialogFooter = (
    <>
      <Button
        type="reset"
        label={t('common.cancel')}
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <WriteOnlyButton
        type="submit"
        disabled={formik.values.isUsed}
        label={t('common.save')}
        icon="pi pi-check"
        className="p-button-text"
        onClick={formik.submitForm}
      />
    </>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik}>
      <Dialog
        visible={dialogVisible}
        header={t('groupTemplates.group_template_details')}
        modal
        className="p-fluid"
        footer={DialogFooter}
        onHide={hideDialog}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
        style={{ width: '60vw' }}
      >
        <form>
          <div className="col-10">
            <div className="field">
              <label htmlFor="name">{t('common.name')}</label>
              <InputText
                id="name"
                disabled={formik.values.isUsed}
                value={formik.values.name}
                onChange={formik.handleChange}
                className={formik.touched.name && formik.errors.name && 'p-invalid'}
              />
              <FormErrorMessage fieldName="name" formikInstance={formik} />
            </div>

            <Fieldset legend={t('groupTemplates.connectors')}>
              <div className="formgrid grid">
                {formik.values.connectors?.map((_, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="field col-8">
                        <label htmlFor={`connectors[${index}.name]`}>{t('common.name')}</label>
                        <InputText
                          id={`connectors[${index}.name]`}
                          disabled={formik.values.isUsed}
                          value={formik.values.connectors[index].name}
                          onChange={formik.handleChange}
                          className={
                            formik.touched?.connectors &&
                            formik.errors?.connectors &&
                            formik.touched.connectors[index]?.name &&
                            (formik.errors.connectors as FormikErrors<GroupTemplateConnector>[])[index]?.name &&
                            'p-invalid'
                          }
                        />
                        <FormErrorMessage
                          fieldName="connectors"
                          formikInstance={formik}
                          index={index}
                          nestedFieldName="name"
                        />
                      </div>
                      <div className="field col-3">
                        <label htmlFor={`connectors[${index}.type]`}>{t('common.type')}</label>
                        <Dropdown
                          id={`connectors[${index}.type]`}
                          disabled={formik.values.isUsed}
                          value={formik.values.connectors[index].type}
                          onChange={formik.handleChange}
                          options={connectorTypes}
                          className={
                            formik.touched?.connectors &&
                            formik.errors?.connectors &&
                            formik.touched.connectors[index]?.type &&
                            (formik.errors.connectors as FormikErrors<GroupTemplateConnector>[])[index]?.type &&
                            'p-invalid'
                          }
                        />
                        <FormErrorMessage
                          fieldName="connectors"
                          formikInstance={formik}
                          index={index}
                          nestedFieldName="type"
                        />
                      </div>
                      <div className="col-1 field" style={{ alignContent: 'end' }}>
                        <Button
                          type="button"
                          icon="pi pi-trash"
                          tooltip={t('common.delete')}
                          tooltipOptions={{ position: 'top' }}
                          className="p-button-danger"
                          disabled={formik.values.connectors?.length < 2 || formik.values.isUsed}
                          onClick={() => {
                            const connectors = formik.values.connectors;
                            connectors.splice(index, 1);
                            formik.setFieldValue('connectors', connectors);
                          }}
                        ></Button>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
              <Button
                type="button"
                icon="pi pi-plus"
                disabled={formik.values.isUsed}
                label={t('common.add')}
                className="p-button"
                onClick={() => {
                  const connectors = formik.values.connectors;
                  connectors.push(deepCopy(EMPTY_CONNECTOR));
                  formik.setFieldValue('connectors', connectors);
                }}
              ></Button>
            </Fieldset>
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
}

export default GroupTemplateDialog;
