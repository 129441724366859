import axios from 'axios';
import { Printer } from '../../types/Printer';

class PrintersService {
  async getAll(): Promise<Printer[]> {
    const result = await axios.get('/printers');
    return result.data;
  }
  async get(id: string): Promise<Printer> {
    const result = await axios.get(`/printers/${id}`);
    return result.data;
  }
  async delete(id: string): Promise<void> {
    return axios.delete(`/printers/${id}`);
  }
  async create(postData: Printer): Promise<void> {
    return axios.post(`/printers`, mapToRequestBody(postData));
  }
  async edit(postData: Printer, id: string): Promise<void> {
    return axios.put(`/printers/${id}`, mapToRequestBody(postData));
  }
}

const mapToRequestBody = (printer: Printer): any => {
  const result = {
    id: printer.id,
    name: printer.name,
    description: printer.description,
    hostId: printer.hostId,
    divisionId: printer.divisionId,
    connectionType: printer.connectionType,
    ipAddress: printer.ipAddress,
    port: printer.port,
    portPath: printer.portPath,
    databits: printer.databits,
    stopbits: printer.stopbits,
    parity: printer.parity,
    flowControl: printer.flowControl,
    baudRate: printer.baudRate,
    vendorId: printer.vendorId,
    productId: printer.productId,
  };

  return result;
};

const printersService = new PrintersService();
export default printersService;
