import axios from 'axios';
import { User } from '../../types/User';

class UsersService {
  async getLoggedUserContext() {
    return axios.get(`users/me/context`);
  }

  async signUp(user: any) {
    return axios.post(`/users/sign-up`, user);
  }

  async initAccount(user: any): Promise<any> {
    const response = await axios.post(`/users/init-account`, user);
    return response.data;
  }

  async confirmSignUp(data: any) {
    return axios.post(`/users/confirm-sign-up`, data);
  }

  async forgotPassword(data: any) {
    return axios.post(`/users/forgot-password`, data);
  }

  async changePassword(oldPassword: string, newPassword: string, accessToken) {
    return axios.put(
      '/users/me/changePassword',
      {
        oldPassword: oldPassword,
        newPassword: newPassword,
      },
      {
        headers: {
          'Access-Token': accessToken,
        },
      }
    );
  }

  async confirmForgotPassword(data: any) {
    return axios.post(`/users/confirm-forgot-password`, data);
  }

  async resendConfirmationCode(data: any) {
    return axios.post(`/users/resend-confirmation-code`, data);
  }

  async login(email: string, password: string): Promise<any> {
    const response = await axios.post(`/users/login`, { email, password });
    return response.data;
  }

  async edit(user: User): Promise<void> {
    return axios.put(`/users`, user);
  }

  async create(user: User): Promise<void> {
    return axios.post(`/users`, user);
  }

  async editById(user: User, id: string): Promise<void> {
    return axios.put(`/users/${id}`, user);
  }

  async getAll(): Promise<User[]> {
    const response = await axios.get('/users');
    return response.data;
  }

  async delete(id: string): Promise<void> {
    return axios.delete(`/users/${id}`);
  }
}

let usersService = new UsersService();
export default usersService;
