import axios, { AxiosRequestHeaders } from 'axios';
import { useCallback, useEffect, useRef } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

interface AxiosRequestConfig {
  baseURL?: string;
  headers?: {
    common?: AxiosRequestHeaders;
  };
}

export const AxiosConfigContainer = (): JSX.Element => {
  const history = useHistory<RouteComponentProps['history']>();
  const toast = useRef<any>();

  // todo: access token should be used here, see: https://oauth.net/id-tokens-vs-access-tokens/
  const loggedUserToken = useSelector((state: RootState) => state.user?.loggedUser?.idToken);

  const setUpInterceptors = useCallback(
    async (token: string) => {
      axios.interceptors.request.handlers = [];
      axios.interceptors.response.handlers = [];

      axios.interceptors.request.use(
        function (config: AxiosRequestConfig) {
          config.baseURL =
            process.env.REACT_APP_BACKEND_PORT && process.env.REACT_APP_BACKEND_PATH
              ? `${window.location.protocol}//${window.location.hostname}:${process.env.REACT_APP_BACKEND_PORT}/${process.env.REACT_APP_BACKEND_PATH}`
              : process.env.REACT_APP_BACKEND_URL;

          if (config?.headers?.common) {
            if (token) {
              config.headers.common.Authorization = `Bearer ${token}`;
            }

            return config;
          }
          return config;
        },
        function (error) {
          return Promise.reject(error);
        }
      );

      axios.interceptors.response.use(
        function (response) {
          return response;
        },
        function (error) {
          console.error(error);
          error.response && error.response.status === 401 && history.push('/login');
          error.response && error.response.status === 403 && history.push('/access');

          error.response && error.response.status.toString().charAt(0) === '5' && history.push('/error'); // HTTP 5XX
          !error.response && history.push('/error');
          return Promise.reject(error);
        }
      );
    },
    [history]
  );

  useEffect(() => {
    setUpInterceptors(loggedUserToken);
  }, [setUpInterceptors, loggedUserToken]);

  return <Toast ref={toast} />;
};
