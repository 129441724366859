import axios from 'axios';
import { RawMaterial } from '../../types/RawMaterial';
import { DateFilters } from '../../types/general-types';

class RawMaterialsService {
  async getAll(): Promise<RawMaterial[]> {
    const result = await axios.get('/raw-materials');
    return result.data;
  }
  async getUsageSummary(dateFilters: DateFilters): Promise<RawMaterial[]> {
    const result = await axios.post('/raw-materials/usage-summary', dateFilters);
    return result.data;
  }

  async getReport(fileFormat: string, dateFilters: DateFilters | null) {
    let dataToSend: any = {
      fileFormat,
      isAscending: false,
    };

    if (dateFilters) dataToSend.dateFilters = dateFilters;

    return axios.post(`/raw-materials/report`, dataToSend, {
      responseType: 'arraybuffer',
    });
  }

  async get(id: string): Promise<RawMaterial> {
    const result = await axios.get(`/raw-materials/${id}`);
    return result.data;
  }
  async delete(id: string): Promise<void> {
    return axios.delete(`/raw-materials/${id}`);
  }
  async create(postData: RawMaterial): Promise<void> {
    return axios.post(`/raw-materials`, mapToRequestBody(postData));
  }
  async edit(postData: RawMaterial, id: string): Promise<void> {
    return axios.put(`/raw-materials/${id}`, mapToRequestBody(postData));
  }
}

const mapToRequestBody = (rawMaterial: RawMaterial): any => {
  const result = {
    id: rawMaterial.id,
    name: rawMaterial.name,
    connectorIds: rawMaterial.connectorIds,
  };

  return result;
};

const rawMaterialsService = new RawMaterialsService();
export default rawMaterialsService;
