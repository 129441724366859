import axios from 'axios';

class ChangeOfStateConfigsService {
  async getConfigs(scaleId: string) {
    const result = await axios.get(`/scales/${scaleId}/change-of-state-configs`);

    return result.data;
  }

  async updateConfig(scaleId: string, configId: string, config: any) {
    return axios.post(`/scales/${scaleId}/change-of-state-configs/${configId}`, config);
  }
}

let changeOfStateConfigsService = new ChangeOfStateConfigsService();
export default changeOfStateConfigsService;
