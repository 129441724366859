import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';
import { Message } from 'primereact/message';
import { HostActivationDataDialogProps } from './types';

export const HostActivationDataDialog = (props: HostActivationDataDialogProps) => {
  const { dialogVisible, closeDialog, host, activationOtp } = props;

  const hideDialog = () => {
    closeDialog();
  };

  const { t } = useTranslation();

  return (
    <Dialog
      visible={dialogVisible}
      header={t('hosts.activation')}
      modal
      className="p-fluid"
      onHide={hideDialog}
      breakpoints={{ '1400px': '40vw', '896px': '60vw' }}
      style={{ width: '30vw' }}
    >
      <div className="formgrid grid ml-auto mr-auto pb-3 pt-3">
        <div className="field col text-center">
          <label htmlFor="code">
            <b>{t('hosts.code')}</b>
          </label>
          <br />
          <mark style={{ fontSize: '2em' }}>{host?.code}</mark>
        </div>

        <div className="field col text-center">
          <label htmlFor="description">
            <b>{t('hosts.activation_otp')}</b>
          </label>
          <br />

          <mark style={{ fontSize: '2em' }}>{activationOtp}</mark>
        </div>
      </div>
      <Message severity="info" text={t('hosts.activation_code_info')} />
    </Dialog>
  );
};
