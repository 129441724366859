import { useState, useEffect } from 'react';

import { Column } from 'primereact/column';

import { DataTable } from 'primereact/datatable';

import { useParams } from 'react-router-dom';
import { parseISO, format } from 'date-fns';

import { useTranslation } from 'react-i18next';

import { ChangesOfStatesToolbar } from './ChangesOfStatesToolbar';

import { handleDateFilters, handleSort } from '../../_shared/functions';
import changesOfStatesService from '../../../service/api/ChangesOfStatesService';
import getPaginatorTemplate from '../../_shared/getPaginatorTemplate';
import { ParsedChangeOfState, ChangesOfStatesProps } from './types';
import { PaginatorState, PaginationParams } from '../../../types/general-types';
import { Panel } from 'primereact/panel';

import generalStyle from '../../../assets/custom-styles/general.module.scss';
import { dropdownPanelTemplate, panelTemplate } from '../../_shared/panel-template';
import { ChangeOfStateConfig } from './ChangeOfStateConfig/ChangeOfStateConfig';

const INIT_PAGINATION_PARAMETERS = {
  order: {
    isAscending: false,
    orderColumn: 'date',
  },
  page: {
    index: 1,
    size: 10,
  },
};

const INIT_PAGINATOR_STATE = {
  currentPage: 1,
  totalPages: 1,
  rows: 10,
};

export const ChangesOfStates = (props: ChangesOfStatesProps) => {
  const { scaleName, weighingUnit } = props;
  const [changesOfStates, setChangesOfStates] = useState<any>([]);
  const [expandedRows, setExpandedRows] = useState<any>(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [paginatorState, setPaginatorState] = useState<PaginatorState>(INIT_PAGINATOR_STATE);
  const [paginationParams, setPaginationParams] = useState<PaginationParams>(INIT_PAGINATION_PARAMETERS);

  const { t } = useTranslation();
  const { scaleId } = useParams<{ scaleId: string }>();

  useEffect(() => {
    if (
      paginatorState.currentPage !== paginationParams.page.index ||
      paginatorState.rows !== paginationParams.page.size
    ) {
      const params = {
        ...paginationParams,
        page: {
          index: paginatorState.currentPage,
          size: paginatorState.rows,
        },
      };
      setPaginationParams(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationParams, paginatorState]);

  useEffect(() => {
    const dateFilters = handleDateFilters(startDate, endDate);

    changesOfStatesService.getAll(scaleId, paginationParams, dateFilters).then((changesOfStates) => {
      let parsedChangesOfStates = [];

      for (const changeOfState of changesOfStates.data) {
        parsedChangesOfStates.push({
          id: changeOfState.id,
          scaleId: changeOfState.scaleId,
          hour: format(parseISO(changeOfState.endTimestamp), t('formats.hour_format')),
          date: format(parseISO(changeOfState.endTimestamp), t('formats.date_format')),
          value: `${changeOfState.amount} ${weighingUnit}`,
          details: [
            {
              status: t('changes_of_states.status_start'),
              hour: format(parseISO(changeOfState.startTimestamp), t('formats.hour_format')),
              state: `${changeOfState.startState} ${weighingUnit}`,
            },
            {
              status: t('changes_of_states.status_end'),
              hour: format(parseISO(changeOfState.endTimestamp), t('formats.hour_format')),
              state: `${changeOfState.endState} ${weighingUnit}`,
            },
          ],
          type:
            changeOfState.startState > changeOfState.endState
              ? t('changes_of_states.negative')
              : t('changes_of_states.positive'),
        });
      }

      setChangesOfStates(parsedChangesOfStates);
      setPaginatorState({
        ...paginatorState,
        totalPages: changesOfStates.totalPageNumber,
        currentPage: paginatorState.currentPage > changesOfStates.totalPageNumber ? 1 : paginatorState.currentPage,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, paginationParams, scaleId]);

  const rowExpansionTemplate = (changeOfState: ParsedChangeOfState) => {
    return (
      <div className="orders-subtable">
        <DataTable value={changeOfState.details} responsiveLayout="scroll">
          <Column field="status" header={t('changes_of_states.status')} />
          <Column field="hour" header={t('common.hour')} />
          <Column field="state" header={t('changes_of_states.state')} />
        </DataTable>
      </div>
    );
  };

  return (
    <div className="grid table-demo">
      <div className="col-12">
        <Panel
          header={`${t('auto_weighings.header_change_of_state')} - ${scaleName}`}
          className={generalStyle.panelTitle}
          headerTemplate={(e) => dropdownPanelTemplate(e)}
          toggleable
        >
          <ChangeOfStateConfig weighingUnit={weighingUnit} />
        </Panel>

        <Panel
          header={`${t('changes_of_states.changes_of_states_list')} - ${scaleName}`}
          className={generalStyle.panelTitle}
          headerTemplate={(e) => panelTemplate(e)}
        >
          <ChangesOfStatesToolbar
            t={t}
            scaleId={scaleId}
            scaleName={scaleName}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            paginatorState={paginatorState}
            setPaginatorState={setPaginatorState}
            paginationParams={paginationParams}
            setPaginationParams={setPaginationParams}
            changesOfStates={changesOfStates}
          />

          <DataTable
            value={changesOfStates}
            expandedRows={expandedRows}
            onRowToggle={(e: any) => setExpandedRows(e.data)}
            responsiveLayout="scroll"
            rowExpansionTemplate={rowExpansionTemplate}
            dataKey="id"
            paginator
            rows={paginatorState.rows}
            rowHover
            paginatorTemplate={getPaginatorTemplate(paginatorState, null, null, setPaginatorState)}
            emptyMessage={t('changes_of_states.empty_message')}
            onSort={(event) =>
              handleSort(event, paginatorState, paginationParams, setPaginatorState, setPaginationParams)
            }
            sortField={paginationParams.order.orderColumn}
            sortOrder={paginationParams.order.isAscending ? 1 : -1}
            removableSort
          >
            <Column expander style={{ width: '3em' }} />
            <Column field="date" header={t('common.date')} sortable />
            <Column field="hour" header={t('common.hour')} />
            <Column field="value" header={t('changes_of_states.value')} />
            <Column field="type" header={t('common.type')} />
          </DataTable>
        </Panel>
      </div>
    </div>
  );
};
