import { Dialog } from 'primereact/dialog';

import { Button } from 'primereact/button';
import FormErrorMessage from '../../_shared/FormErrorMessage';
import { InputText } from 'primereact/inputtext';
import { GetConfirmationForm } from './validation';
import { ConfirmationDialogProps } from '../types';
import usersService from '../../../service/api/UsersService';
import { Trans } from 'react-i18next';

export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const { t, isVisible, initialValues, dialogHeader, onSuccess } = props;

  const confirmationForm = GetConfirmationForm(initialValues, onSuccess, t);

  const confirmationDialogFooter = () => {
    return (
      <>
        <Button
          type="submit"
          label={t('confirmation_dialog.confirm')}
          className="p-button-success mt-3 mr-2 mb-2 pr-4 pt-2 pb-2 pl-4"
          onClick={confirmationForm.submitForm}
        />
      </>
    );
  };

  const sendConfirmationCodeAgain = async () =>
    await usersService.resendConfirmationCode({ email: initialValues.email });

  return (
    <Dialog
      visible={isVisible}
      style={{ width: '600px' }}
      header={dialogHeader(t('confirmation_dialog.header'))}
      modal
      className="p-fluid"
      footer={confirmationDialogFooter}
      onHide={null}
      closable={false}
    >
      <div className="col-12 md:col-11 mb-3 ml-auto mr-auto pt-1">
        <div className="flex flex-1">
          <p className="mb-3">
            <Trans
              i18nKey="confirmation_dialog.send_code_info"
              values={{ email: initialValues.email }}
              components={{ bold: <strong /> }}
            />
          </p>
        </div>

        <div className="field">
          <label htmlFor="confirmationCode">
            <b>{t('confirmation_dialog.confirmation_code')}</b>
          </label>
          <span className="p-input-icon-left">
            <i className="pi pi-lock-open" />
            <InputText
              id="confirmationCode"
              value={confirmationForm.values.confirmationCode}
              onChange={confirmationForm.handleChange}
              type="text"
              required
              autoFocus
            />
          </span>
          <FormErrorMessage fieldName="confirmationCode" formikInstance={confirmationForm} />
        </div>

        <div className="flex">
          <div className="mr-auto">
            <Button
              style={{ width: '240px' }}
              className={`p-button-link text-left pl-1`}
              label={t('confirmation_dialog.send_again')}
              type="button"
              onClick={sendConfirmationCodeAgain}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};
