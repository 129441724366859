import { InputNumber } from 'primereact/inputnumber';
import React from 'react';
import FormErrorMessage from '../_shared/FormErrorMessage';
import { RecipeIngredient } from '../../types/RecipeIngredient';
import { InputText } from 'primereact/inputtext';

interface FormValuesProps extends RecipeIngredient {}

export const INIT_FORM_STATE: FormValuesProps = {
  id: null,
  name: '',
  groupConnectorId: null,
  quantity: null,
  sequenceNumber: null,
  delay: null,
};

export function RecipeIngredientTemplate(formik, index, t) {
  return (
    <React.Fragment key={index}>
      <div className="field col-4">
        <label htmlFor={`ingredients[${index}].name`}>{t('recipeIngredients.ingredient')}</label>
        <InputText id="name" disabled={true} value={formik.values.ingredients[index].name} />
      </div>

      <div className="field col-2">
        <label htmlFor={`ingredients[${index}].quantity`}>{t('recipeIngredients.quantity')} (kg)</label>
        <InputNumber
          id={`ingredients[${index}].quantity`}
          value={formik.values.ingredients[index].quantity}
          min={1}
          max={1000000}
          onValueChange={formik.handleChange}
          className={
            formik.touched?.ingredients &&
            formik.errors?.ingredients &&
            formik.touched.ingredients[index]?.quantity &&
            formik.errors.ingredients[index]?.quantity &&
            'p-invalid'
          }
        />
        <FormErrorMessage fieldName="ingredients" formikInstance={formik} index={index} nestedFieldName="quantity" />
      </div>

      <div className="field col-2">
        <label htmlFor={`ingredients[${index}].sequenceNumber`}>{t('recipeIngredients.sequence_number')}</label>
        <InputNumber
          id={`ingredients[${index}].sequenceNumber`}
          value={formik.values.ingredients[index].sequenceNumber}
          min={1}
          max={1000000}
          onValueChange={formik.handleChange}
          className={
            formik.touched?.ingredients &&
            formik.errors?.ingredients &&
            formik.touched.ingredients[index]?.sequenceNumber &&
            formik.errors.ingredients[index]?.sequenceNumber &&
            'p-invalid'
          }
        />
        <FormErrorMessage
          fieldName="ingredients"
          formikInstance={formik}
          index={index}
          nestedFieldName="sequenceNumber"
        />
      </div>

      <div className="field col-3">
        <label htmlFor={`ingredients[${index}].delay`}>{t('recipeIngredients.delay')} (s)</label>
        <InputNumber
          id={`ingredients[${index}].delay`}
          value={formik.values.ingredients[index].delay}
          onValueChange={formik.handleChange}
          min={1}
          max={1000000}
          className={
            formik.touched?.ingredients &&
            formik.errors?.ingredients &&
            formik.touched.ingredients[index]?.delay &&
            formik.errors.ingredients[index]?.delay &&
            'p-invalid'
          }
        />
        <FormErrorMessage fieldName="ingredients" formikInstance={formik} index={index} nestedFieldName="delay" />
      </div>
    </React.Fragment>
  );
}
